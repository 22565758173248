.voucher-balance {
  background: url("../assets/voucher/Gift-card.png");
  background-size: cover;
  text-align: center;
  padding: 80px 10px;
  border-radius: 40px;
}
.voucher-balance h4 {
  color: white;
  text-align: center;
}
.voucher-balance h3 {
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: xxx-large;
  display: flex;
  align-items: center;
  justify-content: center;
}
.voucher-balance img {
  width: 40px;
  margin-right: 10px;
}
.voucher-balance .tag {
  width: 40px;
}
.voucher-redeem {
  text-align: center;
}
.voucher-amount {
  border: 1px solid #e6e6e6;
  padding: 8px;
  border-radius: 25px;
  text-align: center;
  font-size: large;
  font-weight: 600;
  color: #333;
}
.font-bold {
  font-weight: 600;
}
.history .order {
  padding: 20px 5px;
  border-bottom: 2px solid #e6e9ff;
}
.history .order-icon {
  width: 40px;
  margin-right: 25px;
}
.history .order-arrow {
  width: 30px;
  height: fit-content;
  display: flex;
  align-self: center;
}
.order-success {
  padding: 2px 20px;
  text-align: center;
  margin: 0;
  border-radius: 10px;
  color: white;
  background: #6372ff;
  margin-bottom: 5px;
  width: fit-content;
  font-size: smaller;
  text-transform: uppercase;
}
.history h4 {
  margin: 0;
  font-size: large;
  font-weight: 600;
  margin-bottom: 6px;
  color: #646dff;
}
.history h5 {
  margin: 0;
  font-size: large;
}
.history p {
  color: #333;
  margin: 0;
  font-size: medium;
}
.w-md-75 {
  width: 75% !important;
}
.redeem-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.redeem-success h4 {
  margin: 0;
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 6px;
  color: #646dff;
}
.redeem-success h3 {
  font-size: x-large;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-bg-lottie {
  position: relative;
  width: 55%;
}
.success-box {
  position: absolute;
  top: 0%;
}
.success-lottie {
  width: 150px;
  margin: auto;
}
.voucher-redeem img {
  width: 30px;
  margin-right: 8px;
}
.redeem-error-message {
  color: red;
}

@media screen and (max-width: 600px) {
  .voucher-balance .tag {
    width: 20px;
    right: 5% !important;
  }
  .w-md-75 {
    width: 100% !important;
  }
  .voucher-balance h4 {
    font-size: large;
  }
  .voucher-balance h3 {
    font-size: xx-large;
  }
  .voucher-balance {
    background-repeat: no-repeat;
    padding: 40px 10px;
    border-radius: 15px;
  }
  .history h5 {
    text-align: right;
    font-size: medium;
  }
  .history .order-icon {
    width: 30px;
    margin-right: 15px;
    display: flex;
    align-self: baseline;
  }
  .history h4 {
    font-size: medium;
    margin-bottom: 0px;
  }
  .order-success {
    font-size: x-small;
  }
  .history p {
    font-size: smaller;
  }
}

@media screen and (max-width: 350px) {
  .voucher-balance {
    padding: 30px 10px;
  }
}
