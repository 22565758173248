.insurance-banner {
  background: url("../assets/Insurance\ Background.png");
  background-position-y: bottom;
  background-size: cover;
  padding: 175px 0 165px 50px;
  // margin-bottom: 40px;
  @media (max-width: 400px) {
    padding: 30px 0 !important;
  }
  @media (max-width: 600px) {
    padding: 50px 0;
    margin-bottom: 10px;
  }
  @media (min-width: 1800px) {
    padding: 200px 0;
    background-position-x: 12vw;
  }
}
@media (min-width: 600px) {
  .insurance-banner .head-margin {
    margin-top: 0px;
  }
}
@media (max-width: 990px) {
  .insurance-banner .image {
    max-width: 100%;
  }
}
.insurance-banner h1 {
  font-weight: 700;
  color: #646dff;
  font-size: xxx-large;
  @media (max-width: 990px) {
    font-size: x-large;
  }
  @media (max-width: 600px) {
    font-size: x-large;
  }
  @media (max-width: 300px) {
    font-size: large;
  }
}
.insurance-banner h2 {
  font-weight: 600;
  font-size: x-large;
  @media (max-width: 990px) {
    font-size: large;
  }
  @media (max-width: 600px) {
    font-size: small;
  }
}

.insurance-insurance-box {
  background: url("../assets/ic_place_holder.svg");
  background-size: cover;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1.5px solid #ccc8ff;
  border-radius: 15px;
  align-items: center;
  @media (max-width: 600px) {
    padding: 15px 10px;
  }
}
.insurance-insurance-box .insurance-icon {
  width: 100px;
  scale: 100%;
  margin: 0;
  @media (max-width: 990px) {
    width: 80px;
  }
  @media (max-width: 600px) {
    width: 100px;
  }
}
.insurance-insurance-box h2 {
  margin: 0;
  font-size: large;
  text-transform: uppercase;
  font-weight: 500;
  @media (max-width: 1200px) {
    font-size: medium;
  }
  @media (max-width: 767px) {
    font-size: x-large;
  }
  @media (max-width: 500px) {
    font-size: medium;
  }
}
.insurance-insurance-box .insurance-arrow {
  height: 25px !important;
  width: auto;
  @media (max-width: 990px) {
    height: 20px !important;
  }
  @media (max-width: 600px) {
    height: 15px !important;
  }
}
.ins-single {
  width: 350px !important;
  margin: unset !important;

  @media (max-width: 990px) {
    width: 200px !important;
  }
  @media screen and (max-width: 600px) {
    width: 120px !important;
    scale: 140% !important;
  }
}
.cursor-pointer{
    cursor: pointer;
}