.slick-prev:before,
.slick-next:before {
  color: #0078d7;
  line-height: 41px;
  margin-left: 4px;
}

.slick-slide img {
  display: inline;
}

.slick-disabled {
  display: none !important;
}

.slick-track {
  margin: 0;
}

.slick-list {
  margin: 0 -15px;
}

// Banner Carousel

.carousel-control-prev{
  z-index: 0;
  display: flex;
  justify-content: start;
  padding-left: 25px;

  &-icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #a5a5a5;
    background-size: 50%;
  }
}


.carousel-control-next {
  z-index: 0;
  display: flex;
  justify-content: end;
  padding-right: 25px;

  &-icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #a5a5a5;
    background-size: 50%;
  }
}

.partner-carousel-item {
  width: 100%;
  height: 200px;
  background-size: cover;
  border-radius: 8px;

  @media (min-width: 992px) {
    height: 400px;
  }
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0C9648;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
