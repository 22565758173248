.saved-address-vector{
    width: 300px;
    @media (min-width: 992px) { 
        width:400px;
    }
}

@media (min-width: 992px) {
    .address-modal .modal-dialog {
        max-width: 850px;
    }    
}

.edit-icon {
    width: 1.1rem;
}