.coupon-wrapper {
    display: flex;
    // align-items: center;
    // justify-content: center;

    div {
        position: relative;
        width: 300px;
        height: 250px;
        margin: 0 auto;

        @media (min-width: 992px) {

            width: 400px;
            height: 300px;

        }

        img {
            width: 300px;
            position: absolute;
            top: 0;
            left: 0;

            @media (min-width: 992px) {
                width: 400px;
            }

        }

        h3 {
            color: #FFCC00;
            font-size: 70px;
            font-weight: 600;
            // transform: rotate(-18deg);
            position: absolute;
            top: 85px;
            left: 115px;
            width: 160px;
            text-align: center;

            @media (min-width: 992px) {
                font-size: 90px;
                top: 120px;
                left: 160px;
                width: 200px;
                text-align: center;
            }

        }
        

    }
    h5 {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
    
    }

}

.wrp-summary {

    padding: 0 1rem 3rem;

    @media (min-width: 992px) {
        padding: 0 2rem 3rem;
    }

    .wrp-content {
        margin-top: 2rem;
        text-align: left;
    }

    .toggle-tabs-wrapper {
        width: 100%;
        margin: 0 auto;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tab-btn {
        border: 2px solid #203bcf;
        width: 50%;
        text-align: center;
        padding: .5rem 0;
        border-radius: 10px 0 0 10px;
        font-size: .8rem;
        cursor: pointer;

        @media (min-width: 992px) {
            font-size: 1rem;
        }
    }

    .tab-btn.active {
        background-color: #203bcf;
        color: #fff;
    }

    .tab-btn:last-child {
        border-radius: 0 10px 10px 0;
    }
}

.wrp-tip-wrapper {
    .wrp-tip {
        background-color: #efeff4;
        border-radius: 8px;
        font-size: .8rem;
        padding: 1rem;
        position: relative;
        margin: 1rem auto;
        width: 80%;

        &::before {
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            top: -.8rem;
            left: 5%;
            border-bottom: 15px solid #efeff4;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;

            @media (min-width: 992px) {
                right: 5%;
                left: auto;
            }
        }
    }
}

.wrp-summary-modal {
    .modal-dialog {
        max-width: 580px !important;
    }
}

.wrp-counter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .5rem;
    margin-top: 0rem;

    @media (min-width: 768px) {
        flex-direction: row;
        margin-top: .5rem;
    }

    @media (min-width: 2000px) {
        justify-content: center;
        margin-top: 1rem;
    }

    .left {
        position: relative;

        .progress {
            width: 45%;
            border-radius: 10px;
            margin-left: 1rem;
            margin-top: -.5rem;
            height: .5rem;

            @media (min-width: 2000px) {
                margin-left: 3rem;
            }
        }

        @media (min-width: 768px) {
            width: 50%;
        }

        @media (min-width: 1024px) {
            width: 40%;
        }

        .wrp-tooltip-container {
            position: absolute;
            top: -3rem;
            right: 0rem;
            z-index: 1;

            .wrp-tooltip {
                position: relative;
                padding: 0.5rem;
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

                &::after {
                    content: " ";
                    position: absolute;
                    height: 20px;
                    width: 15px;
                    border: 10px solid #fff;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    bottom: -1rem;
                    left: 50%;
                    transform: translate3d(-50%, 0, 0);
                    z-index: auto;
                }
            }
        }
    }

    .right {
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
        align-items: center;

        @media (min-width: 768px) {
            margin-top: 0;
            width: 50%;
            justify-content: flex-start;
        }

        @media (min-width: 1025px) {
            width: 60%;
        }

        p {
            margin-bottom: 0;
            font-size: 12px;

            @media (min-width: 992px) {
                font-size: 14px;
            }

            @media (min-width: 1600px) {
                font-size: 16px;
            }
        }
    }
}

.wrp-tab {
    background-color: #f0eff5;
    border-radius: 8px;
    padding: 1rem;
    // height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;

    @media (min-width: 992px) {
        //   height: 55px;
        margin: 0 1rem;
    }

    h4 {
        font-size: 15px;

        @media (min-width: 992px) {
            font-size: 20px;
        }
    }

    .arrow-box {
        height: 27px;
        width: 27px;
        border-radius: 8px;
        background-color: #222a73;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;

        @media (min-width: 992px) {
            height: 40px;
            width: 40px;
        }
    }
}

.monthly-wrp{
    // background: url(../assets/ic_bg_1.svg) !important;
    width: 100%;
    min-height: 139px;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-color: #6200ff !important;
    margin-top: 0.5rem;
    border-radius: 10px;
}
.monthly-wrp p{
    color: white;
    margin: 0%;
    padding-left: 15px;
}

.monthly-coupons{
    // background: url(../assets/ic_bg_1.svg) !important;
    width: 100%;
    min-height: 139px;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-color: #9858ff !important;
    margin-top: 0.5rem;
    border-radius: 10px;
}
.monthly-coupons h4{
    color: white;
    padding-left: 8px;
    font-size: 30px;
    margin: 0;
}
.monthly-coupons p{
    color: white;
    margin: 0%;
    padding-left: 12px;
}

.coupon-wrapper-small {
    font-size: 30px !important;
    top: 115px !important;
    left: 115px !important;
    overflow-x: auto;
    @media (min-width: 992px) {
        font-size: 50px !important;
        top: 145px !important;
        left: 155px !important;
    }
}