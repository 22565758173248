.col--404{

    img{

        width:280px;

        @media (min-width: 992px) { 

            width: 500px;

        }

    }

}