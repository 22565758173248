.wallet-vector{

    width:300px;

    @media (min-width: 992px) { 
        width: 400px;
    }

}

.wallet {
    .home-btn {
        @media (min-width: 990px) {
            width: 50%;
        }
    }
}

.withdrawal-details {
    @media (max-width: 990px) {
       margin-left: 2.9rem;
    }
    @media (max-width: 576px) {
        margin-left: 1.7rem;
     }
}

.chg-btn {
    position: absolute;
    right: 1%;
    top: 50%;
    transform: translateY(calc(-50% + 1rem));
}
.withdrawText{line-height: 0; margin-left:10px}
.otp-text{color: #A5A5A5; font-size: 13px;}
.resendOtp{color: #203BCF;}

.checkbox-input{
    opacity: 1 !important;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    padding-left: 0 !important;
}
.checkbox-input input[type=checkbox]:before {
    content: "";
    border: 2px solid #555555;
    border-radius: 20px;
    background-color: white;
}
.payment-card{
    padding: 0.7rem;
    background: #fff;
    // border: 1px solid #D5DBFF;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.14);
}
// .payment-card-top{
//     margin-bottom: 5px;
// }
.payment-card-top h3 {
    font-size: 18px;
    margin-right: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    @media screen and (max-width:767px) {
        font-size: 15px;
        margin-right: 10px;
    }
    @media screen and (max-width:350px) {
        font-size: 12px;
        margin-right: 10px;
    }
    
}
.payment-card-top .line{
    margin: 0 15px;
    width: 2px;
    background: #dadada;
    height: 15px;
    @media screen and (max-width:767px) {
        margin: 0 7px;
    }
}
.payment-card-top p {
    font-size: 15px;
    margin-left: 15px;
    // color: #334155;
    @media screen and (max-width:767px) {
        font-size: 10px;
    }
}
.payment-card-bottom p {
    font-size: 14px;
    color: #6D6D6D;
    @media screen and (max-width:767px) {
        font-size: 12px;
    }
    @media screen and (max-width:350px) {
        font-size: 10px;
    }
}
.payment-card-bottom img {
    width: 25px;
    margin-right: 10px;
    @media screen and (max-width:350px) {
        width: 20px;
        margin-right: 8px;
    }
}
.cart-selected-tic{
    width:26px;
    @media screen and (max-width:380px) {
        width: 19px;
    }
}
.round {
    position: relative;
  }
  
  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }
  
  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #7100FF;
    border-color: #7100FF;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
  h3.choose {
    font-size: 1.3rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    @media screen and (max-width:767px) {
        font-size: 1rem;
        margin-top: 1rem;
    }
}



.select-border{
    // border: 2px solid rgb(113, 0, 255);
    border: 2px solid rgb(113 0 255 / 50%)
}
.wallet-card {
    background: #6877fa;
    padding: 10px;
    color: white;
    border-radius: 12px;
}

.wallet-disable{
    opacity: 60%;
    pointer-events: none;
    // cursor: not-allowed;
}