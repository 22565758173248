.orders-vector{
    width:300px;

    @media (min-width: 992px) {
        width: 500px;
    }

}

.order-success {
    padding: 2px 20px;
    text-align: center;
    margin: 0;
    border-radius: 10px;
    color: white;
    background: #6372FF;
    margin-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: smaller;
    text-transform: uppercase;
}
.filter-head, 
.status {
    width: 100%;
    margin: auto;
}

.date-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: .5rem;
    background: transparent;
}

.main-content {
    @media (min-width: 992px) {
        padding-left: 2rem;
    }
}

.voucher-details {
    .input-group-text{
        border-radius: 0 8px 8px 0;
    }

    .form-control {
        border-radius: 8px 0 0 8px;
    }
    
    p {
        line-height: .8em;
    }
}

.rewards .carousel-control-prev, .rewards .carousel-control-next {
    display: block !important;
}

.order-reward-cash{
    background: url('../assets/orders/ic_win_cas&wrp_bg.png');
    margin: 0;
    background-size: cover;
    border-radius: 20px;
    height: 180px;
    background-position: center;
    background-position-x: right;
}
.order-reward-earned{
    background: url('../assets/orders/ic_reward_earned_bg.png') no-repeat;
    margin: 0;
    background-size: cover;
    border-radius: 20px;
}
.centered{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.flex-icon{
    display: flex;
    align-items: center;
}
.centered h3{
    font-weight: 500;
}
.centered span{
    color: white;
    font-weight: 500;
}
@media screen and (max-width:1200px) {
   .centered h3{
    font-size: 1rem;
} 
.order-reward-earned{
    padding: 10px 0;
 }
 .order-reward-cash{
    height: 120px;
    background-position: center;
    background-position-x: right;
}
}
@media screen and (max-width:600px) {
    .centered h3{
     font-size: 1rem;
 }
 .centered span{
    font-size: 0.7rem;
 }
 .order-reward-cash {
    height: 85px;
    background-position: center;
    background-position-x: right;
}
}

.voucher-toggle-group {
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    
    .btn-outline-light {
      color: #7D70FF !important;
      background-color: #ffffff !important;
      border-color: #7D70FF !important;
      border-radius: 3px !important;
      max-width: 200px;
    }
    .btn-outline-light:hover {
      color: #1404c1 !important;
      background-color: #f1eff6 !important;
      border-color: #1404c1 !important;
    }
    .btn-outline-light:not(:disabled):not(.disabled):active,
    .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > .btn-outline-light.dropdown-toggle {
      color: #fff !important;
      background-color: #7D70FF !important;
      border-color: #6f62fa !important;
    }
    .btn-outline-light:focus {
      color: #fff;
      background-color: #676666;
      border-color: #adadad;
      outline: none;
    }
  }
  
  .voucher-toggle-group::-webkit-scrollbar {
    display: none;
  }