
.agoda-main {
  position: relative; 
  width: 100%;
   height: 79vh; 
   /* background-image: url(./image/background-agoda-img.jpg)no-repeat center center fixed ;  */
   background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agoda-search-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

/* #adgshp-1000689165 {
  width: 100% !important;
  height: 100%;
} */

  .agoda-search-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./image/background-agoda-img.jpg') no-repeat center center ;
  background-size: cover;
  opacity: 0.6;
  z-index: -1;
}
.position-relative {
  position: relative;

}

.position-absolutes {
  position: absolute;
  top: 0;
  left: 0;
}

.overlay-content {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.overlay-logo {
  max-width: 100%;
  height: auto;
  display: block;
}

.overlay-text {
  color: white;
  font-size: 1.2rem; 
  
}

.overlay-button {
  font-size: 1.2rem; 
  padding: 0.5rem 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5), 0px 1px 3px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;
   /* border: 1px solid #daa12e;     */
  background-color:#E5B544;
  color: white;
  border-radius: 6px !important;
  /* E0AD48 */
}
.overlay-button:hover{
  background-color:#daa12e ;
}

.img-fluids {
  width: auto;
  height: 12vh;
  /* opacity: 0.9; */
  background-repeat: no-repeat;
  
}
.overlay-button :hover {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15), 0px 4px 6px rgba(0, 0, 0, 0.10);
}
/* .agoda-search-bar{
     padding-left: 2.25rem;
      padding-top: 1.75rem; 
} */
@media (min-width:320px) and (max-width:576px){
  .img-fluids{
    height: 23vh;
   }
   .overlay-button{
     padding: 10px !important ;
     font-size: 0.8rem;
     display: flex;
     justify-content: center!important;
     /* margin-top:10px!important */
   }
   .overlay-text{
     font-size: 0.9rem!important ;
   }
}

 @media (min-width: 576px) and (max-width: 768px) {
  .img-fluids{
   height: 20vh;
  }
  .overlay-button{
    padding: 10px !important ;
    font-size: 0.9rem;
  }
  .overlay-text{
    font-size: 1rem!important ;
  }
  .agoda-main{
    height:110vh !important;
  }
  
} 
@media (min-width: 768px) and (max-width: 832px) {
  .img-fluids{
   height: 21vh;
  }
  .overlay-button{
    padding: 10px !important ;
    font-size: 1rem;
  }
  .overlay-text{
    font-size: 1rem!important ;
  }
  .agoda-main{
    height: 110vh !important;;
  }
  
}
@media (min-width: 832px) and (max-width: 992px) {
  .img-fluids{
   height: 21vh;
  }
  .overlay-button{
    padding: 10px !important ;
    font-size: 1rem;
  }
  .overlay-text{
    font-size: 1rem!important ;
  }
  .agoda-main{
    height: 110vh !important;
  }
}
@media (min-width: 992px) and (max-width:1200px) {
  .img-fluids{
   height: 21vh;
  }
  .overlay-button{
    padding: 10px !important ;
    font-size: 1rem;
  }
  .overlay-text{
    font-size: 1rem!important ;
  }
}

