.site-header {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(10px);
  background: #f2f2f2ba;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #e5e5e5;
}
.navbar {
  padding: 5px 0;
  z-index: 1;

  @media (min-width: 992px) {
    padding: 12px 0;
  }
  .navbar-nav {
    .nav-item {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: 'green';
      img {
        height: 1rem;
        width: auto;

        @media (min-width: 992px) {
          height: 1.5rem;
        }
      }

      .nav-link {
        color: #3f4850;
        text-transform: uppercase;
        padding: 0 5px;
        font-size: 13px;

        @media screen and (min-width: 1200px) and (max-width: 1300px) {
          padding: 0 1rem !important;
          font-size: 13px !important;
        }

        @media (min-width: 992px) {
          font-size: 16px;
        }

        img {
          height: 1rem;
          width: auto;
        }

        &.is-active {
          color: #ffa300;
        }

        @media (min-width: 992px) {
          padding: 0 1.5rem;

          img {
            height: 2.5rem;
          }
        }

        .wrp-icon {
          height: 0.95rem;

          @media (min-width: 992px) {
            height: 1.5rem;
          }
        }

        .earnings-icon {
          height: 1rem;
          border: solid 1.5px #222a73;
          padding: 3px 5px;
          margin-top: 7px;
          border-radius: 3px;

          @media (min-width: 992px) {
            height: 20px;
            padding: 3px 5px;
            transform: translateY(-3px);
          }
        }
      }

      .nav-text {
        color: #3f4850;
        text-transform: uppercase;
        font-size: 13px;
        padding: 0;
        display: block;
        @media (min-width: 992px) {
          font-size: 16px;
        }
      }

      .dropdown-menu {
        position: absolute;
        min-width: 12rem;
        background: #f6f6f6;
        border: 1px solid rgb(218 218 218 / 15%);
        .dropdown-item {
          display: flex;
          align-items: center;
          padding: 0.5rem 1.5rem;
          img {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

.mobile-icons {
  margin-right: 1rem !important;

  @media (min-width: 992px) {
    margin-right: 0.3rem !important;
    margin-bottom: 0.1rem;
  }
}

.dropdown-icons {
  img {
    height: 0.8rem !important;
  }
}
@media(max-width:992px){
  .navbar-brand img {
    max-width: 60px !important;
    height: auto;
  }
}
.navbar-brand img {
  max-width: 75px;
  height: auto;
}
.wrp-coin {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 3px 6px;
  min-width: 100px;
  max-width: 100px;
}
.wrp-coin img,
.userIcon {
  height: 2rem !important;
}
.wrp-coin span {
  margin: 0 3px;
  font-weight: 600;
  color: #3f4850;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.account-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 165px;
  color: #000;
  // background: linear-gradient(272deg, #211E1F, #0C9648);
  padding: 3px;
  padding-right: 6px;
  border-radius: 20px;
}
.account-info span {
  color: #221E1F;
}
.site-header .nav-link.active {
  text-decoration: underline;
  color: #0C9648 !important;
  text-underline-position: under;
  font-weight: 600;
}

span.header-rewards {
  top: 0;
  position: absolute;
  right: 20%;
  font-size: 8px;
  background: #0d9648;
  color: white;
  margin: 0;
  padding: 0;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  line-height: 18px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #7d70ff;
  text-decoration: none;
}
.root-menu-container .menu-level-options-container {
  box-shadow: none !important;
}
.root-menu-container
  .menu-level-options-container
  .options-main-menu
  .options-container
  .options-sub-menu-container {
  box-shadow: none !important;
}
.toggle-nav {
  .sidenav {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    color: #000;
  }

  .sidenav.open {
    width: 100vw;
  }

  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 36px;
    margin-left: 50px;
  }
  .card {
    border: 0px;
  }
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
  .card-header {
    // padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgb(255, 255, 255);
    border-bottom: 0px;
  }
  .btn {
    text-align: start;
  }
}
.toggle-nav .sidenav .toggle-home-icon {
  position: absolute;
  top: 0;
  font-size: 30px;
  margin-left: 14px;
}
.voucher-section {
  width: 0px;
  height: auto;
  background: rgb(255, 255, 255);
  animation: expand 300ms linear 0s forwards;
}
@keyframes expand {
  from {
    width: 310px;
  }
  to {
    width: 100%;
  }
}
.nav-text-gray {
  color: #000 !important;
}
.shadow-line {
  height: 30px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.319) 1.95px 1.95px 2.6px;
  margin-bottom: 10px;
}

.options-sub-menu-container {
  width: max-content;
  min-width: 200px;
  max-height: 600px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.options-sub-menu-container::-webkit-scrollbar {
  width: 3px;
}

.options-sub-menu-container::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.options-sub-menu-container::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 7, 88, 0.5);
}
.explore-btn-svg {
  width: 10px;
  height: 16px;
  @media (min-width: 1300px) {
    width: 16px;
    height: 22px;
  }
}
.navbar--winds-icon {
  position: absolute;
  top: 42px;
  // left: 40px;
}
.options-container span {
  display: none;
}
.root-menu-container .menu-level-options-container {
  width: max-content !important;
}
.root-menu-container
  .menu-level-options-container
  .options-main-menu
  .options-container
  .options-label {
  max-width: none !important;
  text-align: start;
}

.root-menu-container
  .menu-level-options-container
  .options-main-menu
  .options-container
  .options-sub-menu-container {
  max-width: none !important;
  width: max-content !important;
}

.root-menu-container .menu-level-options-container .options-main-menu .options-container .options-label {
  padding-right: 25px !important;
}
.root-menu-container .menu-level-options-container .options-main-menu .options-container {
  position: static !important;
}