
// .ecom-banner {
//     position: relative;

//     .eCom-img {
//         width: 100%;
//         margin-top: -3rem;
//     }

//     .banner-content {
//         position: absolute;
//         top: 50%;
//         transform: translate3d(0, -50%, 0);
//         right: 5rem;
        
//         h1 {
//             font-size: 4vw;
//             text-transform: uppercase;
//             text-align: right;
//             line-height: 3.5vw;
//         }

//         h5 {
//             font-size: 2vw;
//             text-align: right;
            
//         }

//     }
// }

.eCom-img {
    width: 100%;
    margin-top: -3rem;
}