.confirmedwrpredemption {
  font-size: 16px;
  color: #333;
}

.wrpNumber {
  font-size: 28px;
}

.purchaseGift {
  font-size: 18px;
  font-weight: 500;
  color: #333
}

.lightTextsemiBold {
  color: #999;
  font-weight: 400;
  font-size: 16px;
}

.lightTextNormal {
  color: #999;
  font-size: 14px;
}

.wrpText {
  border-left: none;
  padding-left: 0;
  border-right: none;
  border-top: none;
  border-radius: 0 !important;
  color: #333 !important;
  font-size: 18px;
  font-weight: bold;
}

.wrpText:focus,
.wrpText:active {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0 !important;
  box-shadow: none !important
}

.termsandCond {
  color: #0078D7;
  font-weight: bold;
}

.f-12 {
  font-size: 16airvoucherTextpx;
}

.modalHead .close {
  position: absolute;
  right: 40px;
  font-size: 35px;
  color: #999;
}

.termsCondition {
  vertical-align: middle;
}

.airvoucherText {
  font-size: 20px;
  color: #666;
  font-weight: normal;
}

.claimLater {
  color: #0078D7;
}

.copyicon {
  border: 1px solid #222A73;
}



.wrpHeading {
  margin: 0 auto;
}

.no-border-radius {
  border-radius: 0px !important;
  border-left: 0px solid #fff;
}

.wrp-tab-summary .tab-btn {
  border: 1px solid #203BCF;
  padding: 2px 2px;
  color: #9D9D9D;
  font-size: 13px;
}

.wrpTitle {
  font-size: 20px;
  color: #333;
  font-weight: bold;
}

.earnedwrp {
  font-size: 14px;
  color: #333;
}

.wrpNumber {
  font-size: 30px;
  font-weight: bold;
  color: #333;
}

.wrp-summary .wrp-content {
  margin-top: 2rem;
  text-align: left;
  padding: 10px 50px;
}

.winds-link-color {
  border-radius: 5px;
  background: #203BCF;
  color: #fff !important;
  padding: 2px 12px;
}

.wrp-content .card {
  border: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.wrp-content .card .card-body {
  padding: 12px 22px;
}

.wrp-content h5 {
  font-size: 17px;
}

.termsandcond {
  color: #333;
  font-weight: bold;
  font-size: 30px;
}

.termsService {
  color: #333;
  font-size: 20px;
  font-weight: bold;
}

.termsCondition p {
  font-size: 16px;
}

ul.coloredbulletul li {
  list-style: none;
}

ul.coloredbulletul li::before {
  content: "\2022";
  color: #0078D7;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.claimNowLink {
  display: inline-block;
  float: right;
  color: #0078D7;
}

.wrpText {
  font-size: 17px;
  font-weight: bold;
  display: inline;
}

.nextDraw {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.couponHistory {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.wrp-progressbar {
  background-color: #0078D7 !important;
}

.nextDrawHeading {
  font-size: 30px;
  font-weight: 600;
}

.next-draw-card {
  border-radius: 8px !important;
}

.card-img-caption {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: right;
  width: 100%;
  color: #fff;
  font-weight: bold;
  position: absolute;
  z-index: 1;
  right: 5%;

}

.couponHeading {
  font-size: 35px;
}



.card-body-border {
  border: 1px solid #222A73;
  border-top-color: transparent;
}

.claim-now-text {
  font-weight: 500;
}


.card-text h1{
font-size:30px;
  @media (max-width: 400px) {
   font-size: 15px !important;
}

}