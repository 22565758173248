.color-accent {
   color: #FFCC00;
}

.rounded-border {
   border-radius: .5rem;
}

.text-blue {
   color: #0078D7;

   &.a:hover {
      color: #0078D7;
   }
}

.earning-history {
   position: relative;

   &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 70px);
      background: #203BD0;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
   }

   &-details {

      .card {
         margin-bottom: 20px;

         &:last-child {
            margin-bottom: 0;
         }

         .btn {
            border-radius: 15px;
            color: rgba(#040E43, 0.8) !important;
            padding-left: 10px;
            padding-right: 10px;

            &-warning {
               background: #FBECFF;
               border-color: #D99AEA;
            }

            &-success {
               background: rgba(#28a745, 0.1);
            }

            &-info {
               background: rgba(#17a2b8, 0.1);
               border-color: rgba(#17a2b8, 0.7);
            }
         }

         .text-warning {
            color: #FF7805 !important;
         }

      }
   }

   &>div {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px;

      &:before {
         content: "";
         position: absolute;
         width: calc(100% - 30px);
         height: 1px;
      }

      &:last-child {
         margin-bottom: 0;
         padding-bottom: 0;
         border: none;

         &:before {
            display: none;
         }
      }

      &:before {
         content: "";
         background: rgba(#000, 0.1);
         height: 1px;
         width: calc(100% - 45px);
         position: absolute;
         right: 0;
         bottom: 0;
      }
   }
}

.calender-icon {
   position: absolute;
   top: 10px;
   margin: auto;
   height: 30px;
   width: 30px;
   background: #D7EAFB;
   text-align: center;
   border-radius: 50%;

   img {
      transform: translate(1px, 1px);
   }

   &+.text-left {
      padding-left: 45px;

      p {
         margin-bottom: 0;
      }
   }
}

body {

   .dropdown-item.active,
   .dropdown-item:active {
      background: none;
   }

   .btn-disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
   }

   .earning-low {
      padding: 3vh 5vh 5vh;
      text-align: center;
      position: relative;

      .btn-close {
         position: absolute;
         right: -3px;
         top: -3px;
         border: none;
         background: transparent;
         color: #f00;
         font-size: 18px;
         opacity: 0.7;
      }

      img {
         max-width: 90%;
         display: block;
         margin-bottom: 30px;
      }

      h5 {
         font-size: 20px;
         line-height: 1.4;
         color: $color-blue;
         margin-bottom: 0;
         font-weight: bold;
         letter-spacing: 0.02em;
      }
   }

   .my-referrals {
      background-image: linear-gradient(180deg, #F7FDFF 0%, #DDF5FF 100%);
      border: 1px solid #C9DCEE;
      border-radius: 4px;
      margin-top: 20px;
      min-height: 100px;
      padding: 20px 30px;
      display: flex;
      align-items: center;

      p {
         margin-bottom: 5px;
      }

      h5 {
         margin-bottom: 0;
      }

      h3 {
         font-weight: bold;
         font-size: 36px;
         color: $color-primary;
         letter-spacing: 0.04em;
         margin-bottom: 0;
      }
   }

   .referral-list {

      h5 {
         font-size: 20px;
      }

      p {
         font-size: 14px;
      }
   }
}