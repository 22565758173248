// .swiper {
//     width: 100%;
//     padding-top: 50px;
//     padding-bottom: 50px;
//   }

//   .swiper-slide {
//     background-position: center;
//     background-size: cover;
//     width: 400px !important;
//     // height: 300px !important;

//   }
//   .swiper-slide img{
//     box-shadow: 0 0px 10px 0 rgb(45 45 45 / 89%);
//     border-radius: 15px;
//   }

//   .swiper-slide img {
//     display: block;
//     width: 100%;
//   }
#side-sizing {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px;
}
#side-sizing-mob {
  // border-radius: 20px;
  padding: 0px;
}
.fun-game-banner-data {
  background-color: #2f2943;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

#fun-game-carousel-controls .carousel-indicators {
  bottom: -55px;
}
.fun-game-button {
  padding: 0.3rem;
  border-radius: 50px;
  background-color: #8660f2;
  color: white;
  font-weight: 800;
  border: none;
  font-size: 20px;
  padding-left: 4rem;
  padding-right: 4rem;
}
.fun-game-card-body {
  min-height: 110px;
  height: auto;
  background-color: #f1e9ff;
  margin: auto;
  border-radius: 15px;
  border: 10px solid #fff;
}
.fungame-scroll-img-fix {
  background-size: cover;
  width: 100%;
  border-radius: 7px;
}
.fungame-scroll-name {
  font-size: 16px;
  font-weight: 700;
  font-size: 15px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  text-align: center;
}
.fungame-scroll-playnow-button {
  border: none;
  color: #f1e9ff;
  background-color: #8660f2;
  border-radius: 7px;
}
#fun-game-search-icon {
  background-color: #fff;
  border-right: 0px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 45px;
}
#fun-game-search-input {
  background-color: #fff;
  border-left: 0px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 45px;
}
.fun-game-text-zone {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(0, 0, 0) 80%
  );
  color: #fff;
}
#fun-game-carousel-controls .slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 40px;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.2;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#fun-game-carousel-controls .slick-dots li:hover {
  color: #00beaa;
}
#fun-game-carousel-controls .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #7d70ff;
}
@media only screen and (max-width: 600px) {
  .fungame-scroll-name {
    font-size: 11px;
  }
  .fungame-scroll-playnow-button {
    font-size: 11px;
  }
  .fun-game-catagory-head {
    font-size: 19px;
  }
  .fun-game-button {
    font-size: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .fungame-scroll-name {
    font-size: 14px;
  }
  .fungame-scroll-playnow-button {
    font-size: 14px;
  }
  .fun-game-catagory-head {
    font-size: 21px;
  }
  .fun-game-button {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .fungame-scroll-name {
    font-size: 15px;
  }
  .fungame-scroll-playnow-button {
    font-size: 15px;
  }
  .fun-game-catagory-head {
    font-size: 24px;
  }
  .fun-game-button {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) {
  .fungame-scroll-name {
    font-size: 16px;
  }
  .fungame-scroll-playnow-button {
    font-size: 16px;
  }
  .fun-game-catagory-head {
    font-size: 27px;
  }
  .fun-game-button {
    font-size: 29px;
  }
}

@media only screen and (min-width: 1200px) {
  .fungame-scroll-name {
    font-size: 15px;
  }
  .fungame-scroll-playnow-button {
    font-size: 17px;
  }
  .fun-game-catagory-head {
    font-size: 28px;
  }
  .fun-game-button {
    font-size: 29px;
  }
}
.fungame-playnow-button {
  width: 90%;
  border: none;
  color: #f1e9ff;
  background-color: #977de4;
  border-radius: 4px;
}
.fun-game-banner-data-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.fun-game-banner-data-text {
  .text-fun-game-zone {
    color: #00beaa;
  }
  .text-fun-game-zone-sub {
    color: #fff;
  }
  hr {
    background-color: #4e4764;
    height: 1.5px;
    width: 100%;
  }
  .text-fun-game-zone-sub {
    color: #ffffff;
  }
  .text-fun-game-zone-title {
    color: #fff;
    font-weight: 800;
  }
}
@media only screen and (max-width: 768px) {
  .fun-game-banner-data-text {
    .text-fun-game-zone {
      font-size: 16px;
    }
    .text-fun-game-zone-sub {
      font-size: 16px;
    }
    .text-fun-game-zone-title {
      font-size: 16px;
    }
  }
}
@media only screen and (min-width: 768px) {
  .fun-game-banner-data-text {
    .text-fun-game-zone {
      font-size: 24px;
    }
    .text-fun-game-zone-sub {
      font-size: 18px;
    }
    .text-fun-game-zone-title {
      font-size: 28px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .fun-game-banner-data-text {
    .text-fun-game-zone {
      font-size: 30px;
    }
    .text-fun-game-zone-sub {
      font-size: 20px;
    }
    .text-fun-game-zone-title {
      font-size: 30px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .fun-game-banner-data-text {
    .text-fun-game-zone {
      font-size: 35px;
    }
    .text-fun-game-zone-sub {
      font-size: 24px;
    }
    .text-fun-game-zone-title {
      font-size: 35px;
    }
  }
}

// @media screen and (min-width: 1050px) and (max-width: 1400px) {
//   #fun-game-carousel-controls .slick-prev {
//     left: -41px !important;
//   }
//   // #fun-game-carousel-control .slick-next {
//   //   right: -15px !important;
//   // }
// }
.all-fun-game-container {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
@media screen and (min-width: 768px) {
  .fun-game-spacing {
    &:after {
      content: "";
      flex: auto;
    }
  }
}
.fun-game-shimmer-head {
  height: 400px !important;
}
.shimmer-card-body {
  border: 10px solid #fff;
  height: 260px !important;
  border-radius: 20px !important;
}
