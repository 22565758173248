.nearby-partner-bill-upload-vector{
    width:300px;

    @media (min-width: 992px) {
        width:500px;
    }

}

.box-partner-detail-logo{

    height: 200px !important;

    @media (min-width: 992px) {
        height:450px !important;
    }

}

.sort {
    width: 75%;
    display: inline-block;
}

.filter-icon {
    height: .9em;
}

.category-title {
    h4 {
        font-size: 1.2rem;

        @media (min-width:992px) {
            font-size: 2rem;
        }
    }
}

.meter-handle {
    transform: translate(0px, -8px)!important;
}


.refund-form-container {
    background-color: #f8f9fa;
    border-radius: 15px;
    padding: 20px 30px;

    .terms-btn {
        margin-left: -0.5rem;
        margin-top: -.1rem;
    }

    .custom-checkbox {
        .custom-control-label::before,
        .custom-control-label::after {
            top: 0.5rem;
        }
    }

    .refund-reason-textarea {
        border-radius: 8px;
        border-color: #ddd;
        display: block;
        margin-bottom: 1rem;
        padding: 10px;
        font-size: 12px;
        width: 100%;

        @media (min-width: 992px) {
            width: 50%;
        }
    }

    .pay-now-input {
        width: 100%;

        @media (min-width: 992px) {
            width: 50%;
        }
    }
}

.refund-btn {
    width: 100%;
    margin: .5rem 0;

    @media (min-width: 992px) {
        width: 33%;
        margin: .5rem 0;
    }
}
@media screen and (max-width:1400px) {
	.card--carousel-item--nearby-partner {
		min-height: 160px;
	}
}
@media screen and (max-width:1200px) {
	.card--carousel-item--nearby-partner {
		min-height: 140px;
	}
}
@media screen and (max-width:992px) {
	.card--carousel-item--nearby-partner {
		min-height: 120px;
	}
}