.forgot-password-sent-otp-btn {
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0.5px solid #ced4da;
  box-sizing: content-box;
  background-color: #e9ecef;
  height: 2.4375em;
  display: block;
  min-width: 0;
  width: 100%;
  padding-top: 1px;
  padding: 7.8px 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0px;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}
.valid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #38c172;
}
.captain-Name {
  font-size: small;
}
.mdc-select__native-control, .mdc-select__selected-text {
  text-indent: 0;
}