body {
  font-family: proxima-nova, sans-serif !important;
}
.service-categories {
  background-image: url(../assets/home/ic_category_bg.png);
  background-size: cover;
}
.home-page {
  padding: 20px 0px 20px 0px;
}
.home-page .space {
  margin: 15px 0;
}

.home-page .title {
  color: #3f4850;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 45px;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
  user-select: none;
}
.home-page .sub-title {
  color: #464646;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 42px;
  user-select: none;
}
.home-page .desc {
  color: #808080;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}
.title-slim {
  color: #3f4850;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 5px;
}
.title-thick {
  color: #3f4850;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 600;
}
.view-all {
  width: 25px;
  vertical-align: baseline;
  margin-left: 8px;
}
.font-slim {
  font-weight: 500;
  color: #6C6C6C;
  
}
.service-categories-link {
  z-index: 9;
}
.service-categories-link .box {
  border: 1.5px solid #d4d0bd;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 5px;
  background: #f0ede0;
  position: relative;
}
.service-categories-link .box-title {
  text-align: center;
  font-size: 14px;
  margin-top: 8px;
}
.service-categories-link .box .arrow {
  position: absolute;
  bottom: -5px;
}
.service-categories-link .box .icon {
  width: 56%;
}
.home-page .categories-img {
  width: 75%;
  scale: 130%;
  margin-top: -50px;
}
.carousel-control-next-icon {
  background: url("../assets/home/ic_next_button.svg");
  width: 60px;
  height: 60px;
  background-size: cover;
}
.carousel-control-prev-icon {
  background: url("../assets/home/ic_previous_button.svg");
  width: 60px;
  height: 60px;
  background-size: cover;
}

//vouchers
.home-vouchers {
  // background-image: url(../assets/home/ic_vouchers_bg.svg);  
  background: linear-gradient(180deg, #FFFFFF4D, #C3D9FF4D);
  background-size: cover;
}
.home-vouchers .voucher-card {
  width: 20.1%;
  position: relative;
  cursor: pointer;
}
.home-voucher-box {
  margin-bottom: 40px;
}
.home-voucher-box .bg {
  width: 95%;
  position: relative;
}
.home-voucher-box .logo {
  width: 75%;
  position: absolute;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 40%;
}
.home-voucher-box .name {
  color: #3f4850;
  font-size: 22px;
  letter-spacing: 0;
  font-weight: 600;
  text-align: center;
  position: absolute;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 29%;
}

//nearby partners
.home-nearby-partners {
  // background: #f8f8f8;
  background: linear-gradient(90deg, #E4EEFF66, #BEFFDA66);
}
.nearby-box {
  width: fit-content !important;
  height: 240px;
  background: #5d6a84;
  margin: 15px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}
.cat-label {
  color: #ffffff;
  text-align: center;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  padding: 0 5px;
  line-height: normal;
}
.slick-prev {
  left: -70px !important;
}
.slick-next {
  right: -50px !important;
}
.slick-next:before {
  content: "";
  background-image: url("../assets/home/ic_next_dark.svg");
  background-size: cover;
  display: inline-block;
  width: 35px;
  height: 35px;
}
.slick-prev:before {
  content: "";
  background-image: url("../assets/home/ic_previous_dark.svg");
  background-size: cover;
  display: inline-block;
  width: 35px;
  height: 35px;
}

.nearby-box img.Badge {
  z-index: 9;
  position: absolute;
  left: 20px;
  top: 0;
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 0;
}
//fun-game
.fun-game-box {
  width: fit-content !important;
  // height: 240px;
  background: #F0F0F0;
  margin: 15px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  #fun-game-img {
    border-radius: 15px;
  }
  .element.style {
    box-shadow: none;
  }
}
.fun-game-title span {
  // width: fit-content !important;
  color: #6d6d6d;
  font-weight: 900;
  margin: 15px;
  font-size: medium;
  // min-height: 50px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fun-game-label {
  text-align: center;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  // padding: 6px;
  line-height: normal;
}
.fun-game-playnow-title {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 100;
  color: #0C9648;
}

//billpay
// .home-billpay {
//   // background: #f8f8f8;
// }
.billpay-box-img {
  // width: 179px !important;
  // height: 179px !important;
  padding: 10px;
  background: #5d6a84;
  margin: 15px;
  border-radius: 12px;
  cursor: pointer;
}

.billpay-label {
  color: #6d6d6d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: smaller;
}
//refer-earn
.home-refer-earn {
  // background: url("../assets/home/ic_winds_patrons_bg.png");
  background: linear-gradient(90deg, #FFFFFF4D, #F6DA6F4D);
  background-size: cover;
}
.refer-earn-box {
  background: radial-gradient(
    ellipse at right bottom,
    #a6bbff 0%,
    #7b6dff 100%
  );
  border-radius: 15px;
  padding: 15px;
  min-height: 100%;
}
.refer-img {
  margin-top: -40px;
  width: 75%;
}
.font-button {
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 25px;
  width: fit-content;
  cursor: pointer;
}
.button-color1 {
  background: #7b6dff;
}
.button-color2 {
  background: #00ae83;
  float: right;
}
.button-color3 {
  background: #e0af3c;
  float: right;
  padding: 15px 20px;
}
.button-color4 {
  background: #5f4eff;
  padding: 8px 20px;
}
.button-color5 {
  background: #009e8c;
  padding: 8px 20px;
}
.font-button img {
  width: 20px;
  margin-left: 6px;
}
.button-color3 img {
  margin-left: 0;
}
.refer-earn-box input.form-control.refferal-code-input {
  color: #6372ff;
  font-weight: 600;
  font-size: large;
}
.refer-earn-box .refferal-code-input {
  min-height: 35px;
}
.refer-earn-box .share-copy {
  width: 20px;
  cursor: pointer;
}
input.form-control.refferal-code-input {
  max-width: 90px;
}
.refer-earn-box .form-control,
.refer-earn-box .input-group-text {
  border: none;
  border-radius: 10px;
}
.refer-earn-box .form-control:focus {
  box-shadow: none;
}
.refer-earn-box .share-links-dropdown {
  bottom: -55px;
  left: -100%;
  transition: all 0.5s ease-in-out;
}
.what-is-wrp {
  background: radial-gradient(
    ellipse at right bottom,
    #3cc881 0%,
    #00beaa 100%
  );
  position: relative;
  border-radius: 15px;
  padding: 15px;
}
.wrp-img {
  position: absolute;
  top: -20%;
  right: 3%;
}
.coupons-img {
  position: absolute;
  top: -15%;
  width: 150px;
  right: 3%;
}
.wrp-coupons {
  background: radial-gradient(ellipse at right top, #fff3a4 0%, #e7b94c 100%);
  position: relative;
  border-radius: 15px;
  padding: 15px;
}
.wrp .count,
.coupons .count {
  color: #752700;
  font-weight: 600;
  font-size: x-large;
}

//Insurance
.home-insurance {
  background: url("../assets//home/ic_bg_insurance.png");
  background-size: cover;
}
.insurance-box {
  background-size: cover;
  border-radius: 15px;
  padding: 25px 20px;
  width: 100%;
  border: none;
  // width: 90%;
  // margin: auto;
}
@media screen and (min-width: 992px) {
  .single-padding {
    padding: 25px 30px;
  }

}

.insurance-box > img {
  width: 100%;
  margin: auto;
  scale: 110%;
}
.text-black {
  color: #3f4850 !important;
}
.home-insurance .font-button {
  font-size: 18px;
  padding: 6px 15px;
  letter-spacing: 0.5px;
}
.insurance-box .title {
  font-size: 35px;
}

//Travel
.home-travel {
  background: #f2f2f2;
}
.travel-box {
  background: url("../assets//home/ic_travel_flightz_bg.png");
  background-size: cover;
  border-radius: 15px;
  padding: 25px 30px;
  width: 100%;
  border: none;
  flex-direction: column-reverse !important;

  // width: 90%;
  // margin: auto;
}
.travel-box-hotel {
  background: url("../assets//home/ic_hotel_bg.png");
  background-size: cover;
  border-radius: 15px;
  padding: 25px 30px;
  width: 100%;
  border: none;
  flex-direction: column-reverse !important;

  // width: 90%;
  // margin: auto;
}
@media screen and (min-width: 767px) {
  .travel-box {
    flex-direction: unset !important;
  }
  .travel-box-hotel {
    flex-direction: unset !important;
  }
}

//Shopping sites
.home-shopping-sites {
  background: url("../assets//home/ic_bg_shopping_sites.png");
  background-size: cover;
}
.shopping-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
.shopping-box-image {
  width: 15%;
  background: white;
  padding: 10px;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-radius: 12px;
  overflow: hidden;
}
.shopping-box-image:first-child {
  width: 32%;
}
.shopping-box-image:nth-child(9) {
  width: 32%;
}
.shopping-box-image:nth-child(12) {
  width: 32%;
}
.shopping-box-image:nth-child(19) {
  width: 32%;
}
.shopping-box-image:nth-child(21) {
  width: 32%;
}
.shopping-box-image img {
  width: 70%;
}
.shopping-box-image:last-child {
  width: 32%;
}
.shopping-box-image:first-child img,
.shopping-box-image:last-child img,
.shopping-box-image:nth-child(9) img,
.shopping-box-image:nth-child(12) img,
.shopping-box-image:nth-child(19) img,
.shopping-box-image:nth-child(21) img {
  width: 55%;
}

.nearby-box img {
  min-height: 195px;
  border-radius: 15px;
}
.billpay-label img {
  width: 20px;
  margin-left: 5px;
}
.home-billpay img.billpay{
  width: 35px;
  margin-bottom: 5px;
}
.shopping-badge-text {
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
@media screen and (min-width: 1601px) {
  .nearby-box {
    min-height: 270px;
  }
  .nearby-box img {
    min-height: 220px;
  }
  .wrp .desc,
  .coupons .desc {
    width: 70%;
  }
}
@media screen and (max-width: 1400px) {
  .home-page .categories-img {
    scale: 100%;
  }
  .slick-prev {
    left: 10px !important;
    z-index: 9;
  }
  .slick-next {
    right: 50px !important;
  }
  .shopping-badge-text {
    font-size: 13px;
  }
}
@media screen and (max-width: 1200px) {
  .home-page .categories-img {
    scale: 100%;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px;
  }
  .navbar .navbar-nav .nav-item .nav-text {
    font-size: 14px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0 1rem;
  }
  .service-categories-link .box-title {
    font-size: 12px;
    margin-top: 10px;
  }
  .wrp-coin {
    min-width: 90px;
    max-width: 90px;
  }
  .account-info {
    min-width: 120px;
    max-width: 140px;
  }
  .slick-prev {
    left: 10px !important;
    z-index: 9;
  }
  .slick-next {
    right: 50px !important;
  }
  .home-page .title {
    font-size: 35px;
    line-height: 45px;
  }

  .home-page .sub-title {
    font-size: 30px;
    line-height: 35px;
  }
  .title-slim {
    font-size: 28px;
    line-height: 35px;
  }
  .title-thick {
    font-size: 18px;
    line-height: 28px;
  }
  .home-page .desc {
    font-size: 16px;
    line-height: 24px;
  }
  .shopping-badge-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar .navbar-nav .nav-item .nav-link img {
    height: 2rem;
  }
  .home-voucher-box .name {
    font-size: 18px;
    bottom: 32%;
  }
  .account-info {
    min-width: 125px;
    max-width: 125px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }
  .navbar .navbar-nav .nav-item .nav-text {
    font-size: 12px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0 0.5rem;
  }
  .slick-next {
    right: 35px !important;
  }
  .home-page .title {
    font-size: 30px;
    line-height: 35px;
  }
  .font-button {
    font-size: 15px;
  }
  .home-page .sub-title {
    font-size: 25px;
    line-height: 32px;
  }
  .title-slim {
    font-size: 28px;
    line-height: 35px;
  }
  .title-thick {
    font-size: 16px;
    line-height: 22px;
  }
  .home-page .desc {
    font-size: 16px;
    line-height: 24px;
  }
  .site-footer h4 {
    font-size: 1rem;
  }
  .site-footer p {
    font-size: 14px;
  }
  .social-links a {
    margin-right: 20px;
  }
  .wrp-img,
  .coupons-img {
    width: 40%;
  }
  .fun-game-playnow-title {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 900px) {
  // .home-page {
  //   padding: 50px 0;
   
  // }
  .wrp-coup {
    gap: 10%;
  }
  .nearby-box {
    height: 215px;
  }
  .wrp-coin {
    min-width: 80px;
    max-width: 80px;
  }
  .nearby-box img {
    min-height: 170px;
  }
  .navbar .navbar-nav .nav-item .nav-link img {
    height: 1.5rem;
  }
  img.wrp-coin-img,
  .userIcon {
    height: 1.2rem !important;
  }

  .home-voucher-box .name {
    font-size: 15px;
    bottom: 35%;
  }
  .account-info {
    min-width: 100px;
    max-width: 120px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }
  .navbar .navbar-nav .nav-item .nav-text {
    font-size: 12px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0 0.5rem;
  }
  .slick-next {
    right: 35px !important;
  }
  .home-page .title {
    font-size: 25px;
    line-height: 30px;
  }
  .service-categories-link .box {
    width: 85px;
    height: 85px;
  }
  .cat-label {
    font-size: xx-small;
  }
  .refer-earn-box .share-copy {
    width: 10px;
  }
  .refer-earn-box input.form-control.refferal-code-input {
    font-size: x-small;
  }
  .refer-earn-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .font-button {
    font-size: 12px;
  }
  .home-page .sub-title {
    font-size: 25px;
    line-height: 32px;
  }
  .title-slim {
    font-size: 22px;
    line-height: 28px;
  }
  .title-thick {
    font-size: 15px;
    line-height: 20px;
  }
  .home-page .desc {
    font-size: 14px;
    line-height: 22px;
  }
  .site-footer h4 {
    font-size: 1rem;
  }
  .site-footer p {
    font-size: 14px;
  }
  .social-links a {
    margin-right: 20px;
  }
  .view-all {
    width: 15px;
  }
  .fun-game-playnow-title {
    font-size: 15px;
    line-height: 20px;
  }
  .fun-game-label {
    height: 14px;
  }
  .shopping-badge-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .travel-img {
    width: 80%;
    height: auto !important;
  }
  .shopping-box-image img {
    width: 70% !important;
  }
  .refer-mock {
    margin-top: 40px;
  }
  #mobile-location {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #mobile-location .location-name {
    color: #3f4850;
  }
  .navbar .navbar-nav .nav-item .nav-link img {
    height: 2rem;
  }
  img.wrp-coin-img,
  .userIcon {
    height: 1.5rem !important;
  }

  .account-info {
    min-width: 140px;
    max-width: 140px;
  }
  .wrp-coin {
    min-width: 90px;
    max-width: 90px;
  }
  .home-reverse {
    flex-direction: column-reverse;
  }
  .nearby-box img {
    min-height: 130px;
  }
  .nearby-box {
    height: 165px;
  }
  .cat-label {
    height: 30px;
  }
  .title-thick {
    font-size: 14px;
    line-height: 18px;
  }
  .home-voucher-box .logo {
    width: 75%;
    bottom: 50%;
  }
  .home-voucher-box .name {
    font-size: 13px;
    bottom: 30%;
  }
  .home-voucher-box {
    margin-bottom: 30px;
  }
  .home-vouchers .voucher-card {
    width: 22.5%;
  }
  .slick-next:before,
  .slick-prev:before {
    width: 25px;
    height: 25px;
  }
  .slick-next {
    right: 15px !important;
  }
  .slick-prev {
    left: 5px !important;
  }
  .billpay-label {
    font-size: x-small;
  }
  .insurance-box {
    margin-bottom: 40px;
  }
  .insurance-box > img {
    width: 75%;
    margin: auto;
    scale: 100%;
  }
  .refer-img {
    right: 5%;
    width: 30%;
    position: absolute;
  }
  .wrp-img,
  .coupons-img {
    width: 20%;
  }
  .refer-earn-box {
    margin-top: 40px;
  }
  .what-is-wrp {
    margin-top: 80px;
  }
  .fun-game-playnow-title {
    font-size: 10px;
  }
  .fun-game-label {
    height: 12px;
  }
  .shopping-badge-text {
    font-size: 8px;
  }
}
.travel-img {
  // width: 40%;
  height: 200px;
}

@media screen and (max-width: 600px) {
  .home-billpay img.billpay{
    width: 16px;
  }
  .billpay-label img {
    width: 15px;
    margin-left: 3px;
  }
  span.header-rewards {
    font-size: 6px;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    right: 12%;
    line-height: 12px;
  }
  .what-is-wrp {
    margin-top: 30px;
  }
  .refer-earn-box input.form-control.refferal-code-input {
    font-size: medium;
  }
  .navbar .navbar-nav .nav-item .dropdown-menu {
    min-width: 9rem;
  }
  .dropdown-menu {
    font-size: 0.7rem;
  }
  .home-page {
    padding: 10px 0;
  }
  .navbar .navbar-nav .nav-item .nav-link img {
    height: 1.9rem;
  }

  .wrp-coin {
    min-width: 80px;
    max-width: 80px;
  }
  .account-info {
    min-width: 130px;
    max-width: 130px;
  }
  .home-page .title {
    font-size: 26px;
    line-height: 32px;
  }
  .nearby-box {
    margin: 10px;
  }
  .nearby-box img {
    min-height: 100px;
  }
  .home-page .space {
    margin: 10px 0;
  }
  .desc.w-75 {
    width: 100% !important;
  }
  .shopping-box-image {
    width: 23.5% !important;
    height: 50px;
    padding: 8px 5px;
  }
  .shopping-box-image img {
    width: 80%;
  }

  .home-page .sub-title {
    font-size: 20px;
    line-height: 25px;
  }

  .home-page .desc {
    font-size: 16px;
    line-height: 24px;
  }
  .title-slim {
    font-size: 20px;
    line-height: 30px;
  }
  .refer-earn-box {
    min-height: 85%;
  }
  .refer-img {
    top: auto;
    bottom: 10%;
    width: 20%;
  }
  .home-page .categories-img {
    margin-left: -20px;
  }
  .travel-box {
    padding: 20px 15px;
  }
  .refer-earn-box .share-copy {
    width: 20px;
  }
  .mobile-hide span {
    display: none !important;
  }
  .view-all {
    width: 20px;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 30px;
    height: 30px;
  }
  .carousel-indicators li {
    width: 6px;
    height: 6px;
  }
  .carousel-indicators {
    bottom: -15%;
  }
  .insurance-box {
    padding: 20px 15px;
  }
  .home-insurance .font-button {
    font-size: 12px;
    .fun-game-playnow-title {
      font-size: 10px;
    }
  }
  .shopping-badge-text {
    font-size: 10px;
  }
}
@media screen and (max-width: 500px) {
  .nearby-box {
    height: 150px;
  }
  .fun-game-playnow-title {
    font-size: 9px;
  }
  .fun-game-label {
    height: 7px;
  }
  .shopping-badge-text {
    font-size: 8px;
  }
}
@media screen and (max-width: 450px) {
  .wrp-coin span {
    font-size: 9px;
  }
  .billpay-box-img {
    margin: 12px;
  }
  .service-categories-link .box {
    width: 70px;
    height: 70px;
  }
  .home-voucher-box .logo {
    width: 70%;
    bottom: 45%;
  }
  .nearby-box {
    height: 125px;
    margin: 10px;
  }
  .home-voucher-box .name {
    font-size: 8px;
    bottom: 26%;
  }
  .home-page .title {
    font-size: 24px;
    line-height: 30px;
  }
  .billpay-label {
    font-size: xx-small;
  }
  .service-categories-link .box-title {
    font-size: 9px;
    margin-top: 10px;
  }
  .nearby-box img {
    min-height: 80px;
  }
  .billpay-label img {
    width: 10px;
    margin-left: 2px;
  }
  .navbar .navbar-nav .nav-item .nav-link img {
    height: 1.5rem;
  }
  img.wrp-coin-img,
  .userIcon {
    height: 1.2rem !important;
  }
  .account-info {
    min-width: 100px;
    max-width: 100px;
  }
  .account-info span {
    font-size: xx-small;
  }
  .wrp-coin {
    min-width: 55px;
    max-width: 55px;
    padding: 3px 3px;
  }
  .wrp-coin img {
    height: 1.1rem;
  }
  .userIcon,
  .wrp-coin-img {
    height: 1.1rem;
  }
  .cat-label {
    font-size: 8px;
    padding: 0 2px;
  }
  .what-is-wrp {
    margin-top: 30px;
  }
  .wrp-img {
    top: 0%;
  }
  .coupons-img {
    top: 4%;
  }
  .home-vouchers .voucher-card {
    width: 24%;
  }
  .home-voucher-box {
    margin-bottom: 15px;
  }
  .shopping-box-image {
    width: 32% !important;
    height: 60px;
    margin-bottom: 2.5%;
  }
  .fun-game-playnow-title {
    font-size: 7px;
  }
  .shopping-badge-text {
    font-size: 8px;
  }
}

@media screen and (max-width: 380px) {
  .cat-label {
    font-size: 8px;
    padding: 0 2px;
  }
  .nearby-box {
    height: 145px;
    margin: 10px;
  }
  .home-page .title {
    font-size: 22px;
    line-height: 26px;
  }
  .title-slim {
    font-size: 18px;
    line-height: 25px;
  }
  .home-page .desc {
    font-size: 15px;
    line-height: 22px;
  }
  .fun-game-playnow-title {
    font-size: 10px;
  }
  .fun-game-label {
    height: 9px;
  }
  .shopping-badge-text {
    font-size: 8px;
  }
}
@media screen and (max-width: 340px) {
  .home-vouchers .voucher-card {
    width: 31%;
  }
  .service-categories-link .box {
    width: 60px;
    height: 60px;
  }
  .service-categories-link .box-title {
    font-size: 8px;
    margin-top: 8px;
  }

  .navbar .navbar-nav .nav-item .nav-link img {
    height: 1.2rem;
  }
  .wrp-coin {
    min-width: 45px;
    max-width: 45px;
    padding: 2px;
  }
  .account-info span {
    font-size: 7px;
  }
  .wrp-coin span {
    font-size: 7px;
  }
  img.wrp-coin-img,
  .userIcon {
    height: 0.9rem !important;
  }
  .account-info {
    min-width: 80px;
    max-width: 80px;
  }
  .account-info {
    min-width: 80px;
    max-width: 80px;
  }
  .account-info img {
    height: 5px !important;
  }
  .nearby-box {
    height: 130px;
  }
  .fun-game-playnow-title {
    font-size: 7px;
  }
  .fun-game-label {
    height: 8px;
  }
  .shopping-badge-text {
    font-size: 7px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .cat-label {
    height: 30px;
    font-size: x-small;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0 1rem;
  }
}
.shoping-card-title {
  color: #6d6d6d;
  font-weight: 900;
  font-size: medium;
  // min-height: 50px;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  span {
    width: 70%;
    text-overflow: ellipsis;
    text-align: center;
  }
  // @media screen and (min-width: 400px) {
  //   font-size: 10px;
  // }
  // @media screen and (min-width: 600px) {
  //   font-size: 12px;
  // }
  // @media screen and (max-width: 700px) {
  //   font-size: 18px;
  // }
}
.shopping-carousal-box {
  width: fit-content !important;
  min-height: 130px;
  background: #ffffff;
  margin: 5px;
  border: 1px solid #bdbdbdd1;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 290px) {
    min-height: 130px;
  }
  @media screen and (min-width: 320px) {
    min-height: 142px;
  }
  @media screen and (min-width: 360px) {
    min-height: 185px;
  }
  @media screen and (min-width: 380px) {
    min-height: 190px;
  }
  @media screen and (min-width: 400px) {
    min-height: 195px;
  }
  @media screen and (min-width: 420px) {
    min-height: 152px;
  }
  @media screen and (min-width: 450px) {
    min-height: 160px;
  }
  @media screen and (min-width: 500px) {
    min-height: 175px;
  }
  @media screen and (min-width: 550px) {
    min-height: 177px;
  }
  @media screen and (min-width: 570px) {
    min-height: 187px;
  }
  @media screen and (min-width: 600px) {
    min-height: 180px;
  }
  @media screen and (min-width: 700px) {
    min-height: 196px;
  }
  @media screen and (min-width: 768px) {
    border-radius: 10px;
    min-height: 235px;
  }
  @media screen and (min-width: 900px) {
    min-height: 260px;
    border-radius: 13px;
  }
  @media screen and (min-width: 1000px) {
    min-height: 269px;
  }
  @media screen and (min-width: 1100px) {
    min-height: 272px;
  }
  @media screen and (min-width: 1200px) {
    min-height: 285px;
  }
  @media screen and (min-width: 1250px) {
    min-height: 289px;
  }
  @media screen and (min-width: 1300px) {
    min-height: 315px;
  }
  @media screen and (min-width: 1600px) {
    min-height: 355px;
  }
  #shopping-img {
    border-radius: 15px;
  }
  .element.style {
    box-shadow: none;
  }
}

.shopping-earn-caurosal-badge {
  position: absolute;
  top: 0;
  text-align: center;
  min-height: 130px;
  @media screen and (min-width: 290px) {
    min-height: 130px;
  }
  @media screen and (min-width: 320px) {
    min-height: 142px;
  }
  @media screen and (min-width: 360px) {
    min-height: 185px;
  }
  @media screen and (min-width: 380px) {
    min-height: 190px;
  }
  @media screen and (min-width: 400px) {
    min-height: 195px;
  }
  @media screen and (min-width: 420px) {
    min-height: 152px;
  }
  @media screen and (min-width: 450px) {
    min-height: 160px;
  }
  @media screen and (min-width: 500px) {
    min-height: 175px;
  }
  @media screen and (min-width: 550px) {
    min-height: 177px;
  }
  @media screen and (min-width: 570px) {
    min-height: 187px;
  }
  @media screen and (min-width: 600px) {
    min-height: 180px;
  }
  @media screen and (min-width: 700px) {
    min-height: 196px;
  }
  @media screen and (min-width: 768px) {
    min-height: 235px;
  }
  @media screen and (min-width: 900px) {
    min-height: 260px;
  }
  @media screen and (min-width: 1000px) {
    min-height: 269px;
  }
  @media screen and (min-width: 1100px) {
    min-height: 272px;
  }
  @media screen and (min-width: 1200px) {
    min-height: 285px;
  }
  @media screen and (min-width: 1250px) {
    min-height: 289px;
  }
  @media screen and (min-width: 1300px) {
    min-height: 315px;
  }
  @media screen and (min-width: 1600px) {
    min-height: 355px;
  }
}
.shop-carousel-btn {
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  margin-top: -25px;
  background-color: #9983ea;
  z-index: 2;
  @media screen and (max-width: 350px) {
    margin-top: -15px;
  }
}
.shopping-carousal-inner-badge-bg {
  background-color: #ffffffad;
  width: auto;
  color: #494949;
  font-weight: bold;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: -3px;
}

.testimonial-star-ratings {
  div.stars {
    width: 160px;
    display: inline-block;
    @media screen and (max-width: 420px) {
      width: 100px;
      margin-top: 6px;
    }
    @media screen and (max-width: 1200px) and (min-width: 900px) {
      width: 100px;
      margin-top: 15px;
    }
  }
  input.star {
    display: none;
  }

  label.star {
    float: right;
    padding: 7px;
    font-size: 15px;
    color: #ffef77b3;
    transition: all 0.2s;
    @media screen and (max-width: 420px) {
      font-size: 12px;
      padding: 2px;
    }
    @media screen and (max-width: 1200px) and (min-width: 900px) {
      font-size: 12px;
      padding: 2px;
    }
  }

  input.star:checked ~ label.star:before {
    content: "\f005";
    color: #ffca00;
    transition: all 0.25s;
  }

  input.star-5:checked ~ label.star:before {
    color: #ffca00;
    // text-shadow: 0 0 20px rgb(138, 135, 133);
  }

  input.star-1:checked ~ label.star:before {
    color: #ffca00;
  }
  label.star:before {
    content: "\f006";
    font-family: FontAwesome;
  }
}

.shoping-badge-content-home {
  display: flex;
  // margin-top: -25px;
  margin-top: -12px;
}
.shopping-offer-badge-home {
  color: #fff;
  font-weight: bold;
  background-color: #27b455;
  border-bottom-left-radius: 5px;
  text-align: left;
}
.shoping-badge-content-home::after {
  content: " ";
  width: 11%;
  background-color: #ffffff;
  rotate: 45deg;
  margin-left: -10px;
  margin-bottom: 3px;
}

@media only screen and (max-width: 350px) {
  .shoping-badge-content-home {
    display: flex;
    margin-top: 7px;
  }

  .shoping-badge-content-home::after {
    margin-left: -6px;
    margin-bottom: 4px;
    width: 16%;
  }
}
@media only screen and (min-width: 300px) {
  .shoping-badge-content-home {
    border-bottom-left-radius: 5px;
  }
  .shoping-badge-content-home::after {
    margin-left: -4px;
    margin-bottom: 2px;
    width: 11px;
  }
}
@media only screen and (min-width: 400px) {
  .shoping-badge-content-home::after {
    margin-left: -3px;
  }
}
@media only screen and (min-width: 500px) {
  .shoping-badge-content-home::after {
    margin-left: -9px;
    margin-bottom: 2px;
    width: 15%;
  }
}
@media only screen and (min-width: 600px) {
  .shoping-badge-content-home::after {
    margin-left: -5px;
    margin-bottom: 3px;
    width: 12%;
  }
}
@media only screen and (min-width: 768px) {
  .shopping-offer-badge-home {
    padding-left: 5px !important;
    font-size: 10px;
    border-bottom-left-radius: 10px;
  }

  .shoping-badge-content-home::after {
    margin-left: -10px;
    margin-bottom: 1px;
    width: 15%;
  }
}
@media only screen and (min-width: 850px) {
  .shopping-offer-badge-home {
    padding-left: 5px !important;
    font-size: 10px;
  }

  .shoping-badge-content-home::after {
    margin-left: -10px;
    margin-bottom: 1px;
    width: 14%;
  }
}
@media only screen and (min-width: 992px) {
  .shopping-offer-badge-home {
    font-size: 12px;
    border-bottom-left-radius: 13px;
  }
  .shoping-badge-content-home::after {
    margin-left: -9px;
    margin-bottom: 0px;
    width: 19%;
  }
}
@media only screen and (min-width: 1200px) {
  .shopping-offer-badge-home {
    font-size: 14px;
  }
  .shoping-badge-content-home::after {
    margin-left: -6px;
    margin-bottom: 4px;
    width: 10%;
  }
}

.testimonial-reviw-box {
  background-color: #fff;
  border-radius: 20px;
  // min-height: 400px;
  @media only screen and (max-width:700px) {
    min-height: 400px;
  }
  @media only screen and (min-width:700px) {
    min-height: 540px;
  }
  @media only screen and (min-width:1000px) {
    min-height: 584px;
  }
  @media only screen and (min-width:1300px) {
    min-height: 530px;
  }
}
.winds-journey-box {
  background: linear-gradient(180.26deg, #75ebc7 0%, #00beaa 100%);
  border-radius: 25px;
  img{
    width: 50%;
    @media only screen and (min-width:557px) {
      width: 75%;
    }
  }
}
.gradient-margin-right {
  border-right: 3px solid transparent;
  border-image: linear-gradient(180deg,rgba(255, 255, 255, 0) 0%, #ffffff 50.98%,rgba(255, 255, 255, 0) 100%) 1 100%;
  @media only screen and (max-width: 575px) {
    border-right: 0px solid transparent;
  }
}
.gradient-margin-right2 {
  border-right: 0px solid transparent;
  border-image: linear-gradient(180deg,rgba(255, 255, 255, 0) 0%, #ffffff 50.98%,rgba(255, 255, 255, 0) 100%) 1 100%;
  @media only screen and (min-width: 575px){
    border-right: 0px solid transparent;
  }
  @media only screen and (min-width: 768px){
    border-right: 3px solid transparent;
  }
}
.gradient-margin-bottom {
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(0.25turn, rgba(255, 248, 34, 0),rgb(255, 255, 255), rgba(56, 2, 155, 0) );
  border-image-slice: 1;
  width: 100%;
  @media only screen and (min-width: 768px) {
    border-bottom: 0px solid transparent;
  }
}
.gradient-margin-top {
  border-top: 3px solid transparent;
  border-image: linear-gradient(0.25turn, rgba(255, 248, 34, 0),rgb(255, 255, 255), rgba(56, 2, 155, 0) );
  border-image-slice: 1;
  width: 100%;
  // @media only screen and (max-width: 575px){
  //   border-right: 0px solid transparent;
  // }
  @media only screen and (min-width: 575px){
    border-top: 0px solid transparent;
  }
}
.winners-play-button {
  top: 25%;
  .play-btn {
    width: 50px;
  }
  @media only screen and (max-width: 320px) {
    top: 25%;
    .play-btn {
      width: 40px;
    }
  }
  @media only screen and (min-width: 320px) {
    top: 28%;
  }
  @media only screen and (min-width: 420px) {
    top: 31%;
    .play-btn {
      width: 55px;
    }
  }
  @media only screen and (min-width: 480px) {
    top: 19%;
    .play-btn {
      width: 40px;
    }
  }
  @media only screen and (min-width: 500px) {
    top: 23%;
    .play-btn {
      width: 40px;
    }
  }
  @media only screen and (min-width: 600px) {
    top: 18%;
    .play-btn {
      width: 34px;
    }
  }
  @media only screen and (min-width: 768px) {
    top: 24%;
    .play-btn {
      width: 40px;
    }
  }
  @media only screen and (min-width: 992px) {
    top: 30%;
    .play-btn {
      width: 52px;
    }
  }
}
// .winds-journey-container {
//   background-image: url("../../src/assets/home/winds_journey/ic_journey_bg.png");
// }
.marchandize-link{
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  height: -webkit-fill-available;
  width: 20px;
}
@media only screen and (min-width: 600px) {
  .marchandize-link{
    width: 200px;
  }
}
.cate-img{
  display: flex !important;
  align-items: center !important;
} 
@media (min-width:768px ) and (max-width:1024px){
  .categories-img{
    scale: 150%!important;
  } 
}
@media (min-width:1024px ) and (max-width:1200px){
  .categories-img{
    scale: 150%!important;
  } 
}
@media (min-width:1200px ) and (max-width:1300px){
  .home-page .categories-img{
    scale: 150%!important;
  } 
}
@media (min-width:1300px ) and (max-width:1400px){
  .home-page .categories-img{
    scale: 130%!important;
  } 
}
@media (min-width:768px) and (max-width:769px)
{
  .categories-img{
    scale: 150% !important;
  }
}
.kyc-cardmanage-banner-home {
  background-color: #eae4ff;
  padding: 30px 0;
}
.title-slim-small {
  color: #3f4850;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 400px) {
  .title-slim-small {
    font-size: 16px;
	line-height: 30px;
  }
}
@media only screen and (min-width: 400px) {
  .title-slim-small {
    font-size: 16px;
  }
}
@media only screen and (min-width: 600px) {
  .title-slim-small {
    font-size: 22px;
  }
}
@media only screen and (min-width: 768px) {
  .title-slim-small {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) {
  .title-slim-small {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1800px) {
  .title-slim-small {
    font-size: 28px;
  }
}
.title-slim-head{
	font-weight: 200;
}
.kyc-tic-icon{
	width:36px;
}