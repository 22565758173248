.site-footer{
    background-color: #343540;
    padding: 70px 0 50px;
}
.site-footer p{
    color: #B2B2B2;
}
.site-footer p:hover{
    color: #ebebeb;
}
.site-footer-heart{
    background: #3C3D4A;
}
    .social-links {
        position: relative;
        display: flex;
        justify-content: flex-start;
        a {
            display: inline-block;
            font-size: 18px;
            margin-right: 30px;
            transition: all 0.3s ease-in;

            &:hover {
                transform: scale(1.02) translateY(-2px);
            }

            &:first-child {
                margin-left: 0;
            }

            // &.facebook:hover {
            //     background-color: #3b5998;
            // }

            // &.twitter:hover {
            //     background-color: #00aced;
            // }

            // &.linkedin:hover {
            //     background-color: #007bb6;
            // }

            // &.pinterest:hover {
            //     background-color: #cb2027;
            // }

            // &.instagram:hover {
            //     background-color: #ef3434;
            // }

            // &.youtube:hover {
            //     background-color: #bb0000;
            // }
        }
    }

.container-fluid--sub-footer {

    background-color: #2A2929;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    color: #fff;
}
.footer-heading{
    font-size: larger;
    line-height: 50px;
}