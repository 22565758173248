.container {
  max-width: 1440px;
}
@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .container {
    max-width: 1250px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1299px) {
  .container {
    max-width: 1100px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1099px) {
  .container {
    max-width: 1000px;
  }
}

.testimonialsWrapper .row > div {
  margin-bottom: 30px;
}
.bullet li {
  list-style: disc;
  color: #808080;
}
.hero {
  margin-top: 100px;
  width: 90%;
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
  background: url(../assets/img/w.png) no-repeat bottom center;
  background-size: contain;
  padding: 30px 0;
}

.hero .comm-head-fon {
  line-height: 1.1;
  margin-top: 15px;
  color: #121640;
}

.hero span {
  color: #ffbc01;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.3em;
}

.comm-head-fon {
  color: #121640;
  font-size: 25px;
  line-height: 70px;
  text-transform: uppercase;
  font-weight: 600;
}
.winner-zone-sub-text{
  text-align: center;
    font-size: 22px;
  @media (min-width: 999px) {
    text-align: left;
    font-size: 30px;
  }
}

@media (min-width: 999px) {
  .hero {
    margin-top: 150px;
    // padding: 60px 0;
    padding-top: 60px;
  }
}

@media (max-width: 768px) {
  .hero {
    margin-top: 100px;
    max-width: 60%;
    background: url(../assets/img/w.png) no-repeat center center;
    background-size: contain;
  }

  .hero .comm-head-fon {
    font-size: 23px;
  }
}

@media (max-width: 558px) {
  .hero {
    margin-top: 100px;
    max-width: 90%;
  }

  .hero .comm-head-fon {
    font-size: 18px;
    letter-spacing: 0.2rem;
  }
}

.testimonial {
  padding: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.testimonials {
  background: #fff;
  padding-top: 100px;
  margin: 0;
}

.testimonials > div {
  margin-bottom: 40px;
}

.winners-page .hero {
  margin-top: 50px;
}

.winners-page .testimonials {
  padding-top: 30px;
  padding-bottom: 30px;
}

.embed::before {
  display: block;
  content: "";
}

.embed .embed-responsive-item,
.embed iframe,
.embed embed,
.embed object,
.embed video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56%;
  border-radius: 5px;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-3by4::before {
  padding-top: 133.333333%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

@media (max-width: 768px) {
  .winners-page .hero {
    margin-top: 50px;
  }

  .winners-page .testimonials {
    padding-top: 10px;
  }
}

.image-holder img {
  max-width: 100%;
  height: auto;
}

.image-holder {
  position: relative;
  background: #000;
  padding: 30px 0;
  border-radius: 5px;
}

.patrons img,
.partners img {
  width: 100%;
}

.dow-head {
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.app-dow {
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.backW {
  background-image: url(../assets/img/winds.png);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-size: contain; */
}

.term-container {
  max-width: 94%;
  margin: 0 auto 40px;
  padding-top: 85px;
}

.term-container h4 .fa-rupee-sign {
  font-size: 1.2rem;
}

.term-container p .fa-rupee-sign {
  font-size: 0.85rem;
}

.term-container h2 {
  color: #121640;
  font-size: 60px;
  margin-bottom: 15px;
  line-height: 1.1;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 600;
}

.term-container h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #121640;
  line-height: 1.8;
}

.term-container h4 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #121640;
  line-height: 1.8;
}

.term-container p,
.term-container ol {
  color: #121640;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
}

.term-container p,
.term-container ol {
  color: #121640;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
}

.termCont {
  text-align: center;
}

.termCont .first {
  margin-top: 10%;
}

.termCont .last {
  margin-bottom: 10%;
}

.termCont a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.about-sec-1 {
  background: #edc330;
}

.about-sec-1.patrons {
  background: radial-gradient(circle, #eeeeee 0%, rgba(216, 216, 216, 0) 100%);
  background-image: url(../assets/img/site-patrons/ic_patron_bg.png);
  background-size: cover;
}

.about-sec-3patrons {
  background-color: #ebfffd;
}

.about-sec-1.patrons.bg {
  background: radial-gradient(circle, #eeeeee 0%, rgba(216, 216, 216, 0) 100%);
  background-image: url(../assets/img/site-captain/ic_captain_bg.png);
  background-size: cover;
}

.about-sec-1.partner.bg {
  background: radial-gradient(circle, #ededed 0%, rgba(242, 243, 250, 0) 100%);
  background-color: #f2f3fa;
}

.about-sec-1.site-partner {
  background-image: url(../assets/img/site-partnerImage/ic_bg_partner.png);
  background-size: cover;
}

.about-sec-2.bg {
  background-image: url(../assets/img/site-patrons/ic_patron_benifits_bg.png);
}

.about-sec-2 {
  background: #fff;
}

.about-sec-3 {
  background: #f2f5f0 url("../assets/img/about/ic_who_we_are.png") no-repeat
    right;
}

.about-sec-3captain {
  background-color: #ebfffd;
}

.about-sec-3redirect {
  background-image: url(../assets/img/site-partnerImage/ic_bg_partner_earnings.png);
  background-size: cover;
}

.header-sec {
  display: flex;
  justify-content: center;
  position: relative;
}

.navbar.navbar-expand-lg.all {
  position: absolute;
  top: 20px;
  border-radius: 39px;
  padding: 5px;
  background: white;
  border: 1px solid #eaeaea;
}

.header-sec .nav-link {
  color: #3f4850 !important;
}

.header-sec .nav-item.site-active {
  background-color: #f1f0ff;
  border-radius: 39px;
}

.site-active .nav-link h1 {
  color: #7d70ff !important;
  font-weight: 600;
}

.header-sec li.nav-item {
  padding: 12px;
}

.captain-ref {
  font-weight: normal;
}

.about-sec-5 {
  background: #faf1e8 url("../assets/img/about/ic_our_business.png") no-repeat
    right;
  background-size: contain;
}

.site-page {
  padding: 70px 0;
}

.site-page.site-top {
  padding-top: 100px;
}

.site-page .title {
  color: #3f4850;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 50px;
  margin-bottom: 20px;
}

.title-patrons {
  color: #3f4850;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 65px;
  padding-top: 50px;
}

.title-captain {
  color: #3f4850;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 50px;
  margin-bottom: 20px;
}

.site-page .sub-title {
  color: #464646;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 35px;
}
.ab-image {
  width: 110% !important;
}
.w-md-50 {
  width: 75%;
}
.digi-form-bg {
  position: absolute;
  z-index: 0;
  opacity: 20%;
  width: -webkit-fill-available !important;
  padding: 0;
  scale: 130%;
}
.react-pdf__Page__canvas {
  margin: 50px auto;
  box-shadow: 0px 0px 26px 3px rgba(237, 237, 237, 1);
  width: 60% !important;
  height: auto !important;
}
.pdf-buttons {
  background: white;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  right: 0;
  z-index: 999;
  bottom: 12%;
  width: 100px;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 26px 3px rgb(237 237 237);
}
.pdf-buttons img {
  cursor: pointer;
}
.pdf-btn-disable {
  opacity: 50%;
  pointer-events: none;
  cursor: default;
}
@media screen and (max-width: 1550px) {
  .site-page .sub-title.patron {
    font-size: 20px;
    line-height: 25px;
  }
  .ab-image {
    width: 110% !important;
  }
}

.above-title {
  color: #3f4850;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 55px;
}

.download-patron {
  color: #3f4850;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}

.paystore {
  display: flex;
  gap: 20px;
}

.paystore img {
  width: 175px;
}

.site-page .card,
.site-page .card-header {
  border: none;
  background: transparent;
  padding: 0;
}

.site-page .btn-link{
  color: #3f4850;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 35px;
  display: flex;
  align-items: center;
}

.site-page .btn-link:focus,
.site-page .btn-link:hover {
  text-decoration: none;
}

.site-page .card-body {
  color: #808080;
}

.site-modal .modal-body {
  color: #808080;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
}

.site-modal li {
  padding: 10px 0;
}

.site-modal .modal-title {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.mission-img {
  width: 30px;
  margin-right: 8px;
}

.site-page .desc {
  color: #808080;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

@media screen and (max-width: 1550px) {
  .about-sec-3 {
    background-position-x: 600px;
  }

  .about-sec-5 {
    background-size: 56%;
  }
}
@media screen and (max-width: 1400px) {
  .digi-form-bg {
    scale: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .header-sec .nav-link {
    font-size: 14px !important;
  }
  .ab-image {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .about-sec-3 {
    background-position-x: 500px;
  }

  .title-captain {
    font-size: 35px;
    line-height: 45px;
  }

  .captain-ref {
    font-size: 35px;
    line-height: 45px;
  }

  .above-title {
    font-size: 34px;
    line-height: 45px;
  }

  .site-page .title {
    font-size: 35px;
    line-height: 40px;
  }

  .site-page .sub-title {
    font-size: 25px;
    line-height: 30px;
  }

  .download-patron {
    font-size: 20px;
    line-height: 25px;
  }

  .site-page .btn-link span {
    font-size: 28px;
    line-height: 38px;
  }

  .paystore img {
    width: 180px;
  }
}

@media screen and (max-width: 1024px) {
  .site-top {
    padding: 40px 0 !important;
  }

  .header-sec {
    display: none;
  }

  .site-page .title {
    font-size: 35px;
    line-height: 45px;
  }

  .title-captain {
    font-size: 30px;
    line-height: 39px;
  }

  .above-title {
    font-size: 28px;
    line-height: 39px;
  }

  .captain-ref {
    font-size: 30px;
    line-height: 39px;
  }

  .site-page .sub-title {
    font-size: 25px;
    line-height: 30px;
  }

  // .monthlydraw{
  //   font-size: 20px;
  //   line-height: 25px;
  //  }
  .site-page .btn-link span {
    font-size: 25px;
    line-height: 34px;
  }

  .paystore img {
    width: 150px;
  }
}

@media screen and (max-width: 900px) {
  .about-sec-3 {
    background-position-x: 300px;
  }

  .site-page .title {
    font-size: 25px;
    line-height: 30px;
  }

  .captain-ref {
    font-size: 25px;
    line-height: 30px;
  }

  .above-title {
    font-size: 22px;
    line-height: 30px;
  }

  .title-captain {
    font-size: 25px;
    line-height: 30px;
  }

  .site-page .sub-title {
    font-size: 22px;
    line-height: 27px;
  }

  .paystore img {
    width: 130px;
  }

  // .monthlydraw{
  //   font-size: 16px;
  //   line-height: 20px;
  //  }
  .site-page .btn-link span {
    font-size: 19px;
    line-height: 30px;
  }
}

@media screen and (max-width: 767px) {
  .reverse-col {
    flex-direction: column-reverse;
  }
  .react-pdf__Page__canvas {
    margin: 30px auto;
    width: 100% !important;
  }

  .site-page .btn-link span {
    font-size: 19px;
    line-height: 30px;
  }

  .paystore img {
    width: 120px;
  }
  .w-md-50 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .site-page {
    padding: 40px 0;
  }

  .above-title {
    font-size: 20px;
    line-height: 30px;
  }

  .captain-ref {
    font-size: 22px;
    line-height: 26px;
  }

  .site-page .title {
    font-size: 22px;
    line-height: 26px;
  }

  .title-captain {
    font-size: 22px;
    line-height: 26px;
  }

  .site-page .sub-title {
    font-size: 20px;
    line-height: 25px;
  }

  .site-modal .modal-body {
    font-size: 16px;
    line-height: 25px;
  }

  .site-page .desc {
    font-size: 16px;
    line-height: 24px;
  }

  .about-sec-5 {
    background-size: 0;
  }

  .paystore img {
    width: 100px;
  }

  // .monthlydraw{
  //   font-size: 16px;
  //   line-height: 20px;
  //  }
  .site-page .btn-link span {
    font-size: 18px;
    line-height: 25px;
  }
}

.down-arrow {
  transform: rotate(-90deg);
}

.up-arrow {
  transform: rotate(90deg);
}

.form-lp {
  height: 50px;
  border-radius: 0.5rem;
}
.prize-details-section table,
td,
th {
  border: 2px solid;
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: bold;
}
.prize-details-section .title {
  color: #f27f1a;
  font-weight: 800;
}
.prize-details-section .title-blue {
  color: #106ab6;
  font-weight: 800;
}
@media screen and (max-width: 370px) {
  .prize-details-section .sub-title {
    font-size: 14px !important;
    line-height: 16px;
  }
  .prize-details-section table,
  td,
  th {
    font-size: 12px;
  }
}
.prize-title-head-box {
  background-color: #f27f1a;
  color: #fff;
  font-weight: bold;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.prize-orange-box-text{
  background-color: #f27f1a;
  color: #fff;
  border: 2px solid #f27f1a;
}
.prize-title-head-box-blue {
  background-color: #106ab6;
  color: #fff;
  font-weight: bold;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
.prize-blue-box-text{
  background-color: #106ab6;
  color: #fff;
  border: 2px solid #106ab6;
}