.gridcontainer {
  background-size: cover;
  // height: 425px;
}

.gridinner_flight {
  background-color: rgb(52 53 64 / 81%);
  // width: 85%;
  border-radius: 10px;
  margin: auto;
  // position: relative;
  top: -10rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.travel-page {
  padding-bottom: 200px !important;
}
.travel-page .nav-tabs .nav-link.active {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: #ffd600;
}
.heading_text {
  color: #333;
  // padding: 81px 125px;
  font-size: 45px;
  font-weight: bold;
  line-height: 55px;
  // height: 22px;
}
.travel-page .round label:after {
  border: 2px solid #4355ff;
  border-top: none;
  border-right: none;
  left: 4px;
  top: 6px;
}
.travel-page .round input[type="checkbox"]:checked + label {
  background-color: #fff;
  border-color: #fff;
}
.sub-text {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  color: #433b34;
}

.inner-part.flight {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0px;
  // max-height: 100px;
}

.g-col-6.flight {
  display: flex;
  height: 65px;
}

.g-col-3.start {
  border: 1px solid #cecef8;
  background-color: #fff;
  height: 100%;
  width: 265px;
  display: flex;
  cursor: pointer;
}

.g-col-4.middle {
  display: flex;
}
.rdrInRange {
  background: #eff5ff !important;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: gray !important;
}
.g-col-2.new {
  border: 1px solid #cecef8;
  background-color: #fff;
  width: 210px;
  display: flex;
  cursor: pointer;
  height: 65px;
}

.g-col-2 {
  width: 180px;
  border: 1px solid #cecef8;
  background-color: #fff;
}

.g-col-2.end {
  box-sizing: border-box;
  background-color: #fff;
  height: 65px;
  width: 180px;
  border: 1px solid #cecef8;
  cursor: pointer;
}

.title_text {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
}

.list_results {
  display: flex;
}

// .flex_box{
//     height: 52px;
// }

.searchOptions_flex {
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translate(-50%);
}

.travel-page .btn-primary.new {
  font-size: 21px;
  font-weight: bold;
  padding: 17px 40px;
  line-height: 25px;
  border-radius: 39px;
  color: #ffffff;
  background: #4355ff;
  border: none;
}
p.d-flex.mb-1.text-white {
  font-size: smaller;
}
.travel-page .tab-content {
  position: relative;
}

.travel-page .nav-tabs {
  border-bottom: none;
  background-color: rgb(52 53 64 / 81%);
  border-radius: 5px 5px 0 0;
  width: fit-content;
  margin: auto;
}
.search-travel {
  border: 1px solid #977de4;
  background-color: #977de4;
  height: 65px;
  width: 86px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
  font-weight: 600;
  color: #fff;
}
.travel-page .nav-tabs .nav-link {
  width: auto;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  padding: 8px 40px 0;
  text-align: center;
}

.travel-page .nav-tabs .nav-link.active {
  color: #343540ab;
  border: none;
  font-weight: 900;
  background-color: transparent;
  font-weight: 600;
  color: #ffffff;
}

.travel-page .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}

.travel-page .nav-tabs .nav-link.active:hover {
  border: none;
  color: #ffffff;
}

.travel-page .form-check-inline .form-check-input {
  width: 28px;
  height: 28px;
}

.checkbox_text1 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.fly {
  padding: 20px 60px;
  color: #585858;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
}

.booking-date {
  padding: 12px 15px;
  color: #585858;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: inherit;
  width: inherit;
}

.travel {
  padding: 0px 65px;
  color: #585858;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
}

.icon.fa {
  cursor: pointer;
  color: #4355ff;
  font-size: 18px;
  padding: 0px 6px;
  display: inline;
}

.travel-page input[type="radio"] {
  height: 15px;
  width: 15px;
}

#flexRadioDefault:checked {
  accent-color: #7100ff;
  border: none;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
  position: absolute;
  z-index: 99;
  border-radius: 6px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.33);
}

.cal {
  // height: 25px;
  width: 40px;
  display: flex;
  align-self: center;
  margin-left: 20px;
}

.flyingImage {
  height: 24.43px;
  width: 40.63px;
  position: relative;
  top: 47px;
  left: 20px;
}

.traveller-icon {
  height: 23.35px;
  width: 28.49px;
  position: relative;
  top: 47px;
  left: 20px;
}

// .round.new label {
//   height: 26.55px;
//   top: 2px;
//   width: 26.55px;
// }
.round.new label {
  height: 22px;
  top: 3px;
  width: 22px;
}
.round.new label:after {
  left: 4px;
  top: 6px;
  width: 12px;
}

.flying_from {
  position: absolute;
  top: 0px;
  padding: 22px 82px;
  width: 320px;
  height: 128px;
  border-right: solid 1px #c8c7c7;
}

.flying_to {
  position: absolute;
  top: 0px;
  padding: 22px 82px;
  width: 320px;
  height: 128px;
  left: 319px;
  border-right: solid 1px #c8c7c7;
}

.stored-value {
  padding-top: 10px;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title_name:hover {
  background: #f4f4f4;
}
.stored-value-heading {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.traveller {
  position: absolute;
  width: 250px;
  padding: 22px 63px;
}

.numoftraveler {
  color: #585858;
  font-size: 14px;
  letter-spacing: 0;
  padding-left: 20px;
  margin: 0;
}

.form-check {
  margin: 0px 12px 0px 0;
}

.booking_btns {
  padding: 11px 0px;
  display: flex;
  gap: 16px;
}

.travel-page .btn1 {
  border: 1px solid #cecef8;
  border-radius: 5px;
  background-color: #f6faff;
  color: #515151;
  line-height: 20px;
  padding: 2px 19px 2px 20px;
}
.booking_btns .btn1:active {
  background: blue;
  color: white;
}
#popover-date {
  max-width: none;
  border: 1px solid rgb(255 255 255 / 20%);
}
.apply {
  border: none;
  background: #4455ff;
  color: white;
  font-size: large;
  font-weight: 600;
  border-radius: 0 0 5px 5px;
}
.travel-page .btn1-active {
  background: #4355ff;
  color: #fff;
}

.round {
  position: relative;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}
.travel-page .round label {
  width: 22px;
  height: 22px;
  background: transparent;
}
.round input[type="radio"] {
  visibility: hidden;
}

.round input[type="radio"]:checked + label {
  background-color: #fff;
  border-color: #fff;
}

.round input[type="radio"]:checked + label:after {
  opacity: 1;
}
.rdrCalendarWrapper {
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.33);
}
h3.choose {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}
.clear-date {
  top: 0%;
  right: 0%;
  width: 25px;
}
.interchange {
  top: inherit;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 25px;
  // z-index: 9;
}
.ellip {
  width: 85%;
}
.modify-search-box {
  border: 1px solid #cecef8;
  background: #f6faff;
  border-radius: 15px;
  padding: 10px 30px 10px 50px;
  margin: 0;
  margin-bottom: 15px;
}
.modify-search-box hr {
  margin: 8px 0 10px 0;
}
.modify-search-box .from-to {
  color: #585858;
  font-weight: 500;
  font-size: smaller;
}
.modify-search-box .name {
  color: #4355ff;
  font-size: larger;
  font-weight: 600;
  margin: 0;
}
img.go-back {
  width: 40px;
  position: absolute;
  z-index: 9;
  transform: translate(-50%, 85%);
}
.flight-img {
  width: 20%;
}
.modify-img {
  width: 40%;
}
.air_name {
  color: #585858;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
}
.title_name {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 20px;
  font-weight: 500;
}

.min-book {
  min-height: 65px;
}
.main {
  height: 400px;
  width: 320px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.33);
  overflow: scroll;
}

.fa.fa-search {
  height: 15px;
  width: 15px;
  color: #a5a5a5;
  padding: 28px 20px;
}

.input_box {
  border: none;
  font-size: 16px;
  font-weight: 600;
  width: 220px;
}

.location {
  border: 1px solid #eeeeee;
}
.main_model {
  padding: 15px;
  width: 330px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.33);
}

.model_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model_text {
  height: 22px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0;
}

.pop-over {
  height: 17px;
  width: 107px;
  color: #585858;
  font-size: 14px;
  letter-spacing: 0;
}

.text_box {
  padding: 0px;
  display: flex;
  align-items: flex-end;
  gap: 18px;
}
input.input_box {
  width: 80%;
}
input.input_box:focus-visible {
  outline: none;
}
.add-option {
  box-shadow: rgb(202 205 208 / 30%) 0px 0px 20px;
  border-radius: 10px;
  padding: 0 10px;
  height: 30px;
}

.inner_increment {
  height: 12px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.increment_btn {
  font-size: 20px;
  border: none;
  background-color: transparent;
}

.decrement_btn {
  font-size: 20px;
  border: none;
  background-color: transparent;
}

.middle_text {
  margin: 0 3px;
  font-size: large;
  color: #1f1f1f;
  font-weight: bold;
  text-align: center;
}

.model_box.addtional {
  padding: 20px 0px;
}

.total_travel {
  border: 1px solid #cecef8;
  border-radius: 5px;
  background-color: #f6faff;
  margin: 0;
}

.sub_total {
  padding: 10px;
  display: flex;
  align-items: center;
}

.travel-page .btn {
  display: flex;
  justify-content: center;
}

.done_btn {
  height: 40.9px;
  width: 143.41px;
  border-radius: 29px;
  border: none;
  background-color: #203bcf;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}
.text_box img {
  width: 25px;
}

.shine-black {
  // background: #13131373;

  background-image: linear-gradient(
    to right,
    #11111100 0%,
    #0b0b0b00 20%,
    #1313137d 40%,
    #23232300 100%
  ) !important;
}

@media screen and (min-width: 1601px) {
  .g-col-3.start {
    width: 300px;
  }
  .g-col-2.new {
    width: 270px;
  }
}
@media (max-width: 1300px) {
  .g-col-3.start {
    width: 210px;
  }
  .g-col-2.new {
    width: 190px;
  }
  .g-col-2.end {
    width: 180px;
  }
  .booking-date {
    font-size: 11px;
  }
  .stored-value-heading {
    font-size: large;
  }
  .min-book {
    min-height: 65px;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 1100px) {
  .search-travel {
    width: 60px;
  }
  .g-col-3.start {
    width: 200px;
  }
  .g-col-2.new {
    width: 170px;
  }
  .g-col-2.end {
    width: 160px;
  }
  .stored-value-heading {
    font-size: large;
  }
  .cal {
    width: 30px;
    margin-left: 12px;
  }
  .booking-date {
    padding: 10px 10px;
  }
}

@media (max-width: 1024px) {
  .g-col-3.start {
    width: 186px;
  }
  .g-col-2.new {
    width: 167px;
  }
  .g-col-2 {
    width: 175px;
  }
  .checkbox_text1 {
    font-size: 15px;
  }
  .cal {
    width: 25px;
    margin-left: 8px;
  }
  .g-col-2.end {
    width: 163px;
  }
  .fly {
    padding: 20px 10px;
  }
  .flyingImage {
    height: 24.43px;
    width: 40.63px;
    margin: 40px 10px;
    position: unset;
  }
  .booking-date {
    padding: 12px 5px;
  }
}
@media (max-width: 950px) {
  .stored-value-heading {
    font-size: small;
    margin-top: 0 !important ;
  }
  .inner-part.flight {
    max-height: none;
  }
  .g-col-3.start {
    width: 170px;
  }
  .booking-date {
    padding: 10px 5px;
    font-size: 10px;
  }
  .stored-value {
    font-size: 10px;
    line-height: 15px;
  }
  .g-col-2.new {
    width: 145px;
  }
  .cal {
    width: 20px;
    margin-left: 8px;
  }
  p.m-0.pt-2.font-weight-bold {
    padding-top: 0 !important;
  }
  .g-col-2.end {
    width: 130px;
  }
  .checkbox_text1 {
    font-size: 12px;
  }
  .icon.fa {
    font-size: 12px;
  }
}
@media (max-width: 850px) {
  .g-col-2.new {
    width: 135px;
  }
}
@media (max-width: 800px) {
  .g-col-2.new {
    height: 85px;
    max-height: 85px;
    min-height: 85px;
  }
  .min-book,
  .g-col-2.end {
    min-height: 85px;
  }
  .search-travel {
    width: 100%;
    margin-top: 35px;
    border-radius: 10px;
  }
  .min-book {
    min-height: 108px;
  }
  .modify-img {
    width: 60%;
  }
  img.go-back {
    width: 35px;
    transform: translate(-30%, 85%);
  }
  .interchange {
    rotate: 90deg;
    width: 50px;
    margin: auto 0;
    left: inherit;
    right: 5%;
    top: 10%;
  }
  .g-col-3.start {
    width: 100%;
  }
  .g-col-6.flight {
    width: 100%;
  }
  .g-col-3.start {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 12px !important;
  }
  .gridfirst {
    width: 100%;
  }
  .gridcontainer {
    background-size: cover;
    height: 400px;
    background-repeat: no-repeat;
  }

  .booking-date {
    padding: 20px 25px;
  }
  .cal {
    margin: 0 20px 0 0px;
    width: 40px;
    height: 40px;
  }
  .g-col-2.new {
    width: 100%;
    padding: 20px;
    border-radius: 12px;
  }
  .gridmiddle2 {
    width: 100%;
  }
  .gridmiddle3 {
    width: 100%;
    margin-top: 25px;
  }
  .g-col-2.end {
    width: 100%;
    padding: 20px;
    border-radius: 12px;
  }
  .g-col-6.flight {
    display: initial;
  }
  .gridinner_flight {
    width: 100%;
  }
  .list_results {
    display: flex;
    flex-flow: wrap;
  }
  .stored-value-heading {
    font-size: x-large;
    margin-top: 0 !important;
  }
  .booking-date {
    font-size: 15px;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .travel-page {
    padding-bottom: 100px !important;
  }
  .g-col-2.new {
    height: 85px;
    max-height: 85px;
  }
  .min-book,
  .g-col-2.end {
    min-height: 85px;
  }
  .heading_text {
    font-size: 40px;
    color: #171b4f;
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
  }
  .travel-page .nav {
    display: flex;
    flex-wrap: unset;
  }
  .fly {
    padding: 10px 5px;
  }
  .flyingImage {
    height: 24.43px;
    width: 40.63px;
    margin: 40px 0px;
    position: unset;
  }
  .travel {
    padding: 0px 15px;
  }

  .searchOptions_flex {
    bottom: 0;
    width: 100%;
  }
  .btn-primary.new {
    border-radius: 0px;
  }
  .travel-page .title_text {
    height: auto;
    padding: 0;
    margin-bottom: 15px;
  }
  .checkbox_text1 {
    font-size: 15px;
  }
  .gridinner_flight .round label {
    height: 20px;
    width: 20px;
  }
  .gridinner_flight .round label:after {
    height: 5px;
    left: 5px;
    top: 5px;
    width: 9px;
  }
}

@media (max-width: 600px) {
  .travel-class {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .form-check {
    margin: 0;
  }
  .interchange {
    width: 40px;
    top: 14%;
  }
  .search-travel {
    height: 50px;
    margin-top: 20px;
  }
  .min-book {
    min-height: 50px;
  }
  .min-book,
  .g-col-2.end {
    min-height: 50px;
    max-height: 50px;
    display: flex;
  }
  .modify-search-box {
    padding: 5px 5px 5px 10px;
  }
  img.go-back {
    width: 20px;
    transform: translate(-50%, 35px);
  }
  .modify-search-box .name {
    font-size: small;
  }
  .modify-search-box .from-to {
    font-size: xx-small;
  }
  .p-last {
    font-size: 12px;
  }
  .travel-page .btn-primary.new {
    font-size: 16px;
    padding: 10px 40px;
    margin-bottom: 30px;
  }
  .gridmiddle3 {
    margin-top: 15px;
  }

  .model_text {
    font-size: 14px !important;
    margin-bottom: 0;
  }
  .travel-page .nav-tabs .nav-link {
    width: auto;
    padding: 10px 30px;
    font-size: 12px;
    border-radius: 35px;
  }
  .heading_text {
    margin-bottom: 8px;
  }
  .gridinner_flight {
    top: 0;
  }
  .gridcontainer {
    background-size: cover;
    height: auto;
    background-repeat: no-repeat;
  }
  .gridinner_flight {
    height: auto;
    width: 100%;
    display: grid;
  }
  .inner-part.flight {
    align-content: space-between;
  }
  .list_results {
    flex-wrap: wrap;
  }
  .heading_text {
    font-size: 24px;
    line-height: 26px;
    line-height: 26px;
  }
  .sub-text {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
  }
  .travel-page .nav {
    display: flex;
    flex-wrap: unset;
  }
  .travel-page .nav-tabs .nav-link {
    line-height: 14px;
  }
  .form-check {
    margin: 0px 0px 6px 0px;
  }
  .flyingImage {
    height: 24.43px;
    width: 40.63px;
    margin: 20px 10px;
    position: unset;
  }
  .cal {
    margin: 0 10px 0 0px;
    width: 20px;
    height: 20px;
  }
  .fly {
    padding: 5px 30px;
  }
  .booking-date {
    font-size: 10px;
    padding: 0;
  }
  .traveller-icon {
    top: 20px;
  }
  .searchOptions_flex {
    bottom: 0;
    width: max-content;
  }
  .btn-primary.new {
    border-radius: 0px;
  }
  .g-col-3.start,
  .g-col-2.new {
    min-height: 50px;
    height: 50px;
    padding: 8px 15px;
    border-radius: 5px;
  }
  .g-col-6.flight {
    flex-wrap: wrap;
    gap: 10px;
  }
  .stored-value-heading {
    font-size: medium;
  }
  .g-col-3.start {
    width: 100%;
    border-radius: 5px !important;
    margin-bottom: 10px;
  }
  .g-col-2.end {
    padding: 8px 15px;
    width: 100%;
    border-radius: 5px;
  }
  .travel {
    padding: 0px 65px;
  }

  .title_text {
    padding: 10px 17px 20px;
  }
  .modify-img {
    width: 80%;
    height: fit-content;
  }
  .flight-img {
    width: 40%;
  }
  .travel-page {
    padding-bottom: 50px !important;
  }
}

@media (max-width: 350px) {
  .modify-search-box {
    padding: 5px 5px 5px 10px;
  }
  img.go-back {
    width: 15px;
    transform: translate(-50%, 30px);
  }
  .modify-search-box .name {
    font-size: x-small;
  }
  .modify-search-box .from-to {
    font-size: xx-small;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modify-search-box hr {
    margin: 3px 0 5px 0;
  }
  .booking-date {
    font-size: 8px;
  }
}

@media (max-width: 300px) {
  .inner-part.flight {
    padding: 0 5px;
  }
}

//hotel
.rooms {
  width: 430px !important;
  @media screen and (max-width: 1100px) {
    width: 355px !important;
  }
  @media screen and (max-width: 910px) {
    width: 300px !important;
  }
  @media screen and (max-width: 850px) {
    width: 285px !important;
  }
  @media screen and (max-width: 800px) {
    width: 100% !important;
  }
  @media screen and (min-width: 1601px) {
    width: 500px !important;
  }
  .model_text {
    height: auto;
    color: #585858;
  }
}
.rooms img {
  width: 35px !important;
  @media screen and (max-width: 905px) {
    width: 25px !important;
  }
  @media screen and (max-width: 520px) {
    width: 20px !important;
  }
}
.destination {
  width: 350px !important;
  @media screen and (max-width: 1300px) {
    width: 250px !important;
  }
  @media screen and (max-width: 905px) {
    width: 190px !important;
  }
  @media screen and (max-width: 800px) {
    width: 100% !important;
  }
  @media screen and (min-width: 1601px) {
    width: 400px !important;
  }
}
.destination img {
  width: 30px !important;
  @media screen and (max-width: 1024px) {
    width: 23px !important;
  }
}
.travel-dates {
  width: 350px !important;
  @media screen and (max-width: 1300px) {
    width: 300px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 300px !important;
  }
  @media screen and (max-width: 992px) {
    width: 240px !important;
  }
  @media screen and (max-width: 850px) {
    width: 240px !important;
  }
  @media screen and (max-width: 800px) {
    width: 100% !important;
  }
  @media screen and (max-width: 520px) {
    margin-bottom: 15px;
  }
  @media screen and (min-width: 1601px) {
    width: 420px !important;
  }
  .model_text {
    height: auto !important;
    color: #585858;
    @media screen and (max-width: 992px) {
      font-size: 14px;
    }
    @media screen and (max-width: 850px) {
      font-size: 11px;
    }
    @media screen and (max-width: 520px) {
      font-size: 14px !important;
    }
  }
}

.g-col-4.middle.mob {
  @media screen and (max-width: 520px) {
    display: flex;
    flex-direction: column;
  }
}

.single-room {
  padding: 8px;
  padding-top: 12px;
  border: 1px solid rgb(180, 204, 253);
}
.main_model.hotel {
  @media screen and (max-width: 500px) {
    width: 90% !important;
  }
  width: 400px !important;
 
}
.all-room {
  max-height: 400px;
  overflow-y: auto;
}
