.nav-tabs {
    border-bottom: none;

    .nav-link {

        color: #A5A5A5;
        font-weight: 600;
        font-size: 20px;

        &:hover {
            border: none;
            color: #0078D7;
        }

        &.active {
            color: #0078D7;
            border: none;
            border-bottom: solid 3px #0078D7;
            font-weight: 900;
        }
    }
}

.nav--plans {

    .nav-tabs {

        display: flex;
        flex-wrap: nowrap;

        .nav-link {
            font-size: 14px;
        }
    }

}

.wrp-tabs {
    justify-content: space-between;
    .nav-item {
        width: 50%;
        text-align: center;
    }

    @media (min-width: 992px) {
        justify-content: flex-start;
        .nav-item {
            width: auto;
        }
    }
}

.tab-content {
    h3 {
        font-size: 1.5rem;
    }
    @media (min-width: 992px) {
        h3 {
            font-size: 1.75rem;
        }
    }  
}