.box-voucher-detail-logo {
  height: 345px !important;

  @media (min-width: 992px) {
    height: 252px !important;
  }
}

.voucher-detail-logo-wrapper {
  width: 100%;
  height: 150px;
  // background-color: #fff;
  background: radial-gradient(circle, #ffffff 0%, #e9fff9 100%);
  border-radius: 6px 0 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;

  @media (min-width: 992px) {
    height: 200px;
  }
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary {
  background-color: rgb(32, 59, 207);
  border-color: rgb(32, 59, 207);
}
.btn-quantity {
  background-color: #7d70ff !important;
}
.tab-pane ol {
  padding-left: 1rem;
}

.voucher-deliveryType {
  font-size: 0.8rem;
  @media (min-width: 992px) {
    font-size: 1rem;
  }
}

.voucher-title {
  font-size: 25px;
  width: 87%;
  @media (min-width: 992px) {
    font-size: 30px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // width: 90%;
  }
}

.sub-voucher-card-wrapper {
  display: flex;
  justify-content: center;

  .sub-voucher-card {
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 100px;
    position: relative;

    @media (min-width: 990px) {
      width: 70%;
      margin-left: 2rem;
    }

    .sub-voucher-logo-wrapper {
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translate3d(0, -50%, 0);
      width: 100px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);

      @media (min-width: 990px) {
        left: -3rem;
      }

      img {
        width: 90%;
      }
    }
    .sub-voucher-details {
      position: absolute;
      left: 9rem;
      top: 50%;
      transform: translate3d(0px, -50%, 0px);

      h5 {
        font-size: 1rem;

        @media (min-width: 990px) {
          font-size: 1.25remm;
        }
      }

      @media (min-width: 990px) {
        left: 5rem;
      }
    }
  }
}

.voucher-modal {
  .lds-ellipsis {
    display: block;
    margin: auto;
  }
}

.view-voucher-btn {
  padding: 0;
  vertical-align: unset;
}

.discount-span {
  color: #fff;
}

.voucher-offer {
  position: absolute;
  right: 5%;
  top: 5%;
  background-color: #cf73f7;
  color: white;
  padding: 5px 7px;
  border-radius: 45%;
  margin: 0;
  font-size: 12px;
}
.voucher-heading {
  width: 100%;
}
.voucher-heading-box {
  text-align: center;
  // background: linear-gradient(180deg,#646dff,transparent);
  // background: linear-gradient(180deg, #646dffde, white);
  padding: 5px 0;
  color: white;
  border-radius: 5px 5px 0 0;
  // position: sticky;
  // top: 0;
  // z-index: 999;
}
.voucher-bg {
  // background: url('../assets/ic_bg_with_offfer.svg');
  background-size: cover;
}
.has-discount {
  min-height: 160px;
}
.no-discount {
  min-height: 200px;
}
.dis-head {
  font-size: 15px;
}
.winds-watermark {
  position: absolute;
  right: -50px;
  top: -60px;
  height: 150px !important;
}
.winds-logo {
  position: absolute;
  left: 15px;
  bottom: 15px;
  height: 24px !important;
  @media (max-width: 992px) {
    height: 15px !important;
  }
}
.item-logo {
  width: 65% !important;
  height: auto !important;
}
.home-offer {
  width: 20px;
}
.home-arrow {
  width: 35px;
}
.voucher-brand {
  font-size: x-large;
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 420px) {
    font-size: large !important;
  }
}
.winds-voucher img {
  width: 75%;
}
.winds-voucher .earn {
  position: absolute;
  // right: 21.5%;
  right: 19.2%;
  bottom: 40%;
  font-size: medium;
  color: #ffc500;
  @media screen and (min-width: 1600px) {
    right: 20.2%;
  }
}
.voucher-benefits-popup .title {
  background: radial-gradient(circle, #a8f0ee 0%, #f2fffd 100%);
  padding: 20px 10px;
  text-align: center;
  font-size: x-large;
  font-weight: 600;
}
.list {
  padding: 10px 0;
}
.list img {
  width: 35px;
  height: fit-content;
  margin-right: 20px;
}
.voucher-card .card-body {
  padding: 0;
  // background: linear-gradient(90deg, #f3f3ff 0%, #baf5f5 100%);
  background: linear-gradient(180deg, #009ba3 0%, #5bcdbb 100%);
}
.voucher-span-bg {
  background: #f5f5ff;
  display: block;
  width: 100%;
  padding: 8px;
  text-align: center;
  border-radius: 10px;
}
.voucher-search {
  border: 1px solid #e6e6e6;
  padding: 5px 5px 5px 25px;
  border-radius: 18px;
  text-align: center;
  position: relative;
}
.voucher-search-img {
  position: absolute;
  z-index: 9;
  display: flex;
  top: 25%;
  left: 6%;
}
.voucher-cashback {
  background: linear-gradient(270deg, #6302fd 0%, #8805ff 100%);
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  h6{
    @media screen and (max-width:767px) {
    font-size: small;
    }
  }
 
}
.gift-voucher input,
.gift-voucher textarea {
  background: rgba(255, 255, 255, 0.38);
  border: 1px solid #fff;
}
.wrp-tag img {
  width: 150px;
}
.wrp-tag strong {
  top: 12%;
  left: 10%;
}
.redeem-tag img {
  width: 140px;
}
.redeem-tag strong {
  top: 12%;
  left: 0;
  right: 0;
  text-align: center;
  color: #4a4a4a;
}
@media (max-width: 992px) {
  .wrp-tag img {
    width: 100px;
  }
  .wrp-tag strong,
  .redeem-tag strong {
    font-size: x-small;
    top: 25%;
  }
  .redeem-tag img {
    width: 90px;
  }
  .validity-redeem p {
    font-size: small;
  }
}
@media (max-width: 350px) {
  .wrp-tag img {
    width: 80px;
  }
  .wrp-tag strong,
  .redeem-tag strong {
    font-size: xx-small;
    top: 25%;
  }
  .redeem-tag img {
    width: 70px;
  }
  .validity-redeem p {
    font-size: xx-small;
  }
}
@media (min-width: 992px) {
  .item-logo {
    height: 60px;
  }
}
@media screen and (max-width: 767px) {
  .voucher-search-img {
    top: 38%;
  }
  .winds-voucher .earn {
    right: 11.5%;
    bottom: 50px;
  }
  .voucher-span-bg {
    font-size: x-small;
  }
  .voucher-title {
    font-size: 18px;
  }
  .winds-voucher img {
    width: 100%;
  }
  .winds-voucher p {
    font-size: smaller;
  }
  .home-offer {
    width: 12px;
  }
  .home-arrow {
    width: 22px;
  }
  .home-voucher .item-logo {
    height: auto !important;
    width: 70px !important;
  }
  .item-logo {
    height: auto !important;
    width: 90px !important;
  }
  .winds-watermark {
    position: absolute;
    right: -25px;
    top: -30px;
    height: 70px !important;
  }
  .winds-logo {
    position: absolute;
    left: 10px;
    bottom: 10px;
    height: 20px !important;
  }
  .discount-span {
    font-size: 3vw;
    display: contents;
  }
  .voucher-offer {
    right: 5%;
    top: 7%;
    padding: 2px 4px;
    font-size: 9px;
  }
  .voucher-heading {
    font-size: 22px;
  }
  .nav-tabs .nav-link {
    font-size: 15px;
  }
  .has-discount {
    min-height: 72px;
  }
  .no-discount {
    min-height: 100px;
  }
  .card-bottom {
    padding: 12px 5px !important;
  }
}

.voucher-benefits-popup h5 {
  margin: 0;
  line-height: inherit;
}

.border-shape {
  position: absolute;
  right: 10px;
  height: 100%;
}
.gift-voucher {
  background: linear-gradient(45deg, #cef9f8 0.02%, #f6fdf3 100%);
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgb(52 52 52 / 8%);
}
.gift-img {
  width: 20px;
  margin-right: 5px;
}
.gift-arrow {
  width: 15px;
}
.gift-header {
  background: transparent;
  border: none;
  padding: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.gift-voucher .card-header {
  border: none;
  background: transparent;
  cursor: pointer;
}
.wrp-tabs .nav-item {
  color: #333333;
  font-weight: normal;
  width: 50%;
}
.wrp-tabs {
  background-color: #f8f8ff;
  border: 1px solid #ececff !important;
  border-radius: 12px;
}
.wrp-tabs .nav-item.active {
  background-color: #ece6ff;
  color: #333333;
  border-bottom: none;
  font-weight: 600;
  border: 1px solid #ececff !important;

  border-radius: 12px;
}
.winds-voucher .title-box {
  position: absolute;
  bottom: 43%;
  left: 20%;
}
.winds-voucher .title-box img {
  width: 70px;
}
.winds-voucher .title-box h3 {
  color: #fff;
  font-size: xx-large;
  width: 60%;
  text-align: left;
  margin: 0;
  margin-left: 5%;
  font-weight: 600;
}
.voucher-percent {
  font-size: small;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 992px) {
    font-size: x-small;
  }
  @media screen and (max-width: 767px) {
  }
}
.voucher-percent img {
  width: 20px;
}
@media screen and (max-width: 1100px) {
  .winds-voucher .earn {
    bottom: 40%;
    font-size: small;
  }
  .winds-voucher .title-box img {
    width: 40px;
  }
  .winds-voucher .title-box h3 {
    font-size: x-large;
  }
  .winds-voucher .title-box {
    position: absolute;
    bottom: 48%;
    left: 22%;
  }
}
@media screen and (max-width: 992px) {
  .winds-voucher .title-box img {
    width: 40px;
  }
  .winds-voucher .title-box h3 {
    font-size: large;
  }
}
@media screen and (min-width: 767px) {
  .desktop-width {
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .winds-voucher .title-box {
    bottom: 40%;
    left: 15%;
  }
  .winds-voucher .title-box h3 {
    font-size: x-large;
  }
}
@media screen and (max-width: 600px) {
  .winds-voucher .title-box h3 {
    font-size: medium;
  }
  .winds-voucher .title-box {
    bottom: 56%;
    left: 12%;
  }
  .voucher-benefits-popup .title {
    font-size: larger;
  }
  .voucher-benefits-popup h5 {
    font-size: smaller;
  }
  .list img {
    width: 25px;
    margin-right: 15px;
  }
  .item-logo {
    width: 70px !important;
  }
  .card--carousel-item--vouchers .card-body {
    height: 75px;
  }
  .winds-logo {
    left: 7px;
    bottom: 7px;
    height: 12px !important;
  }
  .card-bottom {
    padding: 6px 2px !important;
  }
  .voucher-percent img {
    width: 12px;
  }
  .voucher-percent {
    font-size: xx-small;
  }
  .voucher-search {
    padding: 5px 5px 5px 25px;
  }
}
@media screen and (max-width: 490px) {
  .winds-voucher .earn {
    bottom: 5rem;
    // top: auto;
    // top: initial;
    font-size: xx-small;
    right: 11.5%;
  }
}
@media screen and (max-width: 340px) {
  .voucher-percent {
    font-size: 6px;
  }
  .winds-voucher .earn {
    bottom: 4.5rem;
    right: 10%;
  }
  .item-logo {
    width: 55px !important;
  }
  // .voucher-heading-box .custom-control-label {
  //   font-size: x-small;
  // }
}
// .voucher-heading-box .custom-control-label {
//   // font-size: x-small;
//   // @media screen and (min-width: 340px) {
//   //   font-size: small;
//   // }
// }
// .voucher-heading-box
//   .custom-checkbox
//   .custom-control-input:checked
//   ~ .custom-control-label::after {
//   background-color: ;
// }
.all-denominations {
  background: #f5f5ff;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  min-width: 85px;
}
.selected-denomination {
  background: #7d70ff;
  color: #fff;
}

.denom-slider .slick-prev,
.denom-slider .slick-next {
  top: 40%;
}
.denom-slider .slick-prev {
  left: -20px !important;
  z-index: 9;
}
.denom-slider .slick-next {
  right: 0px !important;
  z-index: 9;
}
.denom-slider .slick-next:before,
.denom-slider .slick-prev:before {
  width: 25px;
  height: 25px;
}
.filter-voucher {
  font-size: medium;
  margin-bottom: 0;
}
.voucher-earn-card-text {
  position: relative;
  bottom: 38px;
  font-size: medium;
  color: #ffc500;
}
@media screen and (max-width: 991px) {
  .voucher-earn-card-text {
    position: relative;
    bottom: 28px;
    font-size: 13px;
    color: #ffc500;
  }
}
@media screen and (max-width: 576px) {
  .voucher-earn-card-text {
    position: relative;
    bottom: 38px;
    font-size: 20px;
    color: #ffc500;
  }
}
@media screen and (max-width: 500px) {
  .voucher-earn-card-text {
    position: relative;
    bottom: 28px;
    font-size: 13px;
    color: #ffc500;
  }
}
.voucher-banner-area {
  position: relative;
}
.voucher-banner-text-box {
  position: absolute;
  top: 0;
  left: 45%;
  .desc {
    display: none;
  }
  .banner-text-small {
    color: #fff;
  }
  // .header1{
  //   font-size: 40px;
  // }
  // .header2{
  //   font-size: 50px;
  // }
}
.voucher-banner-btn {
  background: rgb(255, 255, 255);
  // background: linear-gradient(90deg, rgba(86, 38, 181, 1) 0%, #7d70ff 100%);
  color: #8660f2;
  border: none;
  font-weight: bold;
  border-radius: 50px;
}
@media only screen and (max-width: 300px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 8px;
      padding-top: 5px;
    }
    .header2 {
      font-size: 9px;
    }
    .voucher-banner-btn {
      margin: 6px 0px;
    }
    .btn-arrow {
      width: 10px;
    }
    .btn-text {
      font-size: 7px;
      padding: 1px 2px;
    }
    .banner-text-small {
      font-size: 6px;
      width: 80%;
    }
  }
}
@media only screen and (min-width: 300px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 9px;
      padding-top: 16px;
    }
    .header2 {
      font-size: 10px;
    }
    .desc {
      font-size: 7px;
      padding-top: 2px;
    }
    .voucher-banner-btn {
      margin: 4px 0px;
    }
    .btn-arrow {
      width: 10px;
    }
    .btn-text {
      font-size: 5px;
      padding: 1px 2px;
    }
    .banner-text-small {
      font-size: 7px;
      width: 80%;
    }
  }
}

@media only screen and (min-width: 400px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 14px;
      padding-top: 6px;
    }
    .header2 {
      font-size: 13px;
    }
    .desc {
      font-size: 11px;
      padding-top: 2px;
    }
    .voucher-banner-btn {
      margin: 6px 0px;
    }
    .btn-arrow {
      width: 12px;
    }
    .btn-text {
      font-size: 7px;
      padding: 3px 2px;
    }
    .banner-text-small {
      font-size: 9px;
      width: 90%;
    }
  }
}
@media only screen and (min-width: 500px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 14px;
      padding-top: 20px;
    }
    .header2 {
      font-size: 13px;
    }
    .desc {
      font-size: 11px;
      padding-top: 2px;
    }
    .voucher-banner-btn {
      margin: 6px 0px;
    }
    .btn-arrow {
      width: 12px;
    }
    .btn-text {
      font-size: 7px;
      padding: 3px 2px;
    }
    .banner-text-small {
      font-size: 10px;
      width: 90%;
    }
  }
}

@media only screen and (min-width: 600px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 18px;
      padding-top: 8px;
    }
    .header2 {
      font-size: 20px;
    }
    .desc {
      display: block;
      font-size: 12px;
      padding-top: 2px;
    }
    .voucher-banner-btn {
      margin: 12px 0px;
    }
    .btn-arrow {
      width: 15px;
    }
    .btn-text {
      font-size: 8px;
      padding: 5px 3px;
    }
    .banner-text-small {
      font-size: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 22px;
      padding-top: 21px;
    }
    .header2 {
      font-size: 24px;
    }
    .desc {
      font-size: 14px;
      padding-top: 6px;
    }
    .voucher-banner-btn {
      margin: 14px 0px;
    }
    .btn-arrow {
      width: 15px;
    }
    .btn-text {
      font-size: 12px;
      padding: 6px 3px;
    }
    .banner-text-small {
      font-size: small;
    }
  }
}

@media only screen and (min-width: 992px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 30px;
      padding-top: 19px;
    }
    .header2 {
      font-size: 36px;
    }
    .desc {
      font-size: 23px;
      padding-top: 8px;
    }
    .voucher-banner-btn {
      margin: 16px 0px;
    }
    .btn-arrow {
      width: 17px;
    }
    .btn-text {
      font-size: 15px;
      padding: 4px 4px;
    }
    .banner-text-small {
      font-size: small;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 33px;
      padding-top: 30px;
    }
    .header2 {
      font-size: 40px;
    }
    .desc {
      font-size: 23px;
      padding-top: 16px;
    }
    .voucher-banner-btn {
      margin: 20px 0px;
    }
    .btn-arrow {
      width: 20px;
    }
    .btn-text {
      font-size: 17px;
      padding: 5px 4px;
    }
    .banner-text-small {
      font-size: small;
    }
  }
}
@media only screen and (min-width: 1300px) {
  .voucher-banner-text-box {
    .header1 {
      font-size: 36px;
      padding-top: 50px;
    }
    .header2 {
      font-size: 46px;
    }
    .desc {
      font-size: 26px;
      padding-top: 20px;
    }
    .btn-arrow {
      width: 20px;
      margin-right: 14px;
    }
    .btn-text {
      font-size: 17px;
      padding: 7px 8px 7px 14px;
    }
    .banner-text-small {
      font-size: 17px;
      width: 100%;
    }
    .voucher-banner-btn {
      margin: 10px 0px;
    }
    .banner-text-small {
      font-size: small;
    }
  }
}
.voucher-checkbox {
  background-color: rgb(121, 127, 252);
  border-radius: 5px;
  color: #fff;
  border: 1px solid #eeeeee;
  height: 35px;
}
.active-border {
  border: 1px solid #64608b97;
}
.active-filter-border {
  border: 2px solid red;
}
.voucher-label-text {
  font-size: 16px;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
    padding-top: 4px;
  }
  @media only screen and (max-width: 350px) {
    font-size: 9px;
    padding-top: 5px;
  }
}
.overlay-container {
  border: 2px solid #7d70ff2e;
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  padding: 3px;
  background-color: #ffffff;
  border-radius: 5px;
  // border: none;
  z-index: 2;

  @media only screen and (max-width: 992px) {
    width: 600px;
    // height: 340px;
  }
  @media only screen and (max-width: 768px) {
    width: 400px;
    // height: 450px;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    height: auto;
  }
}
// .overlay-list {
//   flex-wrap: wrap;
//   flex-direction: column;
//   display: flex;
//   width: 165px;
//   height: 300px;
//   padding: 28px;
//   @media only screen and (max-width: 992px) {
//     width: 600px;
//     height: 340px;
//   }
//   @media only screen and (max-width: 768px) {
//     width: 400px;
//     height: 450px;
//   }
//   @media only screen and (max-width: 500px) {
//     width: 100%;
//     height: auto;
//   }
// }
// .overlay-list li {
//   flex-wrap: wrap;
//   display: flex;
//   width: 200px;
// }
// .overlay-list li:hover {
//   font-weight: bold;
//   cursor: pointer;

// }
.voucher-popover {
  border: none;
}

.selected-category:hover {
  cursor: pointer;
  background-color: #adadad1d;
  color: #6302fd;
}
.voucher-popover .arrow::before {
  display: none;
}
.voucher-popover {
  transform: (152.5px, 609.5px, 0px) !important;
}
.shopping-card {
  @media only screen and (max-width: 260px) {
    min-height: 166px;
  }
  @media only screen and (min-width: 260px) {
    min-height: 149px;
  }
  @media only screen and (min-width: 300px) {
    min-height: 196px;
  }
  @media only screen and (min-width: 350px) {
    min-height: 220px;
  }
  @media only screen and (min-width: 400px) {
    min-height: 277px;
  }
  @media only screen and (min-width: 450px) {
    min-height: 288px;
  }
  @media only screen and (min-width: 500px) {
    min-height: 327px;
  }
  @media only screen and (min-width: 576px) {
    min-height: 254px;
  }
  @media only screen and (min-width: 600px) {
    min-height: 252px;
  }
  @media only screen and (min-width: 650px) {
    min-height: 273px;
  }
  @media only screen and (min-width: 700px) {
    min-height: 307px;
  }
  @media only screen and (min-width: 768px) {
    min-height: 247px;
  }
  @media only screen and (min-width: 800px) {
    min-height: 269px;
  }
  @media only screen and (min-width: 850px) {
    min-height: 277px;
  }
  @media only screen and (min-width: 900px) {
    min-height: 298px;
  }
  @media only screen and (min-width: 950px) {
    min-height: 302px;
  }
  @media only screen and (min-width: 998px) {
    min-height: 208px;
  }
  @media only screen and (min-width: 1200px) {
    min-height: 220px;
  }
  @media only screen and (min-width: 1300px) {
    min-height: 254px;
  }
  @media only screen and (min-width: 1400px) {
    min-height: 270px;
  }
  @media only screen and (min-width: 1600px) {
    min-height: 310px;
  }
}
@media only screen and (max-width: 600px) {
  .voucher-card-padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .voucher-popover {
    max-height: 400px;
    overflow-y: scroll;
}
}
.expired-text{
  color: #FF2A2A;
}
/* styles.css */
.gray-out {
  position: relative;

  pointer-events: none; /* Prevent interaction */
  opacity: 0.5;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Optional: semi-transparent background */
  z-index: 1000; /* Ensure it appears on top */
}

.spinner-border {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.disabled-button {
  opacity: 0.5; 
  pointer-events: none; 
  cursor: not-allowed;
}