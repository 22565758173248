.cardplans-container {
  background-color: #d7d1ea;
  .sub-title-benefits {
    background-color: #474747;
    color: #ffffff;
    font-weight: bold;
    border-radius: 2px;
  }
  .sub-title-elite {
    background: rgb(86, 38, 181);
    background: linear-gradient(90deg, rgba(86, 38, 181, 1) 0%, #7d70ff 100%);
    color: #ffffff;
    font-weight: bold;
    border-radius: 2px;
  }
  .sub-title-premium {
    background: rgb(186, 122, 0);
    background: linear-gradient(
      90deg,
      rgba(186, 122, 0, 1) 0%,
      rgba(246, 218, 111, 1) 100%
    );
    color: #ffffff;
    font-weight: bold;
    border-radius: 2px;
  }
  .single-card {
    min-height: 60px;
    display: flex;
    align-items: center;
    padding-left: 4.5rem;
    padding-right: 3.5rem;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .single-card {
      min-height: 94px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      justify-content: center;
      text-align: center;
    }
  }
  @media (max-width: 992px) {
    .single-card {
      min-height: 94px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      justify-content: center;
      font-size: 16px;
      border-right: 1px solid #d1d1d1;
    }
    .sub-title-benefits {
      font-size: 20px;
    }
    .sub-title-elite {
      font-size: 20px;
    }
    .sub-title-premium {
      font-size: 20px;
    }
  }
}
.elite-desc {
  color: #7d70ff;
  font-weight: bold;
  font-size: 16px;
  margin: 0.5rem;
}
.elite-desc-sub {
  font-size: 13px;
  color: #9f9f9f;
}
.desc-kyc {
  margin: 0.5rem;
  color: #000;
  font-weight: 300;
}
.premium-desc {
  color: #e3bb4c;
  font-weight: bold;
  font-size: 16px;
  margin: 0.5rem;
}
.premium-desc-sub {
  font-size: 13px;
  color: #9f9f9f;
}
.single-card img {
  width: 20px;
}
.title-choose-plan {
  font-size: 25px;
}

@media (max-width: 992px) {
  .elite-desc {
    font-size: 16px;
  }
  .elite-desc-sub {
    font-size: 12px;
  }
  .desc-kyc {
    font-size: 16px;
  }
  .premium-desc {
    font-size: 16px;
  }
  .premium-desc-sub {
    font-size: 12px;
  }
  .title-choose-plan {
    font-size: 18px;
  }
}
#elite-btn {
  background-color: #fff;
  font-weight: bold;
  img {
    margin-right: 1rem;
  }
}
#elite-btn-active {
  background: rgb(86, 38, 181);
  background: linear-gradient(90deg, rgba(86, 38, 181, 1) 0%, #7d70ff 100%);
  color: #ffffff;
  border: none;
  img {
    margin-right: 1rem;
  }
}
#premium-btn {
  background-color: #fff;
  font-weight: bold;
  img {
    margin-right: 1rem;
  }
}
#premium-btn-active {
  background: rgb(186, 122, 0);
  background: linear-gradient(
    90deg,
    rgba(186, 122, 0, 1) 0%,
    rgba(246, 218, 111, 1) 100%
  );
  color: #ffffff;
  font-weight: bold;
  border: none;
  img {
    margin-right: 1rem;
  }
}
#animated-btn-disable {
  opacity: 0.4;
}
#animated-btn-disable:hover {
  cursor: no-drop;
}
@media (max-width: 768px) {
  #elite-btn {
    display: flex;
    align-items: center;
  }
  #elite-btn-active {
    display: flex;
    align-items: center;
  }
  #premium-btn {
    display: flex;
    align-items: center;
  }
  #premium-btn-active {
    display: flex;
    align-items: center;
  }
}
.title-card-type {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #333333;
}
.subtitle-card-type {
  font-size: 13px;
  text-align: center;
  color: #9f9f9f;
  line-height: 1;
}
.btn-continue-card-type {
  height: 40px;
}
.btn-close-card-type {
  background-color: rgba(130, 159, 255, 0.123);
  border: none;
  color: rgba(0, 0, 0, 0.268);
  padding: 2px 10px;
  font-size: 19px;
  cursor: pointer;
  border-radius: 19px;
  width: 32px;
  height: 32px;
  margin: 0.8rem;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-card-type {
  font-size: 18px;
}
.btn-card-type-desc {
  font-size: 10px;
  color: #9f9f9f;
}
.btn-img-card-type {
  width: 25px;
  height: 25px;
  margin-right: 0.3rem;
}
@media (max-width: 768px) {
  .btn-card-type-desc {
    display: flex;
    margin-left: 30px;
  }
}
.cardkyc-title {
  font-size: 34px;
  color: #333333;
}
.cardkyc-subtitle {
  font-size: 26px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.cardkyc-doc-desc {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
.cardkyc-span-col {
  color: #7100ff;
}
.cardkyc-desc {
  font-size: 20px;
  text-align: center;
  color: #757575;
}
.cardkyc-desc-small {
  font-size: 13px;
  color: #333333;
}
.cardkyc-limit-text {
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
  .cardkyc-doc-desc {
    font-size: 24px;
  }
  .cardkyc-limit-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 1200px) {
  .cardkyc-title {
    font-size: 30px;
  }
  .cardkyc-subtitle {
    font-size: 23px;
  }
  .cardkyc-doc-desc {
    font-size: 22px;
  }
  .cardkyc-desc {
    font-size: 17px;
  }
  .cardkyc-limit-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .cardkyc-title {
    font-size: 28px;
  }
  .cardkyc-subtitle {
    font-size: 22px;
  }
  .cardkyc-desc {
    font-size: 15px;
  }
}
@media screen and (max-width: 900px) {
  .cardkyc-title {
    font-size: 26px;
  }
  .cardkyc-subtitle {
    font-size: 22px;
  }
  .cardkyc-doc-desc {
    font-size: 20px;
  }
  .cardkyc-limit-text {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .cardkyc-title {
    font-size: 25px;
  }
  .cardkyc-subtitle {
    font-size: 22px;
  }
  .cardkyc-doc-desc {
    font-size: 21px;
  }
  .cardkyc-desc {
    font-size: 17px;
  }
  .cardkyc-limit-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .cardkyc-title {
    font-size: 24px;
  }
  .cardkyc-subtitle {
    font-size: 22px;
  }
  .cardkyc-desc {
    font-size: 16px;
  }
  .cardkyc-limit-text {
    font-size: 17px;
  }
}
@media screen and (max-width: 500px) {
  .cardkyc-title {
    font-size: 23px;
  }
  .cardkyc-subtitle {
    font-size: 18px;
  }
  .cardkyc-desc {
    font-size: 15px;
  }
  .cardkyc-limit-text {
    font-size: 15px;
  }
}
@media screen and (max-width: 400px) {
  .cardkyc-title {
    font-size: 22px;
  }
  .cardkyc-subtitle {
    font-size: 14px;
  }
  .cardkyc-doc-desc {
    font-size: 16px;
  }
  .cardkyc-desc {
    font-size: 14px;
  }
  .cardkyc-limit-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 200px) {
  .cardkyc-title {
    font-size: 16px;
  }
}

// input-style
.cardedit-subtitle {
  font-size: 24px;
  color: #333333;
}
.form__group {
  position: relative;
  // padding: 15px 0 0;
}

.form__field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #bcbbbb;
  outline: 0;
  font-size: 1rem;
  color: #000;
  background: transparent;
  transition: border-color 0.2s;
  margin-bottom: 1rem;
  font-weight: 600;
}
.cardedit-head-text {
  font-size: 19px;
  margin-bottom: 0;
  margin-top: 0;
}
.cardedit-body-text {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

.form__field::placeholder {
  font-weight: lighter;
}

.validation-error-text {
  font-size: 12px;
  color: red;
  text-align: center;
  margin-top: -10px;
}
// .valid-text-align{
//   margin-top: -16px;
// }
.otp-box input {
  height: 4rem;
  width: 4rem !important;
}
@media screen and (max-width: 1024px) {
  .form__field::placeholder {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 900px) {
  .form__field::placeholder {
    font-size: 0.6rem;
  }
  .otp-box input {
    height: 3.5rem;
    width: 3rem !important;
  }
}

@media screen and (max-width: 767px) {
  .form__field::placeholder {
    font-size: 0.8rem;
  }
  .otp-box input {
    height: 3rem;
  }
}
@media screen and (max-width: 500px) {
  .form__field::placeholder {
    font-size: 0.7rem;
  }
}
.cardkyc-concent {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    margin: 0.5rem;
  }
}

.agreement-text {
  color: #333333;
  text-align: center;
}
.red-text {
  color: #ff0000;
}

.pan-dob-datepicker {
  width: 100px;
  text-align: center;
  background-color: #f8f8ff;
  border: 1px solid #ececff;
  border-radius: 5px;
}
@media screen and (max-width: 767px) {
  .pan-dob-datepicker {
    width: 65px;
  }
}
.kyc-form-input {
  display: block;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.kyc-form-input:placeholder-shown {
  font-weight: 200;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.kyc-label-align {
  font-size: 11px;
  margin-left: 12px;
  margin-left: 8px;
  margin-top: -10px;
  padding-right: 6px;
  padding-left: 6px;
  color: #9c9c9c;
  position: absolute;
  background-color: white;
  border: 2px solid white;
}

.kyc-form-control {
  .form_group_meterial {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 100%;
  }

  .form_field_meterial {
    width: 100%;
    height: 39px;
    font-size: 1.3rem;
    transition: border-color 0.2s;
    font-size: 14px;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form_label_meterial {
      font-size: 1rem;
      cursor: text;
      top: 20px;
    }
  }

  .form_label_meterial {
    position: absolute;
    top: 5px;
    display: block;
    transition: 0.2s;
    font-size: 0.8rem;
    color: #9c9c9c;
    left: 10px;
    background-color: white;
  }
  .form_group_meterial:focus {
    ~ .form_label_meterial {
      position: absolute;
      top: 5px;
      display: block;
      transition: 0.2s;
      font-size: 0.8rem;
      color: #000000;
      left: 10px;
      // background-color: white;
    }
  }
  .form_field_meterial:focus {
    ~ .form_label_meterial {
      position: absolute;
      top: 5px;
      display: block;
      transition: 0.2s;
      font-size: 0.8rem;
      color: #000000;
      left: 10px;
      // background-color: white;
    }
  }
  /* reset input */
  .form_field_meterial {
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }
}
.shipping-data-body {
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 0rem;
  img {
    width: 30px;
  }
  .map-img {
    width: 25px;
  }
}
.shoppingform-head-text {
  margin: 0;
  font-size: 16px;
}
.shoppingform-body-text {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
}
.shippingdata-edit-btn-style {
  position: relative;
  top: -26px;
  background-color: transparent;
  font-size: 15px;
  height: 44px;
}
.kyc-cardmanage-banner {
  background: rgb(114, 77, 220);
  background: linear-gradient(
    90deg,
    rgba(114, 77, 220, 1) 0%,
    rgba(146, 94, 244, 1) 100%
  );
  h1 {
    color: #fff;
  }
  .text-color-orange {
    color: #ffb400;
  }
  img {
    max-width: 280px;
  }
  .bold-head {
    font-weight: 600;
  }
}
.managecard-card-container {
  border-right: 2px solid #eaeaea;
}
.cardmanager-large-head-text {
  color: #fff;
  width: 90%;
}
.cardmanager-bannerhead {
  color: #fff;
}
@media only screen and (max-width: 400px) {
  .cardmanager-large-head-text {
    font-size: 30px;
  }
  .cardmanager-bannerhead {
    font-size: 26px;
  }
}
@media only screen and (min-width: 400px) {
  .cardmanager-large-head-text {
    font-size: 30px;
    text-align: center;
    width: 74%;
    margin: auto;
  }
  .cardmanager-bannerhead {
    font-size: 36px;
  }
}

@media only screen and (min-width: 600px) {
  .cardmanager-large-head-text {
    font-size: 35px;
    width: 51%;
    text-align: center;
    margin: auto;
  }
  .cardmanager-bannerhead {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .cardmanager-large-head-text {
    font-size: 45px;
    text-align: left;
    margin-left: 0;
    width: 82%;
  }
  .cardmanager-bannerhead {
    font-size: 30px;
    width: 70%;
  }
}

@media only screen and (min-width: 992px) {
  .cardmanager-large-head-text {
    font-size: 47px;
    width: 70%;
  }
  .cardmanager-bannerhead {
    font-size: 34px;
  }
}

@media only screen and (min-width: 1200px) {
  .cardmanager-large-head-text {
    font-size: 45px;
    width: 57%;
  }
  .cardmanager-bannerhead {
    font-size: 43px;
  }
}
@media only screen and (min-width: 1800px) {
  .cardmanager-large-head-text {
    font-size: 45px;
    width: 45%;
  }
  .cardmanager-bannerhead {
    font-size: 50px;
  }
}
.premium-badge {
  background: linear-gradient(
    90deg,
    rgba(186, 122, 0, 1) 0%,
    rgba(246, 218, 111, 1) 100%
  );
  color: #ffffff;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.2rem 2rem;
  margin-left: 0.5rem;
}
.elite-badge {
  background: linear-gradient(
    12.35deg,
    #c3c3c3 0%,
    #dddddd 21.69%,
    #ababab 40.67%,
    #fbebeb 73.57%,
    #b4b4b4 100%
  );
  color: #ffffff;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.2rem 2rem;
  margin-left: 0.5rem;
}
.managecard-cardbody {
  max-width: 430px;
  background: rgb(18, 7, 61);
  background: linear-gradient(
    90deg,
    rgba(18, 7, 61, 1) 0%,
    rgba(42, 45, 109, 1) 100%
  );
  border-radius: 15px;
  // margin: 0.5rem;
  border: 4px solid #fff;
  .card-illustration {
    width: 90%;
  }
  .card-name {
    color: #fecf3a;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0.4rem;
    padding-top: 10px;
    font-weight: bold;
    height: 35px;
    display: flex;
    align-items: center;
  }
  .card-balance-text {
    color: #fff;
    font-size: 12px;
    margin-bottom: 6px;
    padding-top: 20px;
  }
  .img-rupee {
    width: 22px;
    margin-top: -6px;
  }
  .amount-text {
    color: #fff;

    span {
      font-size: 1rem;
      width: 100px;
      letter-spacing: 2px;
    }
  }
  .cvv-dot-size {
    span {
      letter-spacing: 2px;
      font-size: 7px;
    }
  }
  .amount-text-hide {
    font-size: 8px;
    // margin-top: .5rem;
    margin-bottom: 0.46rem;
    color: #fff;
    display: flex;
    justify-items: center;
    align-items: flex-start;
    span {
      display: inline-block;
      text-align: center;
      justify-content: center;
      font-size: 0.3rem;
      width: 10px;
      margin-top: 6px;
    }
    .amount-text-extend {
      font-size: 1rem;
      margin-top: -7px;
    }
  }

  .managecard-winds-logo {
    width: 45px;
  }
  .account-number {
    min-height: 60px;
  }
  .account-number-text {
    color: #fff;
    font-size: 21px;
    letter-spacing: 2px;
  }
  .account-number-text-hide {
    span {
      font-size: 0.5rem;
      letter-spacing: 2px;
    }
    .account-number-text-extend {
      color: #fff;
      font-size: 21px;
    }
  }

  .card-validity-text {
    color: #ffffffaf;
    font-size: 12px;
  }
  .card-validity-data {
    border-right: 2px solid #ffffff77;
  }
  .pine-labs-logo {
    width: 78px;
    margin-top: 10px;
  }
  .card-validity-data-home{
    border-right: 2px solid #ffffff77;
    font-size: 10px;
  }
}
.managecard-home-background {
  background-image: url("../assets/prepaid_cards/ic_faq_illustration_coins.png");
}
.managecard-cardbody-home {
  width: 430px;
  height: 250px;
  z-index: 2;
  .home-managecard-head-logo {
    width: 230px;
  }

  .home-managecard-premium-logo {
    width: 100px;
  }
  .text-red {
    color: #ff0000;
  }
  .low-balance-text {
    font-weight: bold;
    font-size: 10px;
  }
  .home-text-Smoke-white {
    color: #d4dde6bc;
  }
  .card-name {
    font-size: 16px;
  }
  .pine-labs-home-logo{
    width: 76px;
    // margin-top: 17px;
  }
}
.home-ppi-card-upgrade-btn {
  z-index: 10;
  width: 95%;
}
.ppi-card-lottie{
  width: 10%;
  position: relative;
  bottom: 5px;
  
}
.success-bg-lottie-text{
  position: relative;
  bottom: 5px;
}
@media only screen and (max-width: 500px) {
  .managecard-cardbody-home {
    width: 100%;
    height: 220px;
    .card-name {
      font-size: 12px;
    }
    .home-managecard-head-logo {
      width: 120px;
    }
    .pine-labs-home-logo{
      width: 55px;
      // margin-top: 15px;
    }
    .winds-logo-home-card{
      width: 25px;
    }
  }
  .home-ppi-card-upgrade-btn {
    width: 60%;
  }
  .managecard-cardbody-home {
    .home-managecard-winds-logo {
      width: 34px;
    }
    .home-managecard-rupay-logo {
      width: 70px;
    }
    .home-managecard-pinelabs-logo {
      width: 67px;
    }
    .home-managecard-premium-logo {
      width: 100px;
    }
  }
  
  .ppi-card-lottie{
    width: 9%;
    margin-top: -4px;
    
  }
  .success-bg-lottie-text{
    font-size: 13px;
  }
}
@media only screen and (max-width: 300px) {
  .managecard-cardbody-home {
    width: 100%;
    height: 200px;
    .card-name {
      font-size: 12px;
    }
    .home-managecard-head-logo {
      width: 120px;
    }
  }
  .home-ppi-card-upgrade-btn {
    width: 60%;
  }
  .managecard-cardbody-home {
    .home-managecard-winds-logo {
      width: 34px;
    }
    .home-managecard-rupay-logo {
      width: 70px;
    }
    .home-managecard-pinelabs-logo {
      width: 67px;
    }
    .home-managecard-premium-logo {
      width: 100px;
    }
    .winds-logo-home-card{
      width: 25px;
    }
    .pine-labs-home-logo{
      width: 55px;
      // margin-top: 45px;
    }
  }
  .ppi-card-lottie{
    width: 6%;
  }
  .success-bg-lottie-text{
    font-size: 16px;
  }
}

.styled-line-text:before {
  content: " ";
  display: block;
  height: 1px;
  width: 20%;
  position: absolute;
  top: 50%;
  left: 0;
  background: rgb(0, 0, 0);
}
.styled-line-text {
  position: relative;
  max-width: 500px;
  margin: 20px auto;
  margin-top: 50px;
  text-align: center;
  font-weight: 600;
}
.styled-line-text:after {
  content: " ";
  height: 1px;
  width: 20%;
  background: rgb(0, 0, 0);
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .styled-line-text:after {
    width: 30%;
  }
  .styled-line-text:before {
    width: 30%;
  }
}
.quick-logo {
  margin: 10px 15px;
  // padding: 20px;
  background-color: #f3f3f3;
  border-radius: 15px;
  width: 80px;
  @media only screen and (max-width: 768px) {
    width: 50px;
    // padding: 12px;
  }
}
.managecard-manage-link-container {
  background-color: #f4f4ff;
  border-radius: 6px;
  .managecard-link-head {
    font-size: 18px;
    color: #333333;
  }
  .managecard-link-body {
    font-size: 12px;
    color: #818181;
  }
  .text-red {
    color: #ff0000;
  }
}
.managecard-link-icons {
  width: 60px;
  // margin: 17px;
}
.managecard-alert-box {
  border: 2px solid #dddddd;
  border-radius: 10px;
  // width: 60%;
  margin: auto;
  .text-blue {
    color: #7d70ff;
  }
}
.ppi-selection-innactivate {
  // user-select: none;
  opacity: 0.5;
  pointer-events: none;
}

.cardBlock-offset-container {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  padding-top: 60px;
  // max-width: 450px;
}
.kyc-cardmanage-faq-banner {
  background: rgb(180, 255, 210);
  background: linear-gradient(
    270deg,
    rgba(180, 255, 210, 1) 0%,
    rgba(0, 155, 163, 1) 100%
  );
}
.cardmanager-faq-bannerbody {
  color: #fff;
}
.faq-accordion-style {
  border: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .managecard-accordion-toggle-btn {
    width: 100%;
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .accordion-arrow {
    font-size: 28px;
    color: #717171;
    // font-weight: 400;
  }
  .accordion-card-body {
    font-size: 14px;
    color: #818181;
  }
  .card-header {
    border-bottom: 0;
    color: #333333;
  }
  .faq-accordian-item {
    border-bottom: 1px solid #e8e8e8;
  }
}

.validation-error-text2 {
  font-size: 12px;
  color: red;
}

.delete-card-area .round input[type="radio"]:checked + label {
  background-color: #4e5ab6;
  border-color: #4e5ab6;
}
.delete-card-area {
  .animated-btn {
    height: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .mySidenav-active {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .mySidenav-active {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .mySidenav-active {
    width: 90%;
  }
}

@media only screen and (min-width: 992px) {
  .mySidenav-active {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .mySidenav-active {
    width: 40%;
  }
}
@media only screen and (min-width: 1400px) {
  .mySidenav-active {
    width: 33%;
  }
}
.off-canvas-active {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #333333;
  z-index: 999;
}
.ppi-delete-conscent .tick_mark {
  width: 25px;
  height: 23px;
}
.ppi-delete-conscent hr {
  width: 100%;
  height: 2px;
}
.ppi-plane-border-button {
  border: 1px solid #000;
  border-radius: 30px;
  background-color: #fff;
  font-weight: bold;
}
.ppi-upgrade-now-button {
  color: #fff;
  background-color: #501ebd;
  border-radius: 50px;
  border: none;
  font-size: 22px;
  font-weight: bold;
}
.ppi-card-limit {
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #7d53e4;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #7d53e4;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.ppi-card-limit {
  .limit-data {
    background-color: #f8f8ff;
    // border-radius: 5px;
  }
  #limit-data-deactive {
    opacity: 0.5;
    pointer-events: none;
  }
  .ppi-limit-btn {
    font-size: small;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 50px;
    background: linear-gradient(90deg, #ffb4e9, #7f00ff 50%, #ffb4e9);
    background-size: 200%;
    animation: anime 8s linear infinite;
    border: none;
  }
}
.ppi-card-limit-text-box {
  background: rgb(241, 233, 255);
  background: linear-gradient(
    90deg,
    rgba(241, 233, 255, 1) 0%,
    rgba(201, 186, 245, 1) 100%
  );
  border-radius: 5px;
  width: auto;
}
.ppi-card-limit-view-box {
  background-color: #fff;
  color: #4e77f7;
  border-radius: 5px;
  border: 2px solid #d9dee4;
  text-align: center;
  font-weight: bold;
  width: fit-content;
  font-size: 25px;
}

.card-limit-input-range-style {
  input[type="range"] {
    // -webkit-appearance: none;
    height: 7px;
    background: #d8d8d8;
    border-radius: 5px;
    background-image: linear-gradient(#4e77f7, #92acfb);
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #4e77f7;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #4e77f7;
    transition: background 0.3s ease-in-out;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: #4e78f749 0px 0px 0px 8px;
  }

  input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: #4e78f74c 0px 0px 0px 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

@media screen and (max-width:768px) {
  .card-actions p{
    font-size: xx-small;
  } 
}

.upgrade-strip{
  background: linear-gradient(90deg, #F1E9FF 0%, #C9BAF5 100%);
  width: fit-content;
  padding: 5px 10px;
  margin: auto;
  border-radius: 5px;
}

.cardlimit-accordion-style {
  border: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .cardlimit-accordion-toggle-btn {
    width: 100%;
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .accordion-arrow {
    font-size: 28px;
    color: #717171;
    // background-color: #f7f7ff;
    // font-weight: 400;
  }
  .accordion-card-body {
    font-size: 14px;
    color: #818181;
  }
  .card-header {
    border-bottom: 0;
    color: #333333;
  }
  // .cardlimit-accordian-item {
  //   // border-bottom: 1px solid #E8E8E8;
  // }
}
.ppi-homepage-slider {
  position: absolute;
  top: 0;
}
.ppi-coin-bg {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
.ppi-carousel .carousel-control-next-icon {
  // background-color: #07012d64 !important;
  width: 40px !important;
  height: 40px !important;
  background-image: url("../../src/assets/prepaid_cards/ic_next_button_card.svg");
}
.ppi-carousel .carousel-control-prev-icon {
  // background-color: #00000064 !important;
  width: 40px !important;
  height: 40px !important;
  background-image: url("../../src/assets/prepaid_cards/ic_previous_button_card.svg");

}
// .swipper-mobile-view{
//   // display: none;
// }
@media only screen and (max-width: 1051px) {
  .ppi-carousel .carousel-control-prev-icon {
    display: none;
  }
  .ppi-carousel .carousel-control-next-icon {
    display: none;
  }
  .swipper-mobile-view{
    display: block;
  }
}

.statement-header{
  color: #49527E;
  font-size: 19px;
  @media only screen and (max-width: 768px){
    font-size: 15px;
  }
  @media only screen and (max-width: 400px){
    font-size: 11px;
  }
}
.statement-body{
  color: #818181;
  font-size: 17px;
  @media only screen and (max-width: 768px){
    font-size: 13px;
  }
  @media only screen and (max-width: 400px){
    font-size: 10px;
  }
}
.statement-icon{
  width: 33px;
  @media only screen and (max-width: 768px){
    width: 25px;
  }
  @media only screen and (max-width: 400px){
    width: 20px;
    color: #d8d8d82e;
    
  }
}
.declaration-link a{
  color: #4E77F7;
  font-weight: bold;
}
.cursor-not-allowed{
  cursor: not-allowed !important;
}
.statement-active-btn{
  color: #fff !important;
  background-color: #7D70FF !important;
}