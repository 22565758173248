.bill-pay-page {
  width: 100%;
  min-height: 300px;
  display: block;
  position: relative;
}

.container-fluid--bill-pay-icon {
  height: 80px;

  @media (min-width: 992px) {
    height: 100px;
  }
}

.card--bill-pay-form {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.bill-pay-page-icon-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;

  .bill-pay-service-icon {
    flex: 1;
    flex-basis: 33.33%;
    margin-bottom: 50px;
  }
}

.bill-pay-service-icon-img {
  height: 30px;

  @media (min-width: 992px) {
    height: 50px;
    margin-bottom: 0 !important;
  }
}
.billpay-page .bill-pay-service-icon-img {
  height: 90px;

  @media (max-width: 992px) {
    height: 65px;
    margin-bottom: 0 !important;
  }
  @media (max-width: 767px) {
    height: 45px;
  }
}

.billpay-page {
  flex: 1;
  flex-basis: 33.33%;
  margin: 20px 0;

  @media (min-width: 992px) {
    width: 16.66%;
    flex-basis: auto;
    justify-content: flex-start !important;
  }
}
.billpay-page .bill-pay-icon-container {
  justify-content: flex-start !important;
}

.billpay-page .recharge-img-box {
  background: #f5f6ff;
  width: fit-content;
  margin: 0 auto;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d5dbff;
  margin-bottom: 8px;
}
@media screen and (max-width: 600px) {
  .billpay-page .recharge-img-box {
    width: 70px;
    height: 70px;
  }
}

.bill-pay-service-icon-label {
  font-size: 12px;

  @media (min-width: 992px) {
    font-size: 16px;
  }
}

.nav--plans {
  .nav-tabs {
    overflow-x: scroll;
    overflow-y: hidden;

    @media (min-width: 992px) {
      overflow-x: hidden;
    }
  }
}

.pay-now-input {
  border: none;
  background-color: transparent;
}

.bill-upload {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 990px) {
    justify-content: flex-start;
  }
}

.upload-box {
  height: 150px;
  width: 150px;
  background-color: white;
  border: 3px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc;
  overflow: hidden;
  margin: 0.5rem;
  position: relative;
  cursor: pointer;

  .plus {
    font-size: 3rem;
    margin: -1rem 0;
  }

  .close-btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 0rem;
    right: 0rem;
    z-index: 10;
    justify-content: center;
    align-items: center;
  }
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.operator-select {
  border: none;
  border-bottom: 2px solid #e7e7e7;
  outline: none;
}

.recharge-input {
  position: relative;

  .btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.repeat-btn {
  margin-left: -12px;

  @media (min-width: 990px) {
    margin-left: 0;
  }
}

.bill-detail-btn {
  width: 100%;
  @media (min-width: 990px) {
    width: 50%;
  }
}

.display-btns-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: 990px) {
    flex-direction: row;
  }

  .btn-wrapper {
    width: 100%;

    @media (min-width: 990px) {
      width: 49%;
    }
  }

  .disable-btn {
    display: none !important;
  }

  .enlarge-btn {
    width: 100% !important;
  }
}
.recharge-img-box {
  background: #e9e0fb;
  width: fit-content;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bf97ff;
  margin-bottom: 8px;
}

.blinking {
  animation: blink 1s infinite;
}
.billpay-banner {
  background: url("../assets/billpay-banner.png");
  background-size: cover;
  background-position-y: bottom;
  padding: 15px 0 20px;
  @media (max-width: 600px) {
    padding: 20px 0 50px;
  }
  @media (min-width: 1800px) {
    padding: 20px 0 50px;
    background-position-y: 80%;
  }
}
@media (min-width: 992px) {
  .head-margin {
    margin-top: -110px;
  }
}
.billpay-banner .image {
  max-width: 60%;
}
.section-content-header{
  font-size: xx-large;
}
@media (max-width: 767px) {
  .billpay-banner .image {
    max-width: 80%;
  }
  .section-content-header{
    font-size: 25px;
  }
}
.billpay-form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ca87f1;
  outline: 0;
  box-shadow: none;
}
.billpay-banner h1 {
  font-weight: 700;
  color: #646dff;
  font-size: xxx-large;
  @media (max-width: 990px) {
    font-size: x-large;
  }
  @media (max-width: 600px) {
    font-size: x-large;
  }
  @media (max-width: 300px) {
    font-size: large;
  }
}
.billpay-banner p {
  font-weight: 600;
  font-size: x-large;
  @media (max-width: 990px) {
    font-size: large;
  }
  @media (max-width: 600px) {
    font-size: small;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.bp-assured-horizontal {
  width: 140px;
  @media screen and (max-width: 767px) {
    width: 75px;
  }
}

h2.billpay-service-title {
  font-weight: 600;
  color: #333333;
  font-size: xx-large;
  @media screen and (max-width: 767px) {
    font-size: large;
  }
}
.billpay-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 20px;
}
.billpay-data:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.billpay-data.text-center:first-child {
  margin-top: 30px;
}
.complaint-form-control {
  width: 100%;
  background: transparent;
  border: none;
  padding: 8px 0;
  font-weight: 600;
  border-bottom: 1px solid gainsboro;
}
.complaint-form-control:focus {
  // border: none;
  outline: none;
  box-shadow: none;
}

.billpay-order-img-box {
  align-items: center;
  background: #f5f6ff;
  border: 1px solid #d5dbff;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 40px;
  min-width: 40px;
  overflow: hidden;
}
.billpay-providers-img-box {
  align-items: center;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  display: flex;
  // height: 40px;
  justify-content: center;
  margin-right: 10px;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  overflow: hidden;
}
.billpay-paid-img-box {
  align-items: center;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.billpay-order-btn {
  background: #7d70ff;
  padding: 12px;
  color: white;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.provider-search-img {
  position: absolute;
  z-index: 9;
  display: flex;
  right: 3%;
  top: 0;
  bottom: 0;
  margin: auto;
}
.biller-search {
  border: 1px solid #e6e6e6;
  padding: 10px 15px;
  border-radius: 30px;
  text-align: left;
  background: #fafafa;
}
.biller-search:focus {
  border: 1px solid #c5c5c5;
  box-shadow: 0 0 0 0.1rem rgb(111 130 231 / 42%);
}
.biller-search:focus-visible {
  border: 1px solid #e6e6e6;
  outline: 0;
}
.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.billpay-order-btn:hover {
  color: white;
}
.billpay-btn {
  background: #7d70ff;
  border-radius: 10px;
  padding: 2px 10px;
  color: #fff;
  font-weight: 500;
  border: none;
  float: right;
  font-size: 85%;
  font-weight: 600;
  min-width: 90px;
  font-weight: 600;
}
.box-billpay-provider {
  height: 50px !important;
  width: 100%;
}
input:where([type="radio"]) {
  width: 22px;
  height: 22px;
  border-radius: 4px;
}
input[type="radio"]:checked {
  background-image: url("../assets/billpay/search.png");
}
.provider-scroll-list {
  min-height: 250px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.border-md {
  border: 2px solid #f0f0f0 !important;
  @media screen and (max-width: 767px) {
    border: none !important;
  }
}
.provider-scroll-list .d-flex:last-child {
  border-bottom: none !important;
}

/* width */
.provider-scroll-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.provider-scroll-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.provider-scroll-list::-webkit-scrollbar-thumb {
  background: #7d70ff;
}

.no-header-modal .modal-header {
  border-bottom: none !important;
}
.add-info{
  border-bottom: 1px dashed silver;;
}
.add-info:last-child{
  border-bottom: none;
}
.btn-width-50{
  width: 50%;
  @media screen and (max-width:767px) {
    width: 75%;
  }
}
