.card--shopping-site-logo {
  border: 2px solid #eae4ff;
  // box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: 100% auto;

  .card-body {
    padding: 0;

    .logo {
      //   border-radius: 8px;
      //   background: linear-gradient(
      //     180deg,
      //     rgba(255, 255, 255, 0) 0%,
      //     rgba(255, 255, 255, 0.79) 66.41%,
      //     #ffffff 100%
      //   );

      img {
        height: 30px;
        padding: 80px 0 10px 0;
        box-sizing: content-box;
        margin: 0 auto;
        display: block;

        @media (min-width: 992px) {
          height: 50px;
          padding: 180px 0 10px 0;
        }
      }
    }
  }
}

.box-shopping-site-detail-logo {
  height: 345px !important;

  @media (min-width: 992px) {
    height: 450px !important;
  }
}

.shopping-sites-bill-upload-vector {
  width: 300px;

  @media (min-width: 992px) {
    width: 500px;
  }
}

.card--shopping-site-detail-logo {
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: none;
  min-height: 345px;
}

.shopping-sites-vecotr {
  width: 300px;

  @media (min-width: 992px) {
    width: 500px;
  }
}

.filter-categories {
  padding-left: 0;
  margin-top: -1rem;
}

.banner-web,
.banner-mob {
  width: 100vw;
}

.banner-mob {
  display: block;

  @media (min-width: 990px) {
    display: none;
  }
}

.banner-web {
  display: none;

  @media (min-width: 990px) {
    display: block;
  }
}

.bread-crumbs {
  position: absolute;
  color: white;
  top: 1rem;
  // left: 1rem;
  z-index: 2;
}

.logo-container {
  width: 150px;
  position: absolute;
  z-index: 2;
  @media (max-width: 1200px) {
    bottom: -3rem;
    left: 1rem;
  }

  @media (min-width: 990px) {
    width: 200px;
    bottom: -7rem;
    top: 3rem;
  }

  .card {
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
    border: none;
  }

  .card-body {
    padding: 0;
  }
}

.affliate-logo-img {
  width: 80%;
  margin: auto;
  display: block;
  padding: 3rem 0;

  @media (min-width: 990px) {
    padding: 4rem 0;
  }
}

.affiliate-banner {
  height: 25vh;
  position: relative;

  @media (min-width: 990px) {
    height: 30vh;
    background-color: #fff;
  }

  &::after {
    background: linear-gradient(to top right, #ed709d, #ec8a6c);
    left: 0;
    right: 0;
    content: " ";
    display: block;
    z-index: 0;
    top: 0;
    bottom: 0;
    mix-blend-mode: multiply;
    position: absolute;
  }
}

.bill-upload-input-wrapper {
  position: relative;

  .custom-file-input {
    display: none;
  }

  .custom-file-label {
    .input-file-box {
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    .input-file-btn {
      background: #ced4da;
      padding: 5px;
      position: absolute;
      border: 1px solid #ced4da;
      top: 0px;
      right: 0px;
    }

    &::after {
      content: none;
    }
  }
}

.wrp-btn {
  margin-top: 0.4rem;
}

.multiple-bill {
  .modal-dialog {
    max-width: 100%;

    .carousel {
      .carousel-item {
        width: 100%;
        float: none;
        margin: auto;

        @media (min-width: 992px) {
          width: 80%;
        }

        .carousel-card {
          height: 100%;
          width: auto;

          .reveal-icon {
            img {
              width: 100%;
            }
          }
        }
      }

      .carousel-control-next,
      .carousel-control-prev {
        top: 50%;
        transform: translate3d(0, -50%, 0);

        @media (min-width: 992px) {
          top: 0;
          transform: none;
        }
      }

      .carousel-control-next {
        margin-right: -1rem;
        @media (min-width: 992px) {
          margin-right: -4rem;
        }
      }
      .carousel-control-prev {
        margin-left: -1rem;
        @media (min-width: 992px) {
          margin-left: -4rem;
        }
      }
    }
  }
}

.card-body {
  .claim-btn {
    margin: 1rem auto;

    @media (min-width: 992px) {
      margin: 1rem 0.5rem;
    }
  }
}
.shopping-earn-badge {
  position: absolute;
  top: 0;
  text-align: center;
  .inner-text {
    background-color: #eae4ff;
    font-size: 12px;
    width: auto;
    color: #494949;
    font-weight: bold;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-top: -3px;
  }
}
.shoping-badge-content {
  display: flex;
  // margin-top: -25px;
  margin-top: -10px;
}
.shopping-offer-badge {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background-color: #27b455;
  border-bottom-left-radius: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}
.shoping-badge-content::after {
  content: " ";
  width: 11%;
  background-color: #ffffff;
  rotate: 45deg;
  margin-left: -10px;
  margin-bottom: 3px;
}

@media only screen and (max-width: 350px) {
  .shopping-earn-badge {
    .inner-text {
      font-size: 8px;
    }
  }
  .shoping-badge-content {
    display: flex;
  }
  .shopping-offer-badge {
    font-size: 8px;
  }
  .shoping-badge-content::after {
    margin-left: -6px;
    margin-bottom: 4px;
    width: 12%;
  }
}
@media only screen and (min-width: 300px) {
  .shoping-badge-content::after {
    margin-left: -8px;
    margin-bottom: 2px;
    width: 19px;
  }
}
@media only screen and (min-width: 400px) {
  .shoping-badge-content::after {
    margin-left: -9px;
  }
}
@media only screen and (min-width: 500px) {
  .shoping-badge-content::after {
    margin-left: -9px;
    margin-bottom: 2px;
    width: 11%;
  }
}
@media only screen and (min-width: 600px) {
  .shoping-badge-content::after {
    margin-left: -5px;
    margin-bottom: 3px;
    width: 9%;
  }
}
@media only screen and (min-width: 768px) {
  .shopping-offer-badge {
    padding-left: 5px !important;
    font-size: 10px;
  }
  .shopping-earn-badge .inner-text {
    font-size: 10px;
  }

  .shoping-badge-content::after {
    margin-left: -10px;
    margin-bottom: 1px;
    width: 15%;
  }
}
@media only screen and (min-width: 850px) {
  .shopping-offer-badge {
    padding-left: 10px !important;
    font-size: 10px;
  }
  .shopping-earn-badge .inner-text {
    font-size: 12px;
  }
  .shoping-badge-content::after {
    margin-left: -10px;
    margin-bottom: 1px;
    width: 14%;
  }
}
@media only screen and (min-width: 992px) {
  .inner-text {
    font-size: 9px;
  }
  .shopping-offer-badge {
    font-size: 12px;
  }
  .shopping-earn-badge .inner-text {
    font-size: 12px;
  }
  .shoping-badge-content::after {
    margin-left: -9px;
    margin-bottom: 0px;
    width: 19%;
  }
}
@media only screen and (min-width: 1200px) {
  .inner-text {
    font-size: 9px;
  }
  .shopping-offer-badge {
    font-size: 14px;
  }
  .shoping-badge-content::after {
    margin-left: -6px;
    margin-bottom: 4px;
    width: 10%;
  }
}
// margin-left: -12px;
//     margin-bottom: -2px;
//     width: 11%
.filterkey-btn {
  border: none;
  border-radius: 4px;
  background-color: #ece6ff;
  border: 1px solid #e3daff;
  font-size: 12px;
  line-height: 2.8 ;
  
  span{
    // width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }
  
  @media only screen and (min-width: 400px) {
    font-size: 16px;
  line-height: 2.5 ;

  }
}
#filter-dropdown {
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  padding: 0.5rem;
  font-size: 12px;
  border-radius: 5px;
  @media only screen and (min-width: 400px) {
    font-size: 16px;
  }
}
.shopping-search {
  border: 1px solid #eeeeee;
  // padding: 5px 5px 25px 25px;
  border-radius: 18px;
  // position: relative;
  background-color: #fafafa;
  font-size: 12px;
  @media only screen and (min-width: 400px) {
    font-size: 16px;
  }
}
.shopping-search-img {
  width: 19px;
  position: absolute;
  z-index: 9;
  display: flex;
  top: 31%;
  left: 9%;
  @media only screen and (min-width: 400px) {
    top: 33%;
    left: 6%;
  }
  @media only screen and (min-width: 768px) {
    top: 21%;
    left: 6%;
  }
}
.category-dropdown::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.category-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.category-dropdown::-webkit-scrollbar-thumb {
  background: #7d70ff;
}
.ShoppingBannerCarousal .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #7d70ff;
}
.ShoppingBannerCarousal .slick-dots li button:before {
  color: #7c70ff82;
}
.shopping-banner-img {
  border-radius: 5px;
  @media only screen and (min-width: 600px) {
    border-radius: 15px;
  }
}
.popover {
  z-index: 99 !important;
}
