.profile-container-head {
  background-image: url("../assets/profile_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.profile-options {
  background-color: #f8f8ff;
  border-radius: 10px;
  border: 1px solid #e7e7fe;
  /* box-shadow: rgb(123 123 123 / 21%) 0px 3px 7px 0px; */
  margin-bottom: 15px !important;
  .arrow-option {
    width: 100%;
  }
}
.profile-arrow-icon {
  width: 13px;
}
.profile-progress-bar {
  height: 8px;
  background-color: #d8d8d8 !important;
}
.bg-progress-bar-style {
  background: linear-gradient(
    270deg,
    #6302fd 0%,
    rgba(136, 5, 255, 1) 100%
  ) !important;
}
.profile-text-small {
  font-size: 13px;
  color: #4e77f7;
}
.profile-text-blue {
  color: #4e77f7;
}
.profile-text-blue-dark {
  color: #49527e;
}
.profile-info-box {
  border: 2px solid #e7eaff;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.profile-info-box-head {
  background-color: #f5f6ff;
  border-top: 2px solid #e7eaff;
  border-left: 2px solid #e7eaff;
  border-right: 2px solid #e7eaff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.profile-edit-modal {
  .profile-info-box {
    border: 2px solid #e7eaff;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .profile-info-box-head {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .edit-data-input {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
  }
}
#btn-shopping-preference-main {
  background-color: #8660f2 !important;
  color: #fff !important;
}
#btn-shopping-preference-main:hover {
  color: #212529;
  background-color: #6341c9 !important;
  // border-color: #8ad3d3
}
#btn-preference-light {
  color: #49527e !important;
}
.toggle-group{
  .btn-outline-light{
    color: #838383 !important;
    background-color: #ffffff !important;
    border-color: #c7c7c7 !important
  }
  .btn-outline-light:hover{
    color: #000000 !important;
    background-color: #f1eff6 !important;
    border-color: #525252 !important;
  }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #fff !important;
    background-color: #4E77F7 !important;
    border-color: #4E77F7 !important;
}
.btn-outline-light:focus{
  color: #fff;
   background-color: #676666;
   border-color: #adadad;
   outline: none;
}
}
a.active .profile-options {
  background: #f0f0ff !important;
  border: 1px solid #6601fd;
}
.completed-profile{
  padding: 8px 10px;
  background: linear-gradient(270deg, #6302FD 0%, #8805FF 100%);
  color: #f5f5f5;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;

}

.success-modal{
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
 border-radius: 25px;
 .text-gold{
  color: #FF8B00;
}
 .text-grey{
  color: #5C5C5C;
 }
 .text-bold{
  font-weight: 700;
 }
}

.wrp-success-bg-lottie{
  position: absolute;
  top: -0%;
}
.wrp-success-btn{
  z-index: 9;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.follow-us-list{
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.2rem;
    @media only screen and (min-width: 768px) {
      line-height: 2rem;
    }
  }
  
  li {
    padding-left: 1em; 
    text-indent: -1.3em;
    font-size: small;
    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  
  li::before {
    content: "• ";
    color: #6372FF;
    font-size: 30px;
  }
}
.follow-us-tittle{
  font-size: x-large;
  @media only screen and (min-width: 768px) {
    font-size: 35px;
  }
}
.follow-us-header{
  font-size: large;
  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
}
.border-seperator{
  border-right: 0px solid #D8D8D8;
  @media only screen and (min-width: 768px) {
    border-right: 1px solid #D8D8D8;
  }
}