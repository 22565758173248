
.deal-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,.08);
    padding: 0.5rem;
    margin: 0.5rem 0.5rem;
    font-family: proxima-nova, sans-serif !important;

    .deal-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 50%;

            @media(min-width: 992px) {
                width: 25%;
            }
        }

        .tag {
          padding: 0.3rem 0.5rem;
          border-radius: 8px;
          color: #fff;
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: 700;   
        }

        .hot {
            background-color: #EB520D;
        }

        .new {
            background-color: #009D51;
        }
    }

    .deal-body {
        margin-top: 0.5rem;

        .deal-title {
            color: #1e4cab;
            font-size: 0.8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (min-width: 992px) {
                font-size: 1.15rem;
            }
        }

        img {
            width: 100%;
            display: block;
            margin: auto;

            
          
        }

        .deal-price-wrapper {
            display: flex;
            flex-direction: row;
            align-self: center;
            margin-top: 0rem;
            justify-content: space-between;

            @media (min-width: 992px) {
                margin-top: 1.5rem;
                flex-direction: column;
                text-align: right;
            }

            @media (max-width: 990px) and (min-width: 768px) {
                flex-direction: column;
                margin-top: 0.5rem;
            }

            h5 {
                font-size: 0.8rem;

                @media (min-width: 992px) {
                    font-size: 1.15rem;
                }

                @media (max-width: 990px) and (min-width: 768px) {
                    font-size: 0.75rem;
                }
                
            }

            .mrp {
                text-decoration: line-through;
            }

            .offer-price {
                color: #ff4e4e;
            }
        }
    }

    .deal-footer {
        text-align: right;

        .deals-btn {
            width: 100%;
            padding: 0.3rem;

            @media (min-width: 992px) {
                width: 50%;
                padding: .8rem 0rem;
            }
        }
    }
}

.deals-carousel {
    .slick-slide {
        margin: 0.5rem !important;
        width: 125px !important;

        @media (min-width: 1600px) {
            width: 10.8% !important;
            margin: 1rem !important;
        }

        @media (max-width: 1599px) and (min-width: 1400px) {
            width: 10.8% !important;
            margin: 1rem !important;
        }

        @media (max-width: 1399px) and (min-width: 1281px) {
            width: 9% !important;
            margin: 1rem !important;
        }

        @media (max-width: 1280px) and (min-width: 1025px) {
            width: 8.5% !important;
            margin: 1rem !important;
            
        }

        @media (max-width: 1024px) and (min-width: 992px) {
            width: 9% !important;
            margin: 1rem !important;
        }

        @media (max-width: 990px) and (min-width: 768px) {
            width: 230px !important;
        }
        @media (max-width: 990px) and (min-width: 768px) {
            width: 230px !important;
        }
        
       
    }

    .slick-next {
        right: 0 !important;
    }

    .slick-track {
        width: 150px !important;
    }
}
.deals-carousel-home {
    .slick-slide {
        // margin: 0.5rem !important;
        // width: 3312px;
        position: relative;
        // @media (min-width: 1600px) {
        //     max-width: 250px !important;
        //     margin: 1rem !important;
        // }

        // @media (max-width: 1599px) and (min-width: 1400px) {
        //     width: 230px !important;
        //     width: calc(100/5) !important;
        //     margin: 1rem ;
        // }

        // @media (max-width: 1399px) and (min-width: 1281px) {
        //     max-width: 205px !important;
        //     width: calc(100/5) !important;
        // }
        // @media (max-width: 1300px) and (min-width: 1280px) {
        //     max-width: 185px !important;
        //     margin: 1rem !important;
            
        // }
        // @media (max-width: 1280px) and (min-width: 1025px) {
        //     max-width: 180px !important;
        //     margin: 1rem !important;
            
        // }

        // @media (max-width: 1024px) and (min-width: 992px) {
        //     max-width: 160px !important;
        //     margin: 1rem !important;
        // }

        // @media (max-width: 990px) and (min-width: 768px) {
        //     width: 230px !important;
        // }
        // @media (max-width:767px){
        //     .font-proxima-nova{
        //         font-size: x-small;
        //     }
        // }
        // @media (max-width: 768px) and (min-width: 567px) {
        //     width: 230px !important;
        // }
        // @media (max-width: 567px) and (min-width: 420px) {
        //     width: 195px !important;
        // }
        // @media (max-width: 420px) and (min-width: 350px) {
        //     max-width: 155px !important;
        //     margin: 0.5rem !important;
        // }
        // @media (max-width: 350px) and (min-width: 280px) {
        //     max-width: 130px !important;
        //     margin: 0.5rem !important;
        // }
    }


    .slick-track {
        width: 2825px !important;
    }
}
.deals-page {
    justify-content: center;
    
    @media (min-width: 1024px) {
        justify-content: flex-start;
    }
    
    .deal-card {
        .deal-card-header {   
            img {
                width: 25%;
            }
        }
    
        .deal-body {
            margin-top: 0.5rem;
    
            .deal-title {
                font-size: 1rem;
            }
    
            img {
                width: 100%;
                display: block;
                margin: 0;
            }
    
            .deal-price-wrapper {
                margin-top: 2.5rem;
                flex-direction: column;
    
                h5 {
                    font-size: 1.25rem;

                    @media (min-width: 992px) {
                        font-size: 1.15rem;
                        text-align: right;
                    }

                    @media (max-width: 990px) and (min-width: 768px) {
                        font-size: 1rem;
                    }
                }
            }
    
            .deal-footer {
                text-align: right;
        
                .deals-btn {
                    width: 50%;
                }
            }
        }
    }   
}

.empty-state {
    width: 100%;
    margin: auto;
    text-align: center;
    padding-bottom: 1rem;

    // @media (min-width: 992px) {
    //     width: 30%;
    // }

    h4 {
        margin-top: 1rem;
    }

    .no-offers-title {
        width: 100%;
    }
}

.tag-img {
    width: 60px !important;

    img {
        width: 100%;
    }
}
// @media (min-width: 992px) {
//     .deal-head{
//         max-width: 20%;
//     }
// }

@media (min-width:300px) and (max-width:576px){
    .deals-brand-logo{
        width: 50% !important;
    
    }
}

@media (min-width:576px) and (max-width:768px){
    .deals-brand-logo{
        width: 100% !important;
    
    }
    .brands-logo {
        width: 50%!important;
    }
}
@media (min-width:768px) and (max-width:1024px){
   
    .brands-logo {
        width: 50%!important;
    }
}
@media (min-width:1024px){
    .deals-brand-logo{
        width: 50% !important;
    }
    .custom-col {
        flex: 0 0 20% !important; 
        max-width: 20% !important;
        
      }
}

@media (min-width:300px) and (max-width:420px){
    .deal-brand-logo{
        width: 550px;
    }
}  

@media (min-width:300px) and (max-width:375px){
    .custom-col {
        flex: 0 0 100%; 
        max-width: 100%;
        
      }
      .deal-btn{
        font-size: 10px!important;
      }
      .mrp{
        font-size: 14px;
      }
      .offer-prices{
        font-size: 14px;
      }
}
@media (min-width:375px) and (max-width:425px){
    .custom-col {
        flex: 0 0 50%; 
        max-width: 50%;
        
      }
      .deal-btn{
        font-size: 10px!important;
      }
      .mrp{
        font-size: 14px;
      }
      .offer-prices{
        font-size: 14px;
      } 
}
@media (min-width:425px) and (max-width:576px){
    .custom-col {
        flex: 0 0 50%; 
        max-width: 50%;
        
      }
      .deal-btn{
        font-size: 10px!important;
      }
      .mrp{
        font-size: 14px;
      }
      .offer-prices{
        font-size: 14px;
      }
}
@media (min-width:576px) and (max-width:768px){
    .custom-col {
        flex: 0 0 33.3%; 
        max-width: 33.3%; 
      }
      .deal-btn{
        font-size: 10px!important;
      }
      .mrp{
        font-size: 14px;
      }
      .offer-prices{
        font-size: 14px;
      }
}
@media (min-width:768px) and (max-width:1024px){
    .custom-col {
        flex: 0 0 25%; 
        max-width: 25%;
        
      }
}

.deal-activated{
    color: rgb(4,169,77);
    font-size: 1rem;
    font-weight:400;
}
.deals-taking{
    font-size: 1.5rem;
}
.deals-noCode{
    color: #725402;
}

// .row.deal-mask .col-4 {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
  

  .deal-mask {
    background-image: url("../assets/dealsMask.png");
    background-size: cover; 
    background-position: center center;
    background-repeat: no-repeat; 
    width: 100%; 
    height: 100%; 
    // padding: 40px 0px 40px 0px !important; 
  }
    
.rightArrow{
    max-width: 60px!important;
}