
.container--refferal {
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: contain;
	padding: 80px 15px;

	@media (min-width: 992px) {
		background-size: 600px auto;
		background-position: 90% 100%;
		padding: 6rem 1rem 6rem;
		margin-right: 2rem;
	}

	.refferal-code-input[readonly] {
		background: #fff !important;
		border: solid 2px #0078D7;
		height: 48px;
		border-right: none;

		&:focus,
		&:active,
		&:focus-within,
		&:focus:active {
			box-shadow: none !important;
		}
	}

	.input-group-text:nth-child(1) {
		background: #fff;
		border-top: solid 2px #0078D7;
		border-bottom: solid 2px #0078D7;
		cursor: pointer;
	}

	.input-group-text:nth-child(2) {
		background: #0078D7;
		border: solid 1px #0078D7;
		// border-radius: 0 5px 0 5px;
		border-top-right-radius: 0.25rem !important;
		border-bottom-right-radius: 0.25rem !important;

		&:hover {
			background: darken(#0078D7, 5);
		}
	}

	.band-copy {
		margin-top: -2rem;

		@media (min-width: 992px) {
			margin-top: 0rem;
		}

		h2 {
			font-size: 32px;
			color: #333333;
			margin-bottom: 15px;
		}

		p {
			font-size: 16px;
			color: #333333;
			margin-bottom: 20px;
			line-height: 30px;
		}
	}

	.row--home-section-title {
		margin-bottom: 0px;

		h2 {
			font-size: 1rem;


			@media (min-width: 992px) {
				font-size: 2rem;
			}
		}
	}
}
@media (min-width: 992px) {
.referral-padding{
	padding: 2rem 1rem 2rem;
	background-position-y: top;
	background-size: 36%;
}
h3.voucher-offer-heading {
    font-size: 22px;
    font-weight: 600;
}
.home-voucher-padding{
	padding: 15px;
}
}
@media (max-width: 767px) {
	.home-voucher-padding{
		padding: 10px;
	}
	h3.voucher-offer-heading {
		font-size: 12px;
	}
	.card--carousel-item--nearby-partner {
		min-height: 65px !important;
	}
	.home-offer-heading{
		font-size: 12px !important;
	}
}
.about-bg {
	background-size: cover !important;
	position: relative;

	.about-overlay {
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, #131848, #000);
		position: absolute;
		top: 0;
		left: 0;
		mix-blend-mode: darken;
		opacity: .6;
	}
}

.container-fluid--home-section {

	padding: 15px 15px;

	@media (min-width: 992px) {

		padding: 50px 15px;

	}

	.row--home-section-title {

		margin-bottom: 0px;

		h2 {

			font-size: 1rem;

			@media (min-width: 992px) {

				font-size: 2rem;

			}

		}

		.more {

			font-size: 1rem;

			@media (min-width: 992px) {

				font-size: 1.2rem;

			}

		}

	}

}

.bill-pay-icon-container {

	width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;

	.bill-pay-service-icon {

		flex: 1;
		flex-basis: 33.33%;
		margin: 30px 0;

		@media (min-width: 992px) {

			flex-basis: auto;

		}

	}

}

.card--all-brands {
	border-radius: 8px;
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
	border: none;

	.card-body {

		padding: 0;

		.all-brand-image-wrapper {

			width: 100%;
			height: 80px;
			background-size: 80% auto;
			background-position: center center;
			background-repeat: no-repeat;

			@media (min-width: 992px) {
				background-size: auto 50%;
				height: 160px;
			}

		}
	}

}

.slider-shimmer {
	width: 100%;
	height: 176px;

	@media (min-width: 992px) {
		height: 300px;
	}
}

.shine {
	background: #f6f7f8;
	background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
	background-repeat: no-repeat;
	background-size: 800px 500px;
	display: inline-block;
	position: relative;

	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: placeholderShimmer;
	-webkit-animation-timing-function: linear;

	&.box {
		height: 100px;
		width: 100%;
		border-radius: 8px;

		@media (min-width: 992px) {
			height: 200px;
		}

	}

	&.heading {

		height: 20px;
		width: 150px;
		border-radius: 4px;

		@media (min-width: 992px) {
			width: 300px;
		}
	}

}

@-webkit-keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes placeholderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.band-copy {
	.home-btn {
		font-size: .8rem;
		width: 50%;
		letter-spacing: .1rem;
	}
}

.home-btn {
	padding: .8rem 0rem;
	font-size: .8rem;
	letter-spacing: .1rem;
	width: 80%;
	text-transform: uppercase;
	border-radius: 8px;
}

.wrp-counter-container {
	background: rgba(#000, 0.01);
}

.wrp-counter-summary {
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 30px;

	&>div>div {
		background: rgba(#0078D7, 0.1);
		border-radius: 10px;
		overflow: hidden;
	}

	.coupon-count {
		background: #0078D7;
		width: 120px;
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: -10px;
			width: 100%;
			height: 100%;
			background: #0078D7;
			transform: skewX(-5deg);
			z-index: -1;
		}
	}

	.wrp-counter {
		padding: 0;
	}

	.wrp-count {
		position: relative;
		padding: 0;
		margin: 0;
		width: calc(100% - 120px);
		overflow: hidden;

		.progress.bg-white {
			background: rgba(#fff, 1) !important;
			border-radius: 10px;
			position: relative;
			overflow: visible;
		}

		p {
			margin-bottom: 0;
		}

		.progress-bar {
			background: #0078D7;
			border-radius: 10px;
		}

		.wrp-tooltip-container {
			position: absolute;
			top: -45px;
			right: 0;
			text-align: center;
			z-index: 10;

			&>div {
				position: relative;
				background: #fff;
				padding: 10px;
				line-height: 1;
				border-radius: 5px;
				font-size: 14px;

				&:before {
					content: "";
					width: 10px;
					height: 10px;
					background: #fff;
					position: absolute;
					right: 0;
					left: 0;
					margin: auto;
					bottom: -5px;
					transform: rotate(-45deg);
					z-index: -1;
				}
			}
		}
	}

	.provisionalWrp {
		// margin: 0 -1rem -1rem;
		background: rgba(#0078D7, 0.1) !important;
		// margin-bottom: -1rem;
		// margin-left: -2rem;
	}
}

.card--carousel-item--nearby-partner {
	position: relative;
	min-height: 213px;
	overflow: hidden;

	img {
		position: absolute;
		bottom: 0;
	}

	.card-body {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			background: rgb(0, 0, 0);
			background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
			height: 100px;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
	}

	.category-label {
		z-index: 10;
	}
}

.text-col-primary,
a.text-col-primary {
	color: #0078D7;
}

a.text-col-primary:hover {
	color: darken(#0078D7, 10);
}

.share-links {
	position: relative;

	&:hover .share-links-dropdown {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
	}

	&-dropdown {
		position: absolute;
		bottom: -60px;
		left: -145%;
		border-radius: 5px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: all 0.3s ease-in-out;

		a {
			margin-right: 10px;
			margin-bottom: -2px;

			&:last-child {
				margin-right: 0;
			}
		}

		&>div {
			padding: 10px;
			position: relative;
			z-index: 2;
			background: #fff;
			border-radius: 15px;
			padding-bottom: 6px;
		}

		&::before {
			content: "";
			position: absolute;
			width: 15px;
			height: 15px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			background: #fff;
			left: 0;
			right: 0;
			margin: auto;
			top: -8px;
			transform: rotate(45deg);
			z-index: -1;
		}
	}
}

.download-app .div2{
    background:#fff;
    width:40%;
    position:absolute;
    top: 15%;
    border-radius:10px;
    padding:50px 30px;
    left: 30%;
    text-align:center;
    // box-shadow: 0px 10px 14.1px 0.9px rgb(0 0 0 / 24%), 0px 4px 19.6px 0.4px rgb(0 0 0 / 16%);
}
@media screen and (max-width:992px) {
    .download-app .div2{
        width: 100%;
        left: 0;
		bottom: 0;
		top: 0;
    }
	
}

// .featured-image{
// 	min-width: 1204px;
// 	min-height: 300px;
// 	max-height: 300px;
// }
// .sub-feature-img{
// 	min-width: 600px;
// 	min-height: 300px;
// 	max-height: 300px;
// }
// .featured-custom-half{
// 	flex:0,0,50%;

// }
// .gradient-featured-overlay{
// 	position: absolute;
// 	top: 75%;
// 	left: 0;
// 	width: 100%;
// 	height: 25%;
// 	background: linear-gradient(0, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); /* Gradient effect */
// 	z-index: 1; /* Make sure it appears over the image */
// }

// .logo-featured-overlay {
// 	position: absolute;
// 	bottom: 10px; /* Adjust as needed */
// 	left: 10px; /* Adjust as needed */
// 	width: 50px; /* Adjust the logo size */
// 	height: 50px; /* Adjust the logo size */
// 	z-index: 2; /* Ensures it's on top of the gradient */
//   }
.zoom-image{
	transition: transform 0.3s ease;
}
.zoom-image:hover{
	transform: scale(1.02);
}
@media(max-width:420px){
	.featured-desc{
		font-size: 10px !important;
	}
}
@media(max-width:567px){
	.featured-desc{
		font-size: 12px !important;
	}
}
@media(min-width:768px){
	.featured-desc-2{
		font-size: 1.2rem;
	}
}
