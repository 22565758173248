.rewards-card {
  @media (min-width: 1500px) {
    width: 100%;
    height: 100%;
    margin: 1rem auto;
  }
  // width: 300px;
  // height: 320px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  // box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  border: none;
  margin: 1rem auto;
  min-height: 250px;
  // overflow: hidden;

  .reward-icon {
    width: 100%;
    // position: absolute;
    top: 0%;
    left: 0%;

    img {
      width: 100%;
      margin: 0.5rem auto;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    h4 {
      transform: translate3d(-50%, calc(-50% + 6rem), 0);
    }
  }
  .reveal-icon {
    width: 100%;
    margin: 0.5rem auto;
    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 40px;
    }

    // h6 {
    //   @media (min-width: 1600px) {
    //     margin-top: -8.5rem;
    //   }
    // @media (max-width: 600px) {
    //   margin-top: -8.5rem;
    // }
    //   margin-top: -7.5rem;
    // }

    img {
      width: 100%;
      display: block;
      margin: auto;
    }
  }
}
.text-won {
  color: #646eff;
  font-weight: 600;
}
p.text-secondary.reward-date {
  @media (min-width: 1600px) {
    margin-top: 2.2rem;
  }
  @media (max-width: 600px) {
    margin-top: 2rem;

  }
  margin-top: 1.7rem;
}
.no-offers-title {
  width: 90%;
  margin: auto;

  @media (min-width: 990px) {
    width: 50%;
  }
}

.view-offer-btn {
  width: 90%;
  display: block;
  margin: auto;

  @media (min-width: 990px) {
    width: 30%;
  }
}

.offer-card {
  width: auto;
  height: fit-content;
  min-height: 500px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  border: none;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem auto;

  .rewards-card {
    width: 100%;
    // height: 40%;
    // margin: auto;
  }
}
.offer-details-card {
  width: auto;
  height: fit-content;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);
  border: none;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem auto;

}


.offer-detail {
  flex-direction: column-reverse;
  @media (min-width: 990px) {
    flex-direction: row;
  }

  h5 {
    font-weight: bold;
  }

  .offer-card {
    height: 500px;

    .rewards-card {
      width: 100%;
      // height: 50%;
    }
  }
}

.modal-body {
  .rewards-card {
    box-shadow: none;
    .reward-icon {
      top: 50%;
      transform: translateY(-50%);
      h4 {
        transform: none;
        font-size: 2rem;
      }
      p {
        font-size: 1.5rem;
      }
      small {
        font-size: 1.2rem;
      }
    }
  }
}
.win-title{
  color: #F1C40F;
  font-weight: 600;
  font-size: xx-large;
  text-transform: uppercase;
}

#wheel {
  background: transparent;
  background: url('../assets/ic_spinwhell_popup.png');
  background-size: cover;
  min-height: 710px;
  border-radius: 20px;
  background-position-x: center;
  padding: 10px;
  // width: 100vw;
  @media (max-width: 767px) {
    min-height: 630px;
  }
  @media (min-width: 990px) {
    width: auto;
    // margin-left: 8rem;
  }

  #canvas {
    width: 100%;
    margin-top: 70px;
    @media (min-width: 800px) {
      width: auto;
      height: 600px;
    }
    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }
}
.rewards .modal-lg{
  width: fit-content !important;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.rewards {
  .modal-content {
    background-color: transparent;
    border: none;
    height: 100vh;
    // margin-top: -2rem;

    .close {
      color: #fff;
      text-shadow: none;
      font-size: 2rem;
    }
  }

  .carousel-inner {
    overflow: visible;
  }

  .carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel-caption {
    bottom: -8rem;
    background: transparent;
  }
}

.carousel-card {
  height: 370px;
  background-color: white;
  margin-top: -2rem;

  .reveal-icon {
    margin: 2rem auto;
  }
}

.secret {
  margin-top: 3rem;
  .reveal-icon {
    margin-top: -9rem;

    img {
      width: 400px;
    }
    h4 {
      text-align: center;
      width: 85%;
      margin: -8rem auto;
    }

    .reward-content {
      margin-top: -10rem;
      text-align: center;
    }
  }
}

.empty-checknmark {
  height: 20px;
  display: block;
  width: 20px;
  float: right;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.25);

  img {
    width: 1.25rem;
  }
}
.offer-detail {
  .offer-card {
    margin: auto;
    .prize-card {
      box-shadow: none;
      width: 100%;
      height: auto;

      img {
        width: 100%;
      }
    }
  }
}

.add-address-btn {
  width: 100%;

  @media (min-width: 990px) {
    width: 40%;
  }
}

.voucher-detail {
  .offer-card {
    .rewards-card {
      box-shadow: none;
      width: 100%;

      img {
        margin: auto;
        width: 100%;
      }
    }
  }
}

.voucher-info {
  padding: 1rem;

  @media (min-width: 992px) {
    padding: 1rem 2rem;
  }
}

.card-icon {
  box-shadow: none !important;
}

.spin-details {
  text-align: center;
  color: white;
  margin: -8rem auto;

  @media (min-width: 992px) {
    // margin-left: -10rem;
    margin-top: -10rem;
  }
}
.fortune{
  width: 250px;
  position: absolute;
  top: -30px;
  left: 22.5%;
  @media (max-width: 767px) {
    width: 200px;
    top: -20px;
  }
}

.won {
  margin: -5rem auto;
  @media (min-width: 992px) {
    margin-top: -8rem;
  }
}

.claim-btn {
  display: block;
  margin: 1rem auto;
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;
  }
}

.scratch-text {
  bottom: 2rem;
  z-index: 10;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.scratch-details {
  position: absolute;
  bottom: -10rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  text-align: center;
  color: white;
  margin: auto;
  width: 100%;
}

.claim-dialog {
  .modal-body {
    img {
      display: block;
      margin: auto;
    }

    h3 {
      margin-top: 1rem;
    }

    text-align: center;
  }
  .modal-footer {
    margin-top: -1rem;
  }
}

.verify-img {
  width: 70%;
  margin: 3rem 0;

  @media (min-width: 992px) {
    margin: 0;
  }
}

.reward-form {
  position: relative;

  .edit-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0, calc(-50% + 1rem), 0);
  }
}

.offer-btn {
  width: 50%;
  float: left;

  @media (min-width: 992px) {
    width: 100%;
    float: right;
  }
}
.rewards-won-card-textbox{
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media only screen and (min-width: 300px) {
  .rewards-text-box-height{
    min-height: 60px;
  }
}
@media only screen and (min-width: 350px) {
  .rewards-text-box-height{
    min-height: 90px;
  }
}
@media only screen and (min-width: 400px) {
  .rewards-text-box-height{
    min-height: 100px;
  }
}
@media only screen and (min-width: 500px) {
.rewards-text-box-height{
  min-height: 134px;
}
}
@media only screen and (min-width: 576px) {
  .rewards-text-box-height{
    min-height: 7px;
  }
}
@media only screen and (min-width: 650px) {
  .rewards-text-box-height{
    min-height: 80px;
  }
}
@media only screen and (min-width: 750px) {
  .rewards-text-box-height{
    min-height: 100px;
  }
}
@media only screen and (min-width: 850px) {
  .rewards-text-box-height{
    min-height: 120px;
  }
}
@media only screen and (min-width: 992px) {
  .rewards-text-box-height{
    min-height: 75px;
  }
}
@media only screen and (min-width: 1200px) {
  .rewards-text-box-height{
    min-height: 60px;
  }
}
@media only screen and (min-width: 1400px) {
  .rewards-text-box-height{
    min-height: 70px;
  }
}
@media only screen and (min-width: 1500px) {
  .rewards-text-box-height{
    min-height: 73px;
  }
  .rewards-card {
    height: auto;
    margin: 0;
  }
}
.offer-detail.link-highlight a {
  color: blue !important;
}