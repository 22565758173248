.ratings-vector{
    width:300px;
    @media (min-width: 992px) {
        width: 400px;
    }
}

.breadCrumbs {
    @media (min-width: 992px) {
        background: #F2F6FA;
    }
}

.ratings-info {
    width: 75%;
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.profile-pic {
    width: 45px;
    height: 45px;
    border-radius: 50%;

    @media (min-width: 992px) {
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }

}