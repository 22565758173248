@import "_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '@material/react-text-field/index.scss';
@import '@material/react-list/index.scss';
@import '@material/react-menu-surface/index.scss';
@import '@material/react-menu/index.scss';
@import '@material/react-select/index.scss';
@import "_util.scss";
@import "_navbar.scss";
@import "_footer.scss";
@import "_input-group.scss";
@import "_card.scss";
@import "_tabs.scss";
@import "_carousel.scss";
@import "_ripple.scss";
@import "_loader.scss";
@import "_travel.scss";
@import "_home.scss";
@import "_bill_pay.scss";
@import "_coupon.scss";
@import "_referral.scss";
@import "_shopping_sites.scss";
@import "_nearby_partners.scss";
@import "_404.scss";
@import "_search.scss";
@import "_winner.scss";
@import "_notifications.scss";
@import "_wallet.scss";
@import "_address.scss";
@import "_bookmarks.scss";
@import "_orders.scss";
@import "_ratings.scss";
@import "_voucher.scss";
@import "_rewards.scss";
@import "_deals.scss";
@import "_ecom.scss";
@import "_wrp.scss";
@import "_pages.scss";
@import "_insurance.scss";
@import "_dashboard.scss";
@import "_redeem_voucher";
@import "_fun-game.scss";
@import "_profile.scss";
@import "_login.scss";
@import "_prepaid_card.scss";

$winds-color-primary: #131848;
$winds-color-accent: #ffcc00;
$winds-color-grey: #495057;
$winds-color-link: rgb(8, 124, 216);
$light-grey: #aaaaaa;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    outline: none;
    text-decoration: none;
  }
}

.font-proxima-nova {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-proxima-nova-bold {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.animated-btn {
  width: 100%;
  height: 60px;
  font-size: large;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  background: linear-gradient(90deg, #ffb4e9, #7f00ff 50%, #ffb4e9);
  background-size: 200%;
  animation: anime 8s linear infinite;
  border: none;
  @media screen and (max-width: 767px) {
    font-size: small !important;
    height: 40px !important;
  }
}
.non-animated-btn {
  width: 100%;
  height: 60px;
  font-size: large;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  color: #212529;
  border-radius: 50px;
  background: white;
  border: 2px solid #c568f4;
}
.winds-blue-bg {
  background: $winds-color-primary;
}
.winds-lightblue-bg {
  background: $winds-color-link;
}
.winds-blue-color {
  color: $winds-color-primary;
}

.winds-lightblue-color {
  color: $winds-color-link;
}

.winds-accent-color {
  color: $winds-color-accent;
}

.winds-link-color {
  color: $winds-color-link;
}

.winds-grey-color {
  color: $winds-color-grey;
}

.winds-grey-light {
  color: $light-grey;
}

.error-text {
  color: red;
}

.bookmark {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  border-radius: 50%;
  background: rgb(0 0 0 / 10%);
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 9;
  cursor: pointer;

  @media (min-width: 992px) {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    right: 1rem;
    top: 0.5rem;
  }
}

.container-fluid--home-section,
.card--carousel-item--vouchers {
  .bookmark {
    display: none;
  }
}

.abs-center {
  position:absolute;
  top: 55%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  @media screen and (max-width: 600px) {
    top: 60%;
  }
}

.icon-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  z-index: 2;

  img {
    height: 50%;
  }
}

.profile-list .list-group-item {
  border: none;
}

@media (min-width: 992px) {
  .marginDevice {
    margin-left: 3rem;
  }
}

.avatar {
  background: #180e80;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 3rem;
  z-index: 1;
  overflow: hidden;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  img {
    width: 100%;
  }
}

.bg-rating-green {
  background: linear-gradient(
    146.28deg,
    rgb(100, 211, 65) 0%,
    rgb(0, 157, 81) 100%
  );
}

.bg-rating-red {
  background: linear-gradient(
    146.28deg,
    rgba(230, 54, 45, 0.8) 0%,
    rgb(230, 54, 45) 100%
  );
}
.bg-rating-orange {
  background: linear-gradient(
    146.28deg,
    rgba(230, 97, 45, 0.8) 0%,
    rgb(230, 97, 45) 100%
  );
}

.bookmark-delete {
  display: none;
}

.bookmark-list:hover .bookmark-delete {
  display: block;
  background: rgba(255, 255, 255, 0.5);
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
  border-radius: 50%;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 0;
  cursor: pointer;

  img {
    height: auto;
  }
}

// .modal {
//   z-index: 9999999999;
// }

.btn:focus,
.btn:active {
  box-shadow: none !important;
  outline: 0px !important;
}

.highlight {
  color: #ffa300;
}
.spinning-wheel-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  pointer-events: none;
}
#chart {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 0;
  left: 0;
}
#question {
  position: absolute;
  width: 400px;
  height: 500px;
  top: 0;
  left: 520px;
}
#question h1 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slice {
  stroke: black;
  stroke-width: 0.5px;
}

.circle-middle {
  fill: white;
  stroke-width: 1px;
  stroke: black;
  cursor: pointer;
}

.spin-text {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 30px;
}

wheel {
  top: 0;
  left: 0;
  width: 500px;
  -moz-border-radius: 250px;
  -webkit-border-radius: 250px;
  border-radius: 250px;
  transition: transform 0.5s linear;
  -webkit-transition: -webkit-transform 0.5s linear;
  -moz-transition: -moz-transform 0.5s linear;
  -opera-transition: -opera-transform 0.5s linear;
  -ms-transition: transform 0.5s linear;
}
#tick {
  margin-left: 244px;
  font-family: Arial;
  font-size: 14pt;
}
#display {
  background-color: #43759f;
  padding: 1em 2em;
  text-align: center;
  border-radius: 40px;
  border: 4px double #fff;
}
#display div.word {
  display: inline-block;
}
#display div.wordLetter {
  display: block;
  width: 50px;
  height: 65px;
  margin: 2px;
  border: 2px solid #43759f;
  float: left;
  line-height: 65px;
  font-size: 40px;
}
#display div.wordLetter.letter {
  border-color: #eee;
}
#playArea {
  clear: both;
}
#playArea button {
  text-transform: uppercase;
  z-index: 1000;
  border: 4px double #fff;
  background-color: #43759f;
  padding: 6px 14px;
  color: white;
  font-family: inherit;
  font-size: 12pt;
}
#playArea #moneyArea {
  float: right;
  position: relative;
  padding: 10px;
  border: 2px black solid;
  width: 200px;
  margin: 5px;
  font-size: 14pt;
}

.clear {
  clear: both;
}

.info-box {
  min-height: 750px;
  overflow: visible;
  width: 100%;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.pac-container {
  z-index: 9999;
}
// .pac-container:after {
//   /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

//   background-image: none !important;
//   height: 0px;
// }
.bspace {
  padding: 10px;
}
.badge {
  display: inline-block;
  padding: 0.3em 0.8em;
  margin-bottom: 0;
}
.badge-success {
  color: #fff;
  background-color: #6372ff;
}
.badge-green {
  color: #fff;
  background-color: #00be5e;
}
.badge-purple {
  color: #fff;
  background-color: #977de4;
}
.badge-danger {
  color: #fff;
  background-color: #ee0e0e;
}
.badge-delete {
  color: #fff;
  background-color: #ff7171;
}
.badge-partial-cancel {
  color: #fff;
  background-color: #aa00ff;
}
.badge-warning {
  color: #212529;
  background-color: #ffb317;
}
.badge-fulfill {
  color: #212529;
  background-color: #839226;
}
.badge-initiate {
  color: #212529;
  background-color: #ff6e00;
}

.animated-btn {
  width: 100%;
  height: 60px;
  font-size: large;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  background: linear-gradient(90deg, #ffb4e9, #7f00ff 50%, #ffb4e9);
  background-size: 200%;
  animation: anime 8s linear infinite;
  border: none;
}
.animated-btn-disabled {
  width: 100%;
  height: 60px;
  font-size: large;
  text-align: center;
  line-height: normal;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  opacity: 50%;
  border-radius: 50px;
  background: radial-gradient(circle, #fbbee9 30%, #7f00ff 100%);
  background-size: 400%;
  border: none;
  pointer-events: none;
}
.animated-btn-border {
  width: 100%;
  height: 60px;
  font-size: large;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: center;
  border-radius: 100rem;
  color: #49527e;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #ffb4e9, #7f00ff 50%, #ffb4e9);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}
@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.disable-wallet {
  cursor: not-allowed !important;
  opacity: 50%;
}
.explore-home,
.explore-home .show > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
}

.explore-home:hover,
.explore-home:active,
.explore-home:focus,
.explore-home:focus-within,
.explore-home:focus-visible {
  background-color: transparent !important;
  border-color: transparent !important;
}
.form-error {
  color: rgb(255, 0, 0);
}
@media screen and (max-width: 500px) {
  .modal-content {
    width: 95% !important;
    margin: auto;
  }
}
.accordian-bg {
  background-color: #f4f4ff !important;
}

.cart-otp-box input {
  height: 4rem;
  width: 3.7rem !important;
  @media screen and (max-width: 900px) {
    height: 3.5rem;
    width: 3rem !important;
  }
  @media screen and (max-width: 767px) {
    height: 3rem;
    width: 2.5rem !important;
  }
}
.btn-close-card-type {
  background-color: rgba(130, 159, 255, 0.123);
  border: none;
  color: rgba(0, 0, 0, 0.268);
  padding: 2px 10px;
  font-size: 19px;
  cursor: pointer;
  border-radius: 19px;
  width: 32px;
  height: 32px;
  margin: 0.8rem;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.text-red{
  color:red !important;
}
.image-width {
  width: 40%;
  display: flex;
  justify-content: left;
  border: 1px solid #f0f0f0;
  max-width: 40%;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .image-width {
    margin: auto;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    margin: 5px auto;
    margin-bottom: 15px;
  }
}
.custom-container {
  width: 60%;
  border: 1px solid #e8e8e8;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background: ghostwhite;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.08);

  @media (max-width: 767px) {
    width: 100%;
  }
}
.text-red{
  color: red;
}
.color-blue{
  color: #7D70FF !important;
}
.cart-title-rect {
  height: 27px;
  width: 3px;
  background: linear-gradient(180deg, #009BA3 0%, #5BCDBB 100%);
  box-shadow: 3px 4px 13px 0 rgba(19,29,45,0.2);
}
.refund-policy h6{
  color: #9884C3;
  letter-spacing: 1px;
  min-width: fit-content;
}
.refund-policy p{
  font-size: 15px !important;
  color: #6D6D6D !important;
}
.upi-verify{
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  background-color: #7D70FF;
  padding: 15px 30px;
  margin-left: 10px;
  z-index: 99;
  @media screen and (max-width:500px) {
  padding: 15px 20px;
  }
}

.upi-verify-disabled{
  pointer-events: none;
  background-color: #D0D0D0;
  // cursor: not-allowed;
}
.upi-select {
  background: #ECECFF;
}
.verified-vpa{
  font-size: large;
  @media screen and (max-width:500px) {
    font-size: small;
  }
}
.verified-vpa-name{
  font-size: small;
  @media screen and (max-width:500px) {
    font-size: x-small;
  }
}
.add-new{
  font-size: large;
  @media screen and (max-width:500px) {
    font-size: small;
  }
}
.clear-upi{
  @media screen and (max-width:500px) {
    font-size: 8px;
  }
}
.text-ellipsis{
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin: 0;
  color: #7D70FF;
}

.celebration-lottie {
  position: absolute;
  /* top: 0%; */
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
}
